import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from 'reactstrap';
import cn from 'classnames';
import styles from './index.module.scss';

import { If } from 'utils';
import { Logo } from 'components';
import { AuthContext } from 'context/AuthContext';
import rrWelcome from 'assets/rr-illustrations/welcome.svg';
import relaxingRabbit from 'assets/rr-illustrations/tracker-app.svg';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/chevron-left.svg';

export default function UnauthenticatedLayout({
  id = '',
  renderHeader = () => null,
  renderTitle = () => <Title />,
  renderHelptext = () => <HelpText />,
  children = null
}) {
  const { pathname } = useLocation();
  const { appType } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const header = renderHeader();
  const title = renderTitle();
  const helptext = renderHelptext();
  const showLoginBtn = pathname.includes('connect'); // /ss-connect | /connect-store

  const layoutClassName = cn('main', styles.container, {
    [styles.retexApp]: appType.retex,
    [styles.trackingApp]: appType.shipmentTracking
  });
  const imgSrc = appType.shipmentTracking ? relaxingRabbit : rrWelcome;

  return (
    <div className="app">
      <div className="app-body">
        <main className={layoutClassName} id={id}>
          <ToastContainer />
          <Container fluid className={styles.fluidContainer}>
            {header}
            <div className={styles.row}>
              <div className={styles.col}>
                <img src={imgSrc} alt="welcome" onLoad={() => setShow(true)} />
                <If test={show}>
                  <If test={appType.retex}>
                    <div className={styles.shadow}></div>
                  </If>
                  <div className={styles.info}>
                    {title}
                    {helptext}
                  </div>
                </If>
              </div>
              <div className={styles.col}>
                <If test={showLoginBtn}>
                  <Link to="/login">
                    <button className={styles.loginBtn}>
                      <ArrowIcon />
                      Login
                    </button>
                  </Link>
                </If>
                <If test={show}>
                  <Logo className={styles.logo} />
                  <div className={styles.content}>{children}</div>
                </If>
              </div>
            </div>
          </Container>
        </main>
      </div>
    </div>
  );
}

function Title() {
  const { appType } = useContext(AuthContext);

  if (!appType.retex) return null;

  return (
    <h3>
      <span>Returns and Exchanges</span>
      <br />
      Made Easy
    </h3>
  );
}

function HelpText() {
  const { appType } = useContext(AuthContext);

  if (!appType.retex) return null;

  return (
    <p>
      <span>With the ShipStation Returns</span> free plan, you get unlimited
      returns and exchange software as well as steeply discounted shipping rates
      from USPS, UPS, and DHL, along with best-in-class revenue retention
      capabilities.
    </p>
  );
}
