import { ROUTES as EXCHANGES_ROUTES } from '../../Exchanges/stubs';
import { ROUTES as PURCHASES_ROUTES } from '../../Purchases/stubs';
import { ROUTES as REFUND_METHOD_ROUTES } from '../../RefundMethods/stubs';
import { ROUTES as FEES_INCENTIVE_ROUTES } from '../../FeesAndIncentives/stubs';

const INCENTIVES_LINK = {
  EXCHANGE_BONUS_CASH: EXCHANGES_ROUTES.EXCHANGE_BONUS_CASH,
  PURCHASE_BONUS_CASH: PURCHASES_ROUTES.PURCHASE_BONUS_CASH,
  UNIT_RESTOCKING_FEE: FEES_INCENTIVE_ROUTES.UNIT_RESTOCKING_FEE,
  RETURN_PROCESSING_FEE: FEES_INCENTIVE_ROUTES.RETURN_PROCESSING_FEE,
  GIFT_CARD: REFUND_METHOD_ROUTES.HOME,
  ORIGINAL_PAYMENT_METHOD: REFUND_METHOD_ROUTES.HOME
};

export default INCENTIVES_LINK;
