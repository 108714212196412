import React, { useContext, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import styles from './index.module.scss';

import { If } from 'utils';
import { formatPrice } from 'utils/formatters';
import { OrderServiceContext } from 'components/OrderServiceDetails';
import ShopperGroupIncentiveIcon from '../ShopperGroupIncentiveIcon';
import { AuthContext } from 'context/AuthContext';
import { getGiftCardCopy } from 'utils/methods';

export default function AmountBreakdown() {
  const { orderService } = useContext(OrderServiceContext);

  const { masterData } = useContext(AuthContext);
  const { giftCardIncentiveText } = useRef(getGiftCardCopy()).current;

  const SHOPPER_GROUPS_OVERRIDES = useRef(
    _get(masterData, 'shopper_group_override_entities', [])
  ).current;
  const ENTITY_OVERRIDES = useRef(
    Array.isArray(SHOPPER_GROUPS_OVERRIDES) &&
      SHOPPER_GROUPS_OVERRIDES.length === 5
      ? SHOPPER_GROUPS_OVERRIDES.map(({ value = '' }) => value)
      : ['', '', '', '', '']
  ).current;

  const paymentInfo = _get(orderService, 'payment_info', {});
  const shopperOverrides = _get(
    orderService,
    'shopper_group_overrides_applied',
    []
  );
  const currency = _get(paymentInfo, 'currency', 0);
  const totalAmount = _get(paymentInfo, 'amount', 0);
  const shippingFee = _get(paymentInfo, 'shipping_fee', 0);
  const paymentWaiveOff = _get(paymentInfo, 'payment_waive_off', 0);
  const giftCardIncentive = _get(paymentInfo, 'gift_card_incentive', 0);
  const returnProcessingFee = _get(paymentInfo, 'return_processing_fee', 0);
  const paymentProcessingFee = _get(paymentInfo, 'payment_processing_fee');

  const formattedTotalAmount = formatPrice(totalAmount, currency);
  const formattedShippingFee = formatPrice(shippingFee, currency);
  const formattedGiftCardIncentive = formatPrice(giftCardIncentive, currency);
  const formattedReturnProcessingFee = formatPrice(
    returnProcessingFee,
    currency
  );
  const formattedPaymentProcessingFee = formatPrice(
    paymentProcessingFee,
    currency
  );
  const formattedWaiveOffAmount = formatPrice(paymentWaiveOff, currency);

  const transactionHelpText = `Shopper ${totalAmount > 0 ? 'gets' : 'pays'}`;
  // const finalAmountOffset = showAction ? '70px' : '0';

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <span className={styles.label}>Shipping Fee</span>
        <span className={styles.value}>- {formattedShippingFee}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>
          Return Processing Fee{' '}
          <ShopperGroupIncentiveIcon
            type={ENTITY_OVERRIDES[3]}
            incentives={shopperOverrides}
            iconClassName={styles.infoIcon}
            message="Shopper group's return processing fee applied"
          />
        </span>
        <span className={styles.value}>- {formattedReturnProcessingFee}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>
          Payment Processing Fee{' '}
          <ShopperGroupIncentiveIcon
            type={ENTITY_OVERRIDES[1]}
            incentives={shopperOverrides}
            iconClassName={styles.infoIcon}
            message="Shopper group's payment processing fee applied"
          />
        </span>
        <span className={styles.value}>- {formattedPaymentProcessingFee}</span>
      </div>
      <If test={!!giftCardIncentive}>
        <div className={styles.row}>
          <span className={styles.label}>
            {giftCardIncentiveText}{' '}
            <ShopperGroupIncentiveIcon
              type={ENTITY_OVERRIDES[0]}
              incentives={shopperOverrides}
              iconClassName={styles.infoIcon}
              message={`Shopper group's ${_toLower(
                giftCardIncentiveText
              )} applied`}
            />
          </span>
          <span className={styles.value}>{formattedGiftCardIncentive}</span>
        </div>
      </If>
      <div className={styles.row}>
        <span className={styles.label}>FINAL AMOUNT</span>
        <span className={styles.value}>
          <span className={cn('pr-4', styles.lightText)}>
            {transactionHelpText}
          </span>
          <If test={paymentWaiveOff && !totalAmount}>
            <s id="waive-off-amount" className={styles.lightText}>
              {formattedWaiveOffAmount}
            </s>
            <UncontrolledTooltip target="waive-off-amount">
              Waived off all the charges for this exchange order
            </UncontrolledTooltip>
          </If>
          {formattedTotalAmount}
        </span>
      </div>
    </div>
  );
}
