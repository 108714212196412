import React from 'react';
import { Spinner } from 'reactstrap';

import { ReactComponent as ErrorIcon } from 'assets/img/icons/error.svg';
import { ReactComponent as AlertIcon } from 'assets/img/icons/alert.svg';
import { ReactComponent as SuccessIcon } from 'assets/img/icons/success.svg';
import { ReactComponent as InfoIcon } from 'assets/img/icons/info.svg';

const TYPES = {
  info: InfoIcon,
  error: ErrorIcon,
  warning: AlertIcon,
  success: SuccessIcon
};
const TYPES_KEYS = Object.keys(TYPES);

const checkType = type => TYPES_KEYS.includes(type);

const ToastMsg = ({ msg, type = '' }) => {
  if (typeof msg === 'object') {
    msg = msg.message || null;
  }
  if (type === 'warn') {
    type = 'warning';
  }

  const isValidIcon = checkType(type);
  const Icon = isValidIcon ? TYPES[type] : () => null;

  return (
    <div className={`customToast__screen ${type}`}>
      {isValidIcon && (
        <div className="customToast__screen-icon">
          <Icon />
        </div>
      )}
      {type === 'processing' ? <Spinner color="primary" /> : null}
      <div className="customToast__screen-text">
        {msg || 'Something went wrong.'}
      </div>
    </div>
  );
};

export default ToastMsg;
