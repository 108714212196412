import React from 'react';
import styles from './index.module.scss';

import { UiKit } from 'components';

export default function PriceLimitHeader({
  title = '',
  description = '',
  className = '',
  children = null
}) {
  return (
    <UiKit.GridRow gap="24" templateColumns="auto 1fr" className={className}>
      <div className={styles.title}>
        <strong>{title}</strong>
        <span>{description}</span>
      </div>
      {children}
    </UiKit.GridRow>
  );
}
