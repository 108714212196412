import React, { useState } from 'react';
import { UncontrolledAlert } from 'reactstrap';

import styles from './index.module.scss';

export default function Alert() {
  const [showAlert, setShowAlert] = useState(() => {
    const search = new URLSearchParams(window.location.search);
    return search.get('fromChangePassword') === 'true';
  });

  return (
    <UncontrolledAlert
      color="info"
      isOpen={showAlert}
      toggle={() => setShowAlert(false)}
      className={styles.container}
    >
      Your Password has been changed. Please login with your Email and New
      Password.
    </UncontrolledAlert>
  );
}
