import { get, logError } from 'utils';
import { formatAndStoreMasterData } from 'utils/methods';

export default function getMasterData(
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  get({ apiKey: 'masterData', noTokenRequired: true })
    .then(({ data }) => {
      if (!data) {
        throw new Error('Failed to fetch the master data');
      }

      const formattedData = formatAndStoreMasterData(data);
      if (!formattedData) {
        throw new Error('Failed to format and save the the masterData');
      }

      onSuccess(data, formattedData);
    })
    .catch(err => {
      onError(err);
      logError(err);
    })
    .finally(onFinally);
}
