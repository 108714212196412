import { useCallback, useEffect, useRef, useState } from 'react';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _isFunction from 'lodash/isFunction';

import { patch, post, put, deleteRequest, logError } from 'utils';
import { CustomToast } from 'components';

const DEFAULT = {
  isPublicAPI: false,
  ignoreReadModeCheck: false,
  payload: {},
  routeParam: '',
  routeParams: {},
  axiosConfig: {},
  onValidate: () => true,
  onSuccess: () => {},
  onError: () => {},
  onFinally: () => {},
  successMessage: '',
  errorMessage: '',
  successToastType: 'success'
};

const API_METHODS = {
  POST: () => post,
  PUT: () => put,
  PATCH: () => patch,
  DELETE: () => deleteRequest
};

/**
 * useMutation arguments
 * @param {string} apiKey
 * @param {"POST" | "PUT" | "PATCH" | "DELETE"} method
 * @param {import("utils/types").MutationConfig} config
 */
export default function useMutation(apiKey, method, config = {}) {
  if (!apiKey) {
    throw new Error('API Key is required in the useMutation hook');
  } else if (!(method in API_METHODS)) {
    throw new Error(`"${method}" is not valid api method`);
  }

  const payload = _get(config, 'payload', DEFAULT.payload);
  const routeParam = _get(config, 'routeParam', DEFAULT.routeParam);
  const routeParams = _get(config, 'routeParams', DEFAULT.routeParams);
  const axiosConfig = _get(config, 'axiosConfig', DEFAULT.axiosConfig);
  const onValidate = _get(config, 'onValidate', DEFAULT.onValidate);
  const onSuccess = _get(config, 'onSuccess', DEFAULT.onSuccess);
  const onError = _get(config, 'onError', DEFAULT.onError);
  const onFinally = _get(config, 'onFinally', DEFAULT.onFinally);
  const isPublicAPI = _get(config, 'isPublicAPI', DEFAULT.isPublicAPI);
  const errorMessage = _get(config, 'errorMessage', DEFAULT.errorMessage);
  const successMessage = _get(config, 'successMessage', DEFAULT.successMessage);
  const successToastType = _get(
    config,
    'successToastType',
    DEFAULT.successToastType
  );
  const ignoreReadModeCheck = _get(
    config,
    'ignoreReadModeCheck',
    DEFAULT.ignoreReadModeCheck
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const payloadRef = useRef(payload);
  const onValidateCallbackRef = useRef(onValidate);
  const onSuccessCallbackRef = useRef(onSuccess);
  const onErrorCallbackRef = useRef(onError);
  const onFinallyCallbackRef = useRef(onFinally);

  useEffect(() => {
    payloadRef.current = payload;
    onValidateCallbackRef.current = onValidate;
    onSuccessCallbackRef.current = onSuccess;
    onErrorCallbackRef.current = onError;
    onFinallyCallbackRef.current = onFinally;
  });

  const mutate = useCallback(
    /**
     * @param {Record<string, *>} payloadOverrides
     * @param {import("utils/types").MutationCallback} callback
     */
    (payloadOverrides = {}, callback = () => {}) => {
      const defaultPayload = _isFunction(payloadRef.current)
        ? payloadRef.current()
        : payloadRef.current;
      const payload = _merge({}, defaultPayload, payloadOverrides);

      const isValid = onValidateCallbackRef.current(payload);
      if (!isValid) return;

      const methodGen = _get(API_METHODS, method);
      const request = methodGen();

      setIsProcessing(true);
      request(
        { apiKey, ignoreReadModeCheck, noTokenRequired: isPublicAPI },
        {
          params: { routeParam, routeParams, ...payload },
          config: axiosConfig
        }
      )
        .then(response => {
          callback(null, response.data, response);
          onSuccessCallbackRef.current(response.data, response);
          if (successMessage && !response.notified) {
            CustomToast({ type: successToastType, msg: successMessage });
          }
        })
        .catch(error => {
          let errorObj = new Error(error);
          if (errorMessage && !error.notified) {
            errorObj = new Error(errorMessage);
            errorObj.data = { ...error };
            CustomToast({ type: 'error', msg: errorMessage });
          }
          logError(errorObj);
          callback(error);
          onErrorCallbackRef.current(error, _get(error, 'response.data.data'));
        })
        .finally(() => {
          setIsProcessing(false);
          onFinallyCallbackRef.current();
        });
    },
    [
      method,
      apiKey,
      isPublicAPI,
      ignoreReadModeCheck,
      routeParam,
      routeParams,
      axiosConfig,
      errorMessage,
      successMessage,
      successToastType
    ]
  );

  return { mutate, isProcessing };
}
