import React from 'react';

const SINGULAR_DEFINITIONS = {
  exchanges: 'exchange',
  purchases: 'purchase'
};

/**
 * @param {"exchanges" | "purchases"} entity
 * @param {string} ecommPlatformName Store's Ecommerce Platform
 * @param {boolean} supportsType
 * @param {boolean} supportsCategory
 */
export function getCopies(
  entity,
  ecommPlatformName,
  supportsType,
  supportsCategory
) {
  const singularTerm = SINGULAR_DEFINITIONS[entity];

  return {
    title: `${singularTerm} catalog`,
    helpText: `Setup your defaults for ${singularTerm} catalog. Choose among the ${
      supportsType ? 'product types,' : ''
    } ${
      supportsCategory ? 'product categories,' : ''
    } collections and exclude products by tags or price.`,
    productTypes: {
      label: 'Product Types',
      description: `Select the product types that you would like to display in the ${singularTerm} catalog.`
    },
    productCategories: {
      label: 'Product Categories',
      description: `Select the product categories that you would like to display in the ${singularTerm} catalog.`
    },
    collections: {
      label: 'Collections',
      description: `Select the collections that you would like to display in the ${singularTerm} catalog.`
    },
    tags: {
      label: 'Product Tag based restriction',
      description: `Don’t want to show some products to show up for ${singularTerm}? Add their product tags here, and we will not show those products in the catalog. In case of any conflict, the product tag rule will be honoured, and the product will not be shown.`
    },
    price: {
      label: 'Price based restriction',
      description: `Setup a price threshold below which product & variants will not be displayed in the catalog. Return Rabbit will sync with your ${ecommPlatformName} store periodically to track product & variants below this price point and exclude them from the catalog.`
    },
    digitalProducts: {
      label: 'Include Digital Products',
      description: `Choose this option to include digital products like Gift Cards or similar items to display in the ${singularTerm} catalog.`
    },
    saleItems: {
      label: 'Show Sale Items',
      description: (
        <span>
          Choose this option to exclude items that have been marked for sale.
          Return Rabbit will sync all product & variants that have{' '}
          <code>compare_at_price</code> value that is above the{' '}
          <code>listed price</code>.
        </span>
      )
    }
  };
}
