import _get from 'lodash/get';

import { ELEMENT_TYPES } from '../stubs';

function getConfigData(config = [], configData = {}) {
  return config.reduce((acc, { id, type }) => {
    let value;
    switch (type) {
      case ELEMENT_TYPES.SELECT:
        value = _get(configData, `${id}.value`, '');
        break;
      case ELEMENT_TYPES.NESTED_SELECT:
        value = _get(configData, id, '');
        break;
      case ELEMENT_TYPES.BOOLEAN:
        value = _get(configData, id, false);
        break;
      default:
        value = undefined;
    }
    if (value !== undefined) {
      acc[id] = value;
    }
    return acc;
  }, {});
}

export default function createRequestBody(data = {}) {
  const title = _get(data, 'title', '');
  const isEnabled = _get(data, 'isEnabled', false);
  const reasons = _get(data, 'attachedReasons', []);
  const config = _get(data, 'config', {});
  const ruleId = _get(data, 'rule.value', '');
  const ruleConfig = _get(data, 'rule.config', []);
  const isCustomRule = _get(data, 'rule.is_custom', false);

  const attachedReasons = reasons.map(({ value }) => value);
  const configData = getConfigData(ruleConfig, config);

  const customRules = _get(data, 'rules', []);
  const rules = !isCustomRule
    ? []
    : customRules.map(ruleInfo => {
        const configData = _get(ruleInfo, 'config', {});
        const ruleConfig = _get(ruleInfo, 'ruleOption.config', {});
        const isProductRequired = _get(
          ruleInfo,
          'ruleOption.is_product_required',
          false
        );
        const config = getConfigData(ruleConfig, configData);
        const productId = !isProductRequired
          ? null
          : _get(
              ruleInfo,
              'product.objectID',
              _get(ruleInfo, 'product.ObjectId', null)
            );

        const rule = {
          rule_id: _get(ruleInfo, 'ruleOption.value', ''),
          product_id: productId,
          config
        };
        if ('id' in ruleInfo) {
          rule.id = ruleInfo.id;
        }
        return rule;
      });

  return {
    title,
    rule_id: ruleId,
    rules,
    config: configData,
    is_enabled: isEnabled,
    attached_reasons: attachedReasons
  };
}
