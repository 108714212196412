import React from 'react';
import { Route, Link } from 'react-router-dom';

import Request from './Init/Request';
import PasswordForm from './PasswordForm';

export default function ResetPassword() {
  return (
    <div id="login-row">
      <Route path="/reset-password/next/" component={PasswordForm} />
      <Route path="/reset-password/" component={Request} exact />
      <div className="text-right mt-2">
        <Link to="/login" className="route-switch-link">
          Login?
        </Link>
      </div>
    </div>
  );
}
