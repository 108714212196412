import React from 'react';
import _get from 'lodash/get';

import {
  UiKit,
  Toggle,
  LockContainer,
  InteractConfigWrapper
} from 'components';
import { useBonusCashConfig } from '../../hooks';

export default function ToggleSwitch() {
  const { data, isProcessing, onChangeData } = useBonusCashConfig();

  const isActive = _get(data, 'is_active', false);
  const selectedId = _get(data, 'shopper_group_id', null);

  const isLocked = selectedId !== null;
  const isDisabled = isLocked || isProcessing;

  return (
    <InteractConfigWrapper>
      <LockContainer locked={isLocked}>
        <UiKit.GridRow templateColumns="auto 1fr" gap={14}>
          <strong>Active</strong>
          <Toggle
            size="lg"
            isActive={isActive}
            disabled={isDisabled}
            onClick={() => onChangeData({ is_active: !isActive })}
          />
        </UiKit.GridRow>
      </LockContainer>
    </InteractConfigWrapper>
  );
}
