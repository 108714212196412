import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

export default function createSelectOptions(
  data = [],
  { valueKey = 'id', labelKey = 'name' } = {}
) {
  if (!_isArray(data)) return [];

  return data.map(dataset => ({
    label: _get(dataset, labelKey, ''),
    value: _get(dataset, valueKey, '')
  }));
}
