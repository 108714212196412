import React from 'react';
import _get from 'lodash/get';

import { useFeatures } from 'utils/hooks';
import { UiKit, PageErrorUi, SectionContainer } from 'components';
import {
  Features,
  LoaderSkeleton
} from 'views/Configure/ReturnManagement/AdditionalFeatures/components';
import { MODAL_GENERATOR } from './stubs';

const AdditionalFeatureSection = ({
  includeFeature = [],
  excludeFeature = [],
  title = 'Configuration',
  loaderCount = 3,
  hideErrorImage = false,
  className = '',
  containerClassName = ''
}) => {
  const {
    features,
    isLoading,
    isError,
    onReload,
    isModalOpen,
    modalData,
    isProcessing,
    onModalOpen,
    onModalClose,
    onModalClosed,
    toggleFeature
  } = useFeatures({
    includeFeatures: includeFeature,
    excludeFeatures: excludeFeature
  });

  const modalTitle = _get(modalData, 'title', '');
  const featureSlug = _get(modalData, 'feature_type', '');
  const isCurrentlyEnabled = _get(modalData, 'is_enabled', false);
  const modalGenerator = _get(MODAL_GENERATOR, featureSlug, () => ({}));
  const newState = isCurrentlyEnabled ? 'disable' : 'enable';

  const {
    confirmationMessage = `You are about to ${newState} the feature. Are you sure?`,
    confirmButtonText = 'Confirm'
  } = modalGenerator(!isCurrentlyEnabled);

  return (
    <SectionContainer
      title={title}
      className={className}
      containerClassName={containerClassName}
    >
      <LoaderSkeleton isLoading={isLoading} count={loaderCount}>
        <PageErrorUi
          isError={isError}
          onReload={onReload}
          hideImage={hideErrorImage}
          msg="Unable to load the features list at the moment. Please try again later!"
          contentClassName="p-0"
        >
          <Features list={features} onToggle={onModalOpen} />
          <UiKit.Modal
            title={modalTitle}
            isOpen={isModalOpen}
            isProcessing={isProcessing}
            onConfirm={toggleFeature}
            onClose={onModalClose}
            onClosed={onModalClosed}
            confirmBtnText={confirmButtonText}
            confirmBtnType={isCurrentlyEnabled ? 'danger' : 'success'}
          >
            <p>{confirmationMessage}</p>
          </UiKit.Modal>
        </PageErrorUi>
      </LoaderSkeleton>
    </SectionContainer>
  );
};

export default AdditionalFeatureSection;
