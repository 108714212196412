import React from 'react';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';

import { FeatureUpsell, InteractConfigWrapper, UiKit } from 'components';
import RuleConfiguration from './RuleConfiguration';
import { useRecommendationConfig } from '../hooks';
import { createDefaultRuleConfig } from '../methods';

export default function RecommendationRule() {
  const {
    data,
    ruleOptions,
    isProcessing,
    updateData,
    clearErrors
  } = useRecommendationConfig();

  const rule = _get(data, 'rule', null);
  const configData = _get(data, 'config', []);
  const configElements = _get(rule, 'config', []);
  const isDisabled = _get(rule, 'isDisabled', false);

  const onConfigChange = (id, value) => {
    if (isProcessing) return;
    const config = _cloneDeep(configData);
    _set(config, id, value);
    updateData({ config });
    clearErrors(`config.${id}`);
  };

  const onRuleChange = rule => {
    if (isProcessing || isDisabled) return;
    updateData({ rule, config: createDefaultRuleConfig(configElements) });
    clearErrors('config');
  };

  return (
    <InteractConfigWrapper
      title="Recommendation Rule"
      helptext={
        <>
          Select a recommendation rule from pre-defined options or use "Custom
          Rules" to define your own recommendation set.
          <FeatureUpsell.Banner
            compact
            className="mt-3"
            featureSlug="EXCHANGE_RECOMMENDATIONS_PRO"
          />
        </>
      }
    >
      <UiKit.Select
        options={ruleOptions}
        value={rule}
        onChange={onRuleChange}
        isDisabled={isProcessing}
        label="Select recommendation rule"
      />
      <RuleConfiguration
        configData={configData}
        configElements={configElements}
        onChange={onConfigChange}
      />
    </InteractConfigWrapper>
  );
}
