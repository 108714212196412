import React, { useContext, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import qs from 'qs';
import _toLower from 'lodash/toLower';

import Alert from './Alert';
import routes from '../../config/routes/auth';
import UnauthenticatedLayout from 'containers/UnauthenticatedLayout';
import { AuthContext } from 'context/AuthContext';

export default function DefaultLayout() {
  const { search } = useLocation();
  const { isLoggedIn, login, logout } = useContext(AuthContext);

  const { app_install } = qs.parse(search, { ignoreQueryPrefix: true });
  const isAppInstall = _toLower(app_install) === 'true';

  useEffect(() => {
    if (isLoggedIn && isAppInstall) {
      logout();
    }
  }, [isLoggedIn, isAppInstall, logout]);

  return (
    <UnauthenticatedLayout id="login-layout" renderHeader={() => <Alert />}>
      <Switch>
        {routes.map((route, idx) => {
          const Component = route.component;
          return Component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={routeProps => <Component {...routeProps} login={login} />}
            />
          ) : null;
        })}
      </Switch>
    </UnauthenticatedLayout>
  );
}
