import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import _get from 'lodash/get';

import { PLAN_MODE, PLAN_SLUGS } from 'dictionary';
import {
  useFetch,
  useMutation,
  useNotifications,
  useSubscription
} from 'utils/hooks';
import {
  configureRetexPlans,
  getAllPlans,
  getMaxSavingsInBilling,
  getPlansOrderMap
} from 'utils/methods';
import { AuthContext } from 'context/AuthContext';

const SUBSCRIPTION_API_KEY = {
  SHOPIFY: 'billing.shopify.subscribe',
  STRIPE: 'billing.stripe.subscribe'
};

export default function useBilling({
  loadOnMount = true,
  payload = {},
  onSubscribed = () => {},
  onSubscriptionError = () => {},
  onSubscriptionFinally = () => {}
} = {}) {
  const { appType } = useContext(AuthContext);
  const { source, subscription, refetchSubscription } = useSubscription();
  const { fetchUnreadListWithTimerReset } = useNotifications();

  const [plans, setPlans] = useState([]);
  const [billCycle, setBillCycle] = useState(PLAN_MODE.YEARLY);
  const [subscribedPlan, setSubscribedPlan] = useState(
    _get(subscription, 'plan_info')
  );
  const [processingPlan, setProcessingPlan] = useState(null);
  const [subscribedPlanMode, setSubscribedPlanMode] = useState(null);
  const [showDowngradeAlert, setShowDowngradeAlert] = useState(false);
  const [showUpgradeAlert, setShowUpgradeAlert] = useState(false);
  const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);

  const freezeRef = useRef(false);
  const allPlansRef = useRef([]);
  const inititalPlansRef = useRef([]);
  const plansOrderMapRef = useRef({});
  const cardLast4DigitsRef = useRef(null);
  const upgradePlanSlugRef = useRef(null);
  const downgradePlanSlugRef = useRef(null);
  const enterpriseNotifiedRef = useRef(false);
  const processingDataRef = useRef(null);

  const isTrackingApp = _get(appType, 'shipmentTracking', false);
  const upgradePlanSlug = upgradePlanSlugRef.current;
  const downgradePlanSlug = downgradePlanSlugRef.current;

  const { data: plansRawData, isError, isLoading, fetchData } = useFetch(
    'billing.plans',
    {
      loadOnMount,
      initialData: [],
      errorMessage: 'Unable to load billing plans at the moment.',
      onSuccess: data => {
        const plans = _get(data, 'plans', []);
        const billCycle = _get(data, 'billing_mode', null);
        const currentPlan = _get(data, 'current_plan', null);
        const cardLast4Digits = _get(data, 'card_last4', null);
        const isEnterpriseNotified = _get(data, 'enterprise_notified', false);
        const currentPlanSlug = _get(currentPlan, 'slug', '');

        const billingPlans = configureRetexPlans({
          plans,
          billCycle,
          isTrackingApp,
          subscribedBillCycle: billCycle,
          subscribedPlanSlug: currentPlanSlug
        });

        inititalPlansRef.current = plans;
        cardLast4DigitsRef.current = cardLast4Digits;
        enterpriseNotifiedRef.current = isEnterpriseNotified;
        plansOrderMapRef.current = getPlansOrderMap(plans, isTrackingApp);
        allPlansRef.current = getAllPlans(billingPlans, plans, isTrackingApp);

        setPlans(billingPlans);
        setBillCycle(billCycle);
        setSubscribedPlan(currentPlan);
        setSubscribedPlanMode(billCycle);
      }
    }
  );

  const { mutate: onSetupSubscription } = useMutation(
    SUBSCRIPTION_API_KEY[source],
    'POST',
    {
      payload,
      errorMessage: `Unable to subscribe to the plan at the moment. Please try again later.`,
      onSuccess: data => {
        const slug = _get(processingDataRef.current, 'slug', '');
        const billCycle = _get(data, 'payment_mode', null);
        const redirectUrl = _get(data, 'redirect_url', null);
        const cardLast4Digits = _get(data, 'card_last4', null);
        const isEnterpriseNotified = _get(data, 'enterprise_notified', false);

        if (redirectUrl) {
          freezeRef.current = true;
          return window.open(redirectUrl, '_top');
        }

        const isSubscribedToEnterprisePlan = slug === PLAN_SLUGS.ENTERPRISE;
        const newPlan = allPlansRef.current.find(
          plan => _get(plan, 'slug') === slug
        );

        if (!isSubscribedToEnterprisePlan) {
          const billingPlans = configureRetexPlans({
            plans: inititalPlansRef.current,
            billCycle,
            isTrackingApp,
            subscribedPlanSlug: slug,
            subscribedBillCycle: billCycle
          });
          setPlans(billingPlans);
          setSubscribedPlan(newPlan);
          setBillCycle(billCycle);
          setSubscribedPlanMode(billCycle);
        }

        cardLast4DigitsRef.current = cardLast4Digits;
        enterpriseNotifiedRef.current = isEnterpriseNotified;
        setShowEnterpriseModal(isSubscribedToEnterprisePlan);
        setShowUpgradeAlert(false);
        setShowDowngradeAlert(false);
        onSubscribed(data);
        fetchUnreadListWithTimerReset();
        refetchSubscription(true);
      },
      onError: onSubscriptionError,
      onFinally: () => {
        onSubscriptionFinally();
        if (!freezeRef.current) {
          processingDataRef.current = null;
          setProcessingPlan(null);
        }
      }
    }
  );

  const onToggleBillCycle = useCallback(() => {
    const newCycle =
      billCycle === PLAN_MODE.YEARLY ? PLAN_MODE.MONTHLY : PLAN_MODE.YEARLY;
    const billingPlans = configureRetexPlans({
      plans: inititalPlansRef.current,
      billCycle: newCycle,
      isTrackingApp,
      subscribedBillCycle: subscribedPlanMode,
      subscribedPlanSlug: _get(subscribedPlan, 'slug', null)
    });
    setBillCycle(newCycle);
    setPlans(billingPlans);
  }, [billCycle, isTrackingApp, subscribedPlan, subscribedPlanMode]);

  const validatePlanSwitch = useCallback(
    slug => {
      if (!slug) throw new Error('Plan slug is required');

      const currentPlanSlug = _get(subscribedPlan, 'slug', null);
      const newPlanIndex = plansOrderMapRef.current[slug];
      const currentPlanIndex = plansOrderMapRef.current[currentPlanSlug];

      const isDowngrade = !!subscribedPlan && newPlanIndex < currentPlanIndex;
      const isUpgrade = !!subscribedPlan && newPlanIndex >= currentPlanIndex;

      if (isDowngrade) {
        downgradePlanSlugRef.current = slug;
        setShowDowngradeAlert(true);
        return false;
      } else if (isUpgrade) {
        upgradePlanSlugRef.current = slug;
        setShowUpgradeAlert(true);
        return false;
      }

      return true;
    },
    [subscribedPlan]
  );

  const onSubscribe = useCallback(
    ({ slug = null, payload = {}, skipValidation = false } = {}) => {
      if (!slug) return;

      const isValid = skipValidation || validatePlanSwitch(slug);
      if (!isValid) return;

      processingDataRef.current = { slug };
      setProcessingPlan(slug);
      onSetupSubscription(payload);
    },
    [validatePlanSwitch, onSetupSubscription]
  );

  const onCancelDowngrade = useCallback(() => setShowDowngradeAlert(false), []);

  const onCancelUpgrade = useCallback(() => setShowUpgradeAlert(false), []);

  const onCloseEnterpriseModal = useCallback(() => {
    setShowEnterpriseModal(show => !show);
  }, []);

  const subscribedPlanIndex = useMemo(() => {
    const subscribedPlanSlug = _get(subscribedPlan, 'slug', '');
    return plans.findIndex(({ slug }) => slug === subscribedPlanSlug);
  }, [plans, subscribedPlan]);

  const { maxSavings, planPriceVariantMap } = useMemo(
    () => getMaxSavingsInBilling(plans),
    [plans]
  );

  const downgradePlan = useMemo(() => {
    return plans.find(plan => {
      const slug = _get(plan, 'slug', '');
      return slug === downgradePlanSlug;
    });
  }, [plans, downgradePlanSlug]);

  const upgradePlan = useMemo(() => {
    return plans.find(plan => {
      const slug = _get(plan, 'slug', '');
      return slug === upgradePlanSlug;
    });
  }, [plans, upgradePlanSlug]);

  return {
    plans,
    isError,
    isLoading,
    billCycle,
    subscribedPlan,
    subscribedPlanMode,
    subscribedPlanIndex,
    showUpgradeAlert,
    showDowngradeAlert,
    showEnterpriseModal,
    maxSavings,
    planPriceVariantMap,
    upgradePlan,
    downgradePlan,
    processingPlan,
    cardLast4Digits: cardLast4DigitsRef.current,
    isNotifiedForEnterprise: enterpriseNotifiedRef.current,
    referrer: _get(plansRawData, 'referrer', null),
    eligibleTrial: _get(plansRawData, 'merchant_eligible_trial', null),
    onReload: fetchData,
    onSubscribe,
    onToggleBillCycle,
    validatePlanSwitch,
    onCancelUpgrade,
    onCancelDowngrade,
    onCloseEnterpriseModal
  };
}
