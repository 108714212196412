import React from 'react';
import _get from 'lodash/get';

import { InteractConfigWrapper, UiKit } from 'components';
import { useRecommendationConfig } from '../hooks';

export default function RecommendationRequestReasons() {
  const {
    data,
    isProcessing,
    reasonOptions,
    updateData
  } = useRecommendationConfig();

  const reasons = _get(data, 'attachedReasons', []);

  const onChange = reasons => updateData({ attachedReasons: reasons });

  return (
    <InteractConfigWrapper
      title="Request Reasons"
      helptext="Map this recommendation set to specific return reasons. You can select multiple return reasons."
    >
      <UiKit.Select
        isMulti
        options={reasonOptions}
        value={reasons}
        onChange={onChange}
        isDisabled={isProcessing}
        placeholder="Select the reasons"
      />
    </InteractConfigWrapper>
  );
}
