import ROUTES from 'views/Configure/stubs/routes';

const HOME = ROUTES.EXCHANGES;

export default {
  HOME,
  EXCHANGE_CATALOG: `${HOME}/catalog`,
  EXCHANGE_RECOMMENDATIONS: `${HOME}/recommendations`,
  EXCHANGE_INCENTIVES: `${HOME}/incentives`,
  EXCHANGE_BONUS_CASH: `${HOME}/bonus-cash`,

  CONFIGURE: ROUTES.HOME,
  RETURN_MANAGEMENT: ROUTES.RETURN_MANAGEMENT
};
