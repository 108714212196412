import React, { useState, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';
import qs from 'qs';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import styles from './index.module.scss';

import { If } from 'utils';
import { useFetch } from 'utils/hooks';
import { Loader, InfoBanner } from 'components';
import { FormWrapperOne, FormWrapperTwo } from './components';
import { ReactComponent as InProgressIcon } from 'assets/img/icons/in-progress.svg';

export default function Login({ login = () => {} }) {
  const { search } = useLocation();
  const { replace } = useHistory();

  const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
  const appInstall = _get(queryParams, 'app_install', '');
  const storeOwnerEmail = _get(queryParams, 'email', '');
  const storeIdentifier = _get(queryParams, 'identifier', '');
  const isAppInstall = _toLower(appInstall) === 'true';

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [retailers, setRetailers] = useState([]);
  const [isFetchingInstallEmail, setIsFetchingInstallEmail] = useState(
    isAppInstall && !storeOwnerEmail && !!storeIdentifier
  );

  const { fetchData } = useFetch('account', {
    method: 'POST',
    isPublicAPI: true,
    payload: { identifier: storeIdentifier },
    errorMessage: '',
    loadOnMount: isFetchingInstallEmail,
    onSuccess: data => {
      const userEmail = _get(data, 'email', '');
      const error = _get(data, 'error', '');
      if (error) {
        replace('/login');
        setErrorMsg(error);
        setIsFetchingInstallEmail(false);
      } else if (!userEmail) {
        setTimeout(() => fetchData(), 1000);
      } else {
        const queryParams = qs.stringify({
          app_install: true,
          email: userEmail
        });
        replace(`/login?${queryParams}`);
        setIsFetchingInstallEmail(false);
      }
    },
    onError: () => {
      replace('/login?app_install=true');
      setIsFetchingInstallEmail(false);
    }
  });

  const reset = useCallback(() => {
    setRetailers([]);
    setStep(1);
  }, []);

  const proceed = useCallback(({ retailers, email }) => {
    setRetailers(retailers);
    setEmail(email);
    setStep(2);
  }, []);

  return (
    <div id="login-row">
      <If
        test={!errorMsg}
        otherwise={
          <InfoBanner
            icon={<InProgressIcon className={styles.infoIcon} />}
            className={styles.infoAlert}
          >
            {errorMsg}
          </InfoBanner>
        }
      >
        <Card className="login-card">
          <CardBody className="p-0">
            <If
              test={!isFetchingInstallEmail}
              otherwise={
                <div className="login__install-loader">
                  <Loader loading />
                  Setting up your account...
                </div>
              }
            >
              <CardTitle className="text-center">
                Login to your account
              </CardTitle>
              <If test={isAppInstall}>
                <div className="login__emailSent">
                  Welcome to Return Rabbit!{' '}
                  <If
                    test={storeOwnerEmail}
                    otherwise={
                      <>An email has been sent with your credentials.</>
                    }
                  >
                    An email with your credentials has been sent to{' '}
                    <strong>{storeOwnerEmail}</strong>.
                  </If>{' '}
                  Please check your spam folder as well.
                </div>
              </If>
              <If test={step === 1}>
                <FormWrapperOne proceed={proceed} />
              </If>
              <If test={step === 2}>
                <FormWrapperTwo
                  email={email}
                  retailers={retailers}
                  loggedIn={login}
                />
                <div className="text-right reset-link" onClick={reset}>
                  Reset form
                </div>
              </If>
            </If>
          </CardBody>
        </Card>
        <If test={!isFetchingInstallEmail}>
          <div className="text-right mt-2">
            <Link to="/reset-password" className="route-switch-link">
              Forgot your password?
            </Link>
          </div>
        </If>
      </If>
    </div>
  );
}
