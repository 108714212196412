import React from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import _upperFirst from 'lodash/upperFirst';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { If } from 'utils';
import { usePriceLimitter } from '..';

const LIMIT_TYPE = { FIXED: 'fixed', PERCENT: 'percentage' };

export default function PriceLimitConfig({
  enabledKey = 'enabled',
  typeKey = 'type',
  amountKey = 'amount',
  maxAmountKey = 'max_amount',
  typeInputName = '',
  amountInputName = '',
  maxAmountInputName = ''
}) {
  const { data, entity, isDisabled, onChange, errors } = usePriceLimitter();

  const { options, optionsMap } = createLimitOptions(entity);

  const isEnabled = _get(data, enabledKey, false);
  const limitType = _get(data, typeKey, LIMIT_TYPE.FIXED);
  const amount = _get(data, amountKey, '0');
  const maxAmount = _get(data, maxAmountKey, '');
  const typeError = _get(errors, typeKey, '');
  const amountError = _get(errors, amountKey, '');
  const maxAmountError = _get(errors, maxAmountKey, '');

  const selectedType = optionsMap[limitType];
  const disabled = isDisabled || !isEnabled;
  const isFixedType = limitType === LIMIT_TYPE.FIXED;
  const isPercentType = limitType === LIMIT_TYPE.PERCENT;

  const onLimitTypeChange = option => {
    onChange(typeInputName, _get(option, 'value'));
  };

  const onInputChange = event => {
    const { name, value } = event.target;

    let regexToBeUsed = /^[0-9]*$/;
    if (isFixedType && name === amountInputName) {
      regexToBeUsed = /^\d*(\.)?(\d{0,2})?$/;
    } else if (isPercentType && name === maxAmountInputName) {
      regexToBeUsed = /^\d*(\.)?(\d{0,2})?$/;
    }
    const isValid = regexToBeUsed.test(value);

    if (!isValid) return;
    onChange(name, value);
  };

  const onInputBlur = event => {
    const { name, value } = event.target;
    if (!value || !_isNumber(+value)) {
      onChange(name, '0');
    }
  };

  return (
    <UiKit.GridRow gap="10" templateColumns="1fr">
      <UiKit.GridRow gap="16" templateColumns="2fr 1fr">
        <UiKit.Select
          label={<b>{_upperFirst(entity)} Type</b>}
          options={options}
          value={selectedType}
          onChange={onLimitTypeChange}
          placeholder="Select an option"
          isDisabled={disabled}
          error={!!typeError}
        />
        <UiKit.Input
          label={<b>Value</b>}
          name={amountInputName}
          value={amount}
          onChange={onInputChange}
          onBlur={onInputBlur}
          placeholder={`say, ${isPercentType ? '10%' : '$4'}`}
          disabled={disabled || !selectedType}
          inputWrapperClassName={cn(styles.valueInputWrapper, {
            [styles.fixed]: isFixedType,
            [styles.percent]: isPercentType
          })}
          error={!!amountError}
        />
      </UiKit.GridRow>
      {isPercentType && (
        <UiKit.GridRow
          gap="16"
          templateColumns="2fr 1fr"
          className="align-items-center"
        >
          <span className={styles.capLabel}>Enter the cap for {entity}</span>
          <UiKit.Input
            name={maxAmountInputName}
            value={maxAmount === null || maxAmount === 'null' ? '' : maxAmount}
            onChange={onInputChange}
            onBlur={onInputBlur}
            placeholder="say, $4"
            disabled={disabled}
            inputWrapperClassName={cn(styles.valueInputWrapper, styles.fixed)}
            error={!!maxAmountError}
          />
        </UiKit.GridRow>
      )}
      <If test={!!typeError || !!amountError || !!maxAmountError}>
        <UiKit.GridRow gap="4" templateColumns="1fr">
          <UiKit.ErrorMessage msg={typeError} />
          <UiKit.ErrorMessage msg={amountError} />
          <UiKit.ErrorMessage msg={maxAmountError} />
        </UiKit.GridRow>
      </If>
    </UiKit.GridRow>
  );
}

function createLimitOptions(entity = '') {
  if (!entity) return [];
  return Object.values(LIMIT_TYPE).reduce(
    (acc, type) => {
      const formattedType = _upperFirst(type);
      const formattedEntity = _upperFirst(entity);
      const option = {
        label: `${formattedType} ${formattedEntity}`,
        value: type
      };
      acc.options.push(option);
      acc.optionsMap[type] = option;
      return acc;
    },
    { options: [], optionsMap: {} }
  );
}
