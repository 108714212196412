import React from 'react';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import styles from './index.module.scss';

import { If } from 'utils';
import Card from '../Card';
import Actions from './Actions';
import { useRecommendationConfig } from '../../../hooks';
import { createCustomRuleTitle } from '../../../methods';

const CHAR_LIMIT = 40;

export default function RuleCard({ index = 0 }) {
  const {
    data,
    isProcessing,
    editRule,
    discardRule
  } = useRecommendationConfig();

  const rule = _get(data, `rules[${index}]`, null);

  if (!rule) return null;

  const name = _get(rule, 'ruleOption.title', '');
  const title = createCustomRuleTitle(index + 1);
  const product = _get(rule, 'product', null);
  const hasProduct = !!product;
  const productImageUrl = _get(product, 'image_url', '');
  const productTitle = _capitalize(_get(product, 'title', ''));
  let productName =
    productTitle.length < CHAR_LIMIT
      ? productTitle
      : `${productTitle.substr(0, CHAR_LIMIT)}...`;

  return (
    <Card className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.ruleName}>
          <span>Rule:</span>
          <p>{name}</p>
        </div>
      </div>
      <Actions
        isDisabled={isProcessing}
        editRule={() => editRule(index)}
        discardRule={() => discardRule(index)}
      >
        <If test={hasProduct}>
          <div className={styles.product}>
            <img src={productImageUrl} alt="product" />
            <span>{productName}</span>
          </div>
        </If>
      </Actions>
    </Card>
  );
}
