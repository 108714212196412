import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isPlainObject';

import { isReadMode, readModeApiResponse } from 'utils/methods';
import { axiosPublicInstance, axiosPrivateInstance } from 'config/setup/axios';
import { API } from 'config/env';

/**
 * @typedef {Object} Options
 * @property {string} apiKey API Key map from the API.js [API_ENDPOINTS]
 * @property {boolean} [noTokenRequired] True to use Public Axios instance | False to use Private Axios instance.
 * @property {boolean} [ignoreReadModeCheck] To skip the check of read-mode and trigger the API
 */

/**
 * @typedef {Object} Payload
 * @property {import('axios').AxiosRequestConfig} [config] Axios Request Config object
 * @property {Params & Record<string, *>} [params] Define the API data
 */

/**
 * @typedef {Object} Params
 * @property {string} [routeParam] API endpoint fragment to be added at the end of the API endpoint, usually dynamic UUID or slug
 * @property {Record<string, *>} [routeParams] Define the route dynamic parameters to that replaces the placeholder fragments (withing [] brackets) in the API endpoint
 */

/**
 * @param {Options} options
 * @param {Payload} payload
 */

export default function post(options = {}, payload = {}) {
  if (!options.ignoreReadModeCheck && isReadMode()) {
    return readModeApiResponse();
  }

  let url = API(options.apiKey);

  const { routeParam = '', routeParams = null, ...data } = _get(
    payload,
    'params',
    {}
  );
  const axiosConfig = _get(payload, 'config', {});

  if (routeParam) {
    url += `${routeParam}/`;
  }
  if (_isObject(routeParams) && !_isEmpty(routeParams)) {
    for (let key in routeParams) {
      url = url.replace(`[${key}]`, routeParams[key]);
    }
  }

  const axiosMethod = options.noTokenRequired
    ? axiosPublicInstance
    : axiosPrivateInstance;

  return axiosMethod.post(url, data, axiosConfig);
}
