import { useCallback, useState } from 'react';

export default function useToggle(initialValue = false) {
  const [isActive, setIsActive] = useState(initialValue);

  const onToggle = useCallback(() => setIsActive(active => !active), []);

  const onReset = useCallback(() => setIsActive(initialValue), [initialValue]);

  return [isActive, onToggle, onReset];
}
