import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';

const INTERNAL_STORES = [
  'allreturnstore',
  'return-rabbit-demo',
  'woo-quickly-wise-traveler',
  'https-woo-devaspen-com',
  'foqwxk6jzw' // BigCommerce testing store
];

const TEST_RETAILER_OVERRIDES = [
  ['admin.submenu.settings.submenu.manage_subscription.enabled', false]
];

export default function transformPermissions(
  displayMenuOptions,
  storeUniqueName = '',
  isTestRetailer = false
) {
  const configuration = _cloneDeep(displayMenuOptions);
  if (isTestRetailer && !INTERNAL_STORES.includes(storeUniqueName)) {
    TEST_RETAILER_OVERRIDES.forEach(([key, value]) => {
      _set(configuration, key, value);
    });
  }
  return configuration;
}
