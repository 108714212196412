import React from 'react';
import cn from 'classnames';
import _isFunction from 'lodash/isFunction';
import styles from './index.module.scss';

import { If } from 'utils';
import { useAccess } from 'utils/hooks';
import {
  Toggle,
  ProtectedLink,
  FeatureUpsell,
  Skeleton,
  SkeletonTheme
} from 'components';
import ErrorFallback from '../ErrorFallback';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';
import { ReactComponent as LinkIcon } from 'assets/img/icons/external-link.svg';

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.helptext
 * @param {string} props.accessId
 * @param {import("dictionary").FEATURE_SLUGS} props.featureSlug
 * @param {string} props.identifier
 * @param {boolean} props.isError
 * @param {boolean} props.isActive
 * @param {boolean} props.isLoading
 * @param {function} props.onEdit
 * @param {function} props.onToggle
 * @param {function} props.onReload
 * @param {string} props.configRoute
 * @param {string} props.helptextClassName
 * @param {string} props.contentClassName
 * @param {boolean} props.disabled
 * @param {string} props.lockBadgeText
 * @param {import('react').ReactNode} props.children
 * @returns {React.ReactNode}
 */
export default function IndicatorCard({
  title = '',
  helptext = '',
  accessId = '',
  identifier = '',
  featureSlug = '',
  configRoute = '',
  contentClassName = '',
  helptextClassName = '',
  isError = false,
  isActive = false,
  isLoading = false,
  disabled = false,
  onEdit = null,
  onToggle = null,
  onReload = () => {},
  children = null
}) {
  const { hasSubFeatureAccess } = useAccess();

  const hasAccess = featureSlug ? hasSubFeatureAccess(featureSlug) : true;
  const canToggle = _isFunction(onToggle);
  const canEdit = _isFunction(onEdit);
  const badgeText = isError
    ? 'Uncertain'
    : hasAccess && isActive
    ? 'Active'
    : 'Inactive';

  const containerClass = cn(styles.container, {
    [styles.loading]: isLoading,
    [styles.locked]: !hasAccess,
    [styles.error]: isError
  });
  const badgeClass = cn(styles.badge, { [styles.show]: !isLoading });
  const badgeIconClass = cn(styles.badgeIcon, {
    [styles.on]: isActive,
    [styles.off]: !isActive
  });
  const helptextClass = cn(styles.helptext, helptextClassName);
  const contentClass = cn(styles.content, contentClassName);
  const isToggleDisabled = !hasAccess || disabled || isLoading || isError;

  return (
    <div className={containerClass}>
      <div className={badgeClass}>
        <span className={styles.badgeText}>{badgeText}</span>
        <span className={badgeIconClass}></span>
      </div>
      <FeatureUpsell.Container hasAccess={hasAccess} className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <If
              test={!isLoading}
              otherwise={
                <SkeletonTheme className="m-0">
                  <Skeleton rounded width="32px" height="16px" />
                  <Skeleton height="12px" width="125px" />
                </SkeletonTheme>
              }
            >
              <If test={hasAccess && canToggle}>
                <Toggle
                  isActive={isActive}
                  onClick={onToggle}
                  disabled={isToggleDisabled}
                />
              </If>
              <h6>{title}</h6>
            </If>
          </div>
          {!isLoading && (
            <If test={hasAccess} otherwise={<FeatureUpsell.LockBadge />}>
              <div className={styles.actions}>
                {!disabled && canEdit && !isError && (
                  <button className={styles.action} onClick={onEdit}>
                    <EditIcon />
                  </button>
                )}
                {configRoute && (
                  <ProtectedLink
                    isExternal
                    _key={accessId}
                    id={identifier}
                    to={configRoute}
                    className={styles.action}
                  >
                    <LinkIcon />
                  </ProtectedLink>
                )}
              </div>
            </If>
          )}
        </div>
        <div className={contentClass}>
          <ErrorFallback isError={isError} onReload={onReload}>
            <div className={helptextClass}>
              <If
                test={!isLoading}
                otherwise={
                  <Skeleton height="14px" width="80%" className="mb-2" />
                }
              >
                {helptext}
              </If>
            </div>
            {children}
          </ErrorFallback>
          <If test={featureSlug}>
            <FeatureUpsell.Modal.Trigger
              featureSlug={featureSlug}
              className="mt-4 w-100"
            />
          </If>
        </div>
      </FeatureUpsell.Container>
    </div>
  );
}
