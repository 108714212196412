import React from 'react';
import ReactMapboxGl from 'react-mapbox-gl';

import MapLayer from './MapLayer';

const MapElement = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
});

export default function Map(props) {
  const address = props.address;
  if (!address || !address.longitude || !address.latitude) {
    return null;
  }
  const zoom = props.zoom ? [props.zoom] : [18];
  // Mapbox coordinates are in [long, lat]
  // Source: https://www.mapbox.com/mapbox-gl-js/api/#lnglat
  return (
    <MapElement
      // eslint-disable-next-line
      style="mapbox://styles/supplyaidev/cjofh96y1266u2st9u02dxr42"
      zoom={zoom}
      containerStyle={{
        height: '100%',
        width: '100%'
      }}
      center={[address.longitude, address.latitude]}
    >
      <MapLayer {...props} />
    </MapElement>
  );
}
