import React from 'react';
import _get from 'lodash/get';

import { TableHeading } from 'components/Tables';
import { useProductsCatalog } from '../../hooks';
import { useEcomPlatform, useSupportedProductAttributes } from 'utils/hooks';
import { getCopies } from '../../methods';
import { DOC_LINKS } from '../../stubs';

export default function ProductCatalogHeader({ children = null }) {
  const { entity } = useProductsCatalog();
  const { ecommPlatformName } = useEcomPlatform();
  const { supportsType, supportsCategory } = useSupportedProductAttributes();

  const docUrl = _get(DOC_LINKS, entity, '');
  const copies = getCopies(
    entity,
    ecommPlatformName,
    supportsType,
    supportsCategory
  );

  return (
    <TableHeading
      title={copies.title}
      helpText={copies.helpText}
      documentationUrl={docUrl}
      titleClassName="text-capitalize"
    >
      {children}
    </TableHeading>
  );
}
