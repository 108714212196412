import { getEcomPlatformMap } from 'utils/DICTIONARY';

export function isEcommPlatformSupported(platformKey) {
  const labelMap = getEcomPlatformMap();
  return platformKey in labelMap;
}

export function getEcommPlatformLabel(platformKey, fallback = platformKey) {
  const isSupported = isEcommPlatformSupported(platformKey);
  if (!isSupported) return fallback;

  const labelMap = getEcomPlatformMap();
  return labelMap[platformKey];
}
