import ROUTES from 'views/Configure/stubs/routes';

const HOME = ROUTES.PURCHASES;

export default {
  HOME,
  PURCHASE_CATALOG: `${HOME}/catalog`,
  PURCHASE_BONUS_CASH: `${HOME}/bonus-cash`,
  PURCHASE_RECOMMENDATION: `${HOME}/recommendation`,
  CONFIGURE: ROUTES.HOME,
  RETURN_MANAGEMENT: ROUTES.RETURN_MANAGEMENT
};
