import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UncontrolledAlert } from 'reactstrap';
import { Formik } from 'formik';

import { login } from 'utils/services';
import { isValidEmail } from 'utils/validators';
import StepTwoForm from './StepTwoForm';

export default function FormWrapperTwo({
  email = '',
  retailers = [],
  loggedIn = () => {}
}) {
  const { replace } = useHistory();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const initialFormData = useRef({ email: email || '' }).current;

  const retailersList = useMemo(
    () => retailers.map(val => ({ value: val, label: val })),
    [retailers]
  );

  const handleSubmit = useCallback(
    values => {
      setIsError(false);
      setIsLoading(true);
      setErrorMessage('');
      login(
        values,
        payload => {
          replace('/login');
          loggedIn(payload);
        },
        message => {
          setErrorMessage(message);
          setIsError(message !== null);
        },
        () => setIsLoading(false)
      );
    },
    [loggedIn, replace]
  );

  const validate = useCallback(
    values => {
      let errors = {};
      if (!values.email) {
        errors.email = 'Required';
      } else if (!isValidEmail(values.email)) {
        errors.email = 'Invalid email address';
      }
      if (!values.password || values.password.length < 1) {
        errors.password = 'Required';
      }
      if (
        (!values.retailer_name || values.retailer_name.length < 1) &&
        retailersList.length > 0
      ) {
        errors.retailer = 'Please choose a retailer';
      }
      return errors;
    },
    [retailersList.length]
  );

  return (
    <div>
      <UncontrolledAlert
        className="text-left"
        color="danger"
        isOpen={isError}
        toggle={() => setIsError(false)}
      >
        <strong>Error!</strong> {errorMessage}
      </UncontrolledAlert>
      <Formik
        validate={validate}
        onSubmit={handleSubmit}
        validateOnChange={false}
        initialValues={initialFormData}
        handleChange={() => setIsError(false)}
        render={props => (
          <StepTwoForm {...props} loading={isLoading} list={retailersList} />
        )}
      />
    </div>
  );
}
