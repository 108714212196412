import ADMIN_ROUTES from 'config/nav/admin/routes';

const {
  ROOT: HOME,
  RETURN_MANAGEMENT,
  SHIPPING,
  SHOPPER_PORTAL
} = ADMIN_ROUTES.CONFIGURE_RR;

export default {
  HOME,

  RETURN_MANAGEMENT,
  REFUND_METHODS: `${RETURN_MANAGEMENT}/refund-methods`,
  EXCHANGES: `${RETURN_MANAGEMENT}/exchanges`,
  PURCHASES: `${RETURN_MANAGEMENT}/purchases`,
  AUTOMATION_RULES: `${RETURN_MANAGEMENT}/automation-rules`,
  DEDUCTIONS: `${RETURN_MANAGEMENT}/deductions`,
  FEES_AND_INCENTIVES: `${RETURN_MANAGEMENT}/fees-and-incentives`,
  REQUEST_REASONS: `${RETURN_MANAGEMENT}/request-reasons`,
  ADDITIONAL_FEATURES: `${RETURN_MANAGEMENT}/additional-features`,
  SHOPPER_GROUPS: `${RETURN_MANAGEMENT}/shopper-groups`,
  SERVICE_WINDOW: `${RETURN_MANAGEMENT}/service-window`,
  PROMO_MANAGEMENT: `${RETURN_MANAGEMENT}/promo-management`,
  EMAIL_ALERTS: `${RETURN_MANAGEMENT}/email-alerts`,
  TAG_BASED_RULES: `${RETURN_MANAGEMENT}/tag-based-rules`,
  DISPOSITION: `${RETURN_MANAGEMENT}/disposition`,

  SHIPPING,
  SHIPPING_METHODS: `${SHIPPING}/shipping-methods`,
  FACILITIES: `${SHIPPING}/facilities`,
  ZONES: `${SHIPPING}/service-zones`,
  LANES: `${SHIPPING}/service-lanes`,
  BOX: `${SHIPPING}/box`,
  CARIER_CONFIGURATION: `${SHIPPING}/carrier-config`,
  CONFIGURATION_TESTS: `${SHIPPING}/configuration-tests`,
  AGGREGATORS: `${SHIPPING}/aggregators`,

  SHOPPER_PORTAL,
  THEME: `${SHOPPER_PORTAL}/theme`,
  LOGIN_PAGE: `${SHOPPER_PORTAL}/login-page`,
  TRACKING_PAGE: `${SHOPPER_PORTAL}/tracking-page`,
  EMAIL_TEMPLATES: `${SHOPPER_PORTAL}/email-communications`,
  CUSTOMER_CARE: `${SHOPPER_PORTAL}/customer-care`,
  POLICY_POPUP: `${SHOPPER_PORTAL}/custom-popup`,
  SERVICE_REQUEST_PREFIX: `${SHOPPER_PORTAL}/service-request-prefix`
};
