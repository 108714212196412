import { DOCUMENTAION_LINKS } from 'dictionary';
import dashboard1 from 'assets/img/feature-upsell/dashboard-1.png';
import dashboard2 from 'assets/img/feature-upsell/dashboard-2.png';
import insights1 from 'assets/img/feature-upsell/insights-1.png';
import insights2 from 'assets/img/feature-upsell/insights-2.png';
import shopperGroups1 from 'assets/img/feature-upsell/shopper-groups-1.png';
import shopperGroups2 from 'assets/img/feature-upsell/shopper-groups-2.png';
import enterpriseShipping1 from 'assets/img/feature-upsell/enterprise-shipping-1.png';
import enterpriseShipping2 from 'assets/img/feature-upsell/enterprise-shipping-2.png';
import giftReturns1 from 'assets/img/feature-upsell/gift-returns-1.png';
import giftReturns2 from 'assets/img/feature-upsell/gift-returns-2.png';
import multiCurrency1 from 'assets/img/feature-upsell/multi-currency-1.png';
import bXgY1 from 'assets/img/feature-upsell/bxgy-1.png';
import exchangeRecommendations1 from 'assets/img/feature-upsell/exchange-recommendations-1.png';
import exchangeRecommendations2 from 'assets/img/feature-upsell/exchange-recommendations-2.png';
import discountForwarding1 from 'assets/img/feature-upsell/discount-forwarding-1.png';
import honorInventoryPolicy1 from 'assets/img/feature-upsell/honor-inventory-policy-1.png';
import honorOriginalPriceOnVariantExchange1 from 'assets/img/feature-upsell/honor-original-price-on-variant-exchange-1.png';
import exchangeOrderServiceablity1 from 'assets/img/feature-upsell/exchange-order-serviceablity-1.png';
import autoApproveLockedRequest1 from 'assets/img/feature-upsell/auto-approve-locked-request-1.png';
import bonusCash1 from 'assets/img/feature-upsell/bonus-cash-1.png';
import bonusCash2 from 'assets/img/feature-upsell/bonus-cash-2.png';
import returnProcessingFee1 from 'assets/img/feature-upsell/return-processing-fee-1.png';
import promoManagement1 from 'assets/img/feature-upsell/promo-management-1.png';
import promoManagement2 from 'assets/img/feature-upsell/promo-management-2.png';
import serviceWindowOrderDate1 from 'assets/img/feature-upsell/service-window-order-date-1.png';
import serviceWindowOrderDate2 from 'assets/img/feature-upsell/service-window-order-date-2.png';
import serviceWindowSku1 from 'assets/img/feature-upsell/service-window-sku-1.png';
import serviceWindowSku2 from 'assets/img/feature-upsell/service-window-sku-2.png';
import serviceWindowProductType1 from 'assets/img/feature-upsell/service-window-product-type-1.png';
import serviceWindowProductType2 from 'assets/img/feature-upsell/service-window-product-type-2.png';
import trackingPage1 from 'assets/img/feature-upsell/tracking-page-1.png';
import trackingPage2 from 'assets/img/feature-upsell/tracking-page-2.png';
import manageApis1 from 'assets/img/feature-upsell/manage-apis-1.png';
import manageApis2 from 'assets/img/feature-upsell/manage-apis-2.png';
import inStoreReturn1 from 'assets/img/feature-upsell/in-store-return-1.png';
import inStoreReturn2 from 'assets/img/feature-upsell/in-store-return-2.png';
import serviceInternationalOrders1 from 'assets/img/feature-upsell/service-international-orders-1.png';

export const THEME = {
  RED: 'red',
  PURPLE: 'purple',
  GREEN: 'green'
};

export const INFO = {
  ANALYTICS_DASHBOARD: {
    title: 'Dashboard',
    includes: [
      'Returns and exchanges, service window analysis',
      'Including order ageing, disposition, and time elapsed'
    ],
    docUrl: DOCUMENTAION_LINKS.ANALYTICS.DASHBOARD,
    images: [dashboard1, dashboard2],
    theme: THEME.RED
  },
  ANALYTICS_INSIGHTS: {
    title: 'Insights',
    includes: [
      'Insights on product and request reason breakdown',
      'Including highest volume, most returned, return rate by product, and product-level breakdown.'
    ],
    docUrl: DOCUMENTAION_LINKS.ANALYTICS.INSIGHTS,
    images: [insights1, insights2],
    theme: THEME.PURPLE
  },
  SHOPPER_GROUPS: {
    title: 'Shopper Groups',
    includes: [
      'Segment shoppers based on return and exchange activity',
      'Provide targeted incentives or levy fees based on groups'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.SHOPPER_GROUPS,
    images: [shopperGroups1, shopperGroups2],
    theme: THEME.RED
  },
  EXCHANGE_RECOMMENDATIONS: {
    title: 'Exchange Recommendations',
    includes: [
      'Suggest products to enhance customer experience',
      'Suggest best selling products or relevant products from the same category to increase conversions'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.EXCHANGE_RECOMMENDATIONS,
    images: [exchangeRecommendations1, exchangeRecommendations2],
    theme: THEME.PURPLE
  },
  EXCHANGE_BONUS_CASH: {
    title: 'Exchange Bonus Cash',
    includes: [
      'Offer bonus cash rewards for choosing exchanges over returns',
      'Encourage retention with financial incentives'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.EXCHANGE_BONUS_CASH,
    images: [bonusCash1, bonusCash2],
    theme: THEME.PURPLE
  },
  RETURN_PROCESSING_FEE: {
    title: 'Return Processing Fee',
    includes: [
      'Configure a one-time returns processing fee per service request',
      'Set different fees for returns versus exchanges to optimize costs'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.RETURN_PROCESSING_FEE,
    images: [returnProcessingFee1],
    theme: THEME.GREEN
  },
  PROMO_MANAGEMENT: {
    title: 'Promo Management',
    includes: [
      'Add promo durations and products they apply on to streamline promotions before you make them live on your website'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.PROMO_MANAGEMENT,
    images: [promoManagement1, promoManagement2],
    theme: THEME.RED
  },
  SERVICE_WINDOW_ORDER_DATE: {
    title: 'Service Window - Order Date',
    includes: [
      'Configure different durations of return serviceability based on product types'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.SERVICE_WINDOW.ORDER_DATE_EXCEPTIONS,
    images: [serviceWindowOrderDate1, serviceWindowOrderDate2],
    theme: THEME.GREEN
  },
  SERVICE_WINDOW_SKU_BASED: {
    title: 'SKU based Service Window',
    includes: [
      'Configure different durations of return serviceability based on Product SKUs'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.SERVICE_WINDOW.PRODUCT_SKU_EXCEPTIONS,
    images: [serviceWindowSku1, serviceWindowSku2],
    theme: THEME.RED
  },
  SERVICE_WINDOW_PRODUCT_TYPE_BASED: {
    title: 'Product Type based Service Window',
    includes: [
      'Configure different durations of return serviceability based on product types'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.SERVICE_WINDOW.PRODUCT_TYPE_EXCEPTIONS,
    images: [serviceWindowProductType1, serviceWindowProductType2],
    theme: THEME.GREEN
  },
  SHIPPING_METHODS_IN_STORE_RETURN: {
    title: 'In-Store Return',
    includes: [
      'Allow customers to return items directly at your retail locations.',
      'Simplify the return process  and configure shipping method specific fees on accepting product returns in-store.'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.SHIPPING_METHODS.IN_STORE_RETURN,
    images: [inStoreReturn1, inStoreReturn2],
    theme: THEME.RED
  },
  ENTERPRISE_SHIPPING: {
    title: 'Enterprise Shipping',
    includes: [
      'Enable routing of your shipments based on origin and destination',
      'Enable routing based on request reasons or specific product'
    ],
    docUrl: '',
    images: [enterpriseShipping1, enterpriseShipping2],
    theme: THEME.GREEN
  },
  TRACKING_PAGE_AD_MANAGEMENT: {
    title: 'Manage Ads on Tracking Page',
    includes: [
      'Reach your shoppers and engage them with your brand story by running ads on their shipment tracking page'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.TRACKING_PAGE,
    images: [trackingPage1, trackingPage2],
    theme: THEME.RED
  },
  API_TOKENS: {
    title: 'API Tokens',
    includes: ['Generate API tokens to integrate with third-party systems'],
    docUrl: DOCUMENTAION_LINKS.SETTINGS.MANAGE_API_TOKENS,
    images: [manageApis1, manageApis2],
    theme: THEME.PURPLE
  },
  DISCOUNT_FORWARDING: {
    title: 'Discount Forwarding',
    includes: [
      'Carry forward discounts from original purchase when customers opt for exchanges',
      'Preserve promotional benefits to boost customer loyalty'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.DISCOUNT_FORWARDING,
    images: [discountForwarding1],
    theme: THEME.RED
  },
  HONOR_INVENTORY_POLICY: {
    title: 'Honor Inventory Policy',
    includes: [
      'Allow shoppers to place exchange requests for ‘Out of Stock’ products',
      'Shipped as soon as the requested products are restocked'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.HONOR_INVENTORY_POLICY,
    images: [honorInventoryPolicy1],
    theme: THEME.GREEN
  },
  HONOR_ORIGINAL_PRICE_ON_VARIANT_EXCHANGE: {
    title: 'Honor Original Price on Variant Exchange',
    includes: [
      'Maintain original purchase price during product variant exchanges'
    ],
    docUrl:
      DOCUMENTAION_LINKS.CONFIGURE.HONOR_ORIGINAL_PRICE_ON_VARIANT_EXCHANGE,
    images: [honorOriginalPriceOnVariantExchange1],
    theme: THEME.PURPLE
  },
  EXCHANGE_ORDER_SERVICEABILITY: {
    title: 'Exchange Order Serviceability',
    includes: [
      'Allow shoppers to return or exhange a previously exchanged product'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.EXCHANGE_ORDER_SERVICEABILITY,
    images: [exchangeOrderServiceablity1],
    theme: THEME.RED
  },
  AUTO_APPROVE_LOCKED_EXCHANGES_WHEN_INVENTORY_BACK_IN_STOCK: {
    title: 'Auto-approve locked exchanges when inventory is restored',
    includes: [
      'Auto approve locked exchanges as soon as the requested products are restocked'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.AUTO_APPROVE_WHEN_BACK_IN_STOCK,
    images: [autoApproveLockedRequest1],
    theme: THEME.GREEN
  },
  GIFT_RETURNS: {
    title: 'Gift Returns',
    includes: [
      'Allow your shoppers hassle-free gift returns without having to notifying the sender',
      'Refund via gift cards or store credits for revenue retention'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.GIFT_RETURNS,
    images: [giftReturns1, giftReturns2],
    theme: THEME.PURPLE
  },
  MULTI_CURRENCY_ORDER: {
    title: 'Multi-currency Order',
    includes: [
      'Service orders in multiple currencies and allow flexibility for international customers',
      'Increased market reach and compatibility with global e-commerce trends'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.MULTI_CURRENCY_ORDER,
    images: [multiCurrency1],
    theme: THEME.GREEN
  },
  BXGY_ORDER_SERVICEABILITY_PAID_ITEMS_ONLY: {
    title: 'bXgY Order Serviceability - paid items',
    includes: [
      'Configured serviceability for Buy X get Y orders for paid items '
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.BXGY_PAID_ONLY,
    images: [bXgY1],
    theme: THEME.GREEN
  },
  INTERNATIONAL_ORDERS_SERVICEABILITY: {
    title: 'Service International Orders',
    includes: [
      'Service customers across the globe and increase your store’s market reach and compatibility with global e-commerce trends'
    ],
    docUrl: DOCUMENTAION_LINKS.CONFIGURE.INTERNATIONAL_ORDERS_SERVICEABILITY,
    images: [serviceInternationalOrders1],
    theme: THEME.PURPLE
  }
};
