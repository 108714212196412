import _get from 'lodash/get';

import { localStore } from 'utils';

function getDiscounTypeChoices() {
  const masterData = JSON.parse(localStore.get('dictionary'));
  const discountTypeChoices = _get(masterData, 'discount_type_choices', {});
  return discountTypeChoices;
}

export function getDiscountTypeOptions() {
  const discountTypeChoices = getDiscounTypeChoices();
  return Object.keys(discountTypeChoices).reduce((acc, key) => {
    const option = { label: discountTypeChoices[key], value: key };
    acc.push(option);
    return acc;
  }, []);
}

export function getDiscountTypesMap() {
  const discountTypeChoices = getDiscounTypeChoices();
  return Object.keys(discountTypeChoices).reduce((acc, key) => {
    const option = { label: discountTypeChoices[key], value: key };
    acc[key] = option;
    return acc;
  }, {});
}
