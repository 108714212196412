import React from 'react';
import cn from 'classnames';
import _isFunction from 'lodash/isFunction';
import styles from './index.module.scss';

/**
 * @typedef {Object} Props
 * @property {string} id HTML id to be attached to the input tag
 * @property {string} containerId HTML id to be attached to the label tag
 * @property {boolean} isActive Boolean flag to set the toggle state to enable/disable mode
 * @property {boolean} disabled provide a truthy value to disable the toggle
 * @property {'sm'|'lg'} size Renders a smaller toggle if the flag value is truthy
 * @property {string} name value for the name property of the input
 * @property {string} className CSS class name to be added to the label tag
 * @property {OnClick} onClick Click event triggered on clicking the toggle
 * @property {string} dataAction value for the data property "data-action" of the input
 * @property {string|number} dataIndex value for the data property "data-index" of the input
 */

/**
 * Props of the <Toggle /> component
 * @param {Props} props
 */
export default function Toggle(props) {
  const {
    id = '',
    containerId = '',
    isActive = false,
    disabled = false,
    size = 'sm',
    name = 'customCheckbox',
    className = '',
    onClick = () => {},
    dataAction = '',
    dataIndex = null
  } = props;

  const onChange = event => {
    if (disabled || !_isFunction(onClick)) return;
    onClick({ event, ...props });
  };

  return (
    <label
      id={containerId}
      className={cn('switch', styles.switch, className, {
        [styles.small]: size === 'sm',
        [styles.large]: size === 'lg'
      })}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={isActive}
        disabled={disabled}
        onChange={onChange}
        data-index={dataIndex}
        data-action={dataAction}
      />
      <span className={styles.slider} />
    </label>
  );
}
