import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import styles from './index.module.scss';

import { ReactTablesContext } from 'components/ReactTable';
import Loader from '../Loader';

const TITLE_LENGTH_LIMIT = 40;

export default function Products({ selected = null, onSelect = () => {} }) {
  const context = useContext(ReactTablesContext);
  const products = _get(context, 'data', []);
  const selectedProductId = _get(selected, 'ObjectId', null);

  const items = useMemo(() => {
    return products.map(data => {
      const product = _get(data, '_source', {});
      const id = _get(product, 'ObjectId', '');
      const title = _get(product, 'title', '');
      const image_url = _get(product, 'image_url', '');

      const isSelected = id === selectedProductId;
      let name = _capitalize(title);
      if (title.length > TITLE_LENGTH_LIMIT) {
        name = `${name.substring(0, TITLE_LENGTH_LIMIT)}...`;
      }

      return (
        <li
          key={id}
          onClick={() => onSelect(product)}
          className={cn(styles.item, { [styles.selected]: isSelected })}
        >
          <div style={{ backgroundImage: `url(${image_url})` }} />
          <span>{name}</span>
        </li>
      );
    });
  }, [products, selectedProductId, onSelect]);

  return (
    <Loader>
      <ul className={styles.container}>{items}</ul>
    </Loader>
  );
}
