import React from 'react';
import styles from './index.module.scss';

import { Skeleton, UiKit } from 'components';
import Layout from '../Layout';
import Card from '../Card';

export default function LoaderSkeleton({ isLoading = false, children = null }) {
  return !isLoading ? (
    children
  ) : (
    <Layout className={styles.container}>
      <UiKit.GridRow templateColumns="1fr" gap="15">
        <UiKit.GridRow templateColumns="1fr" gap="3">
          <UiKit.GridRow templateColumns="1fr 64px" gap="18">
            <Skeleton width="140px" />
            <Skeleton />
          </UiKit.GridRow>
          <UiKit.GridRow templateColumns="1fr auto" gap="18">
            <UiKit.GridRow templateColumns="1fr" gap="4">
              <Skeleton width="180px" />
              <Skeleton width="200px" />
            </UiKit.GridRow>
            <UiKit.GridRow templateColumns="81px 95px" gap="12">
              <Skeleton height="27px" />
              <Skeleton height="27px" />
            </UiKit.GridRow>
          </UiKit.GridRow>
        </UiKit.GridRow>
        <Card className={styles.jumboCard}>
          <Skeleton height="37px" className="mb-0" />
          <UiKit.GridRow templateColumns="1fr" gap="10">
            <div className="d-flex justify-content-between">
              <Skeleton width="40px" />
              <Skeleton width="160px" />
            </div>
            <div className="d-flex justify-content-between">
              <Skeleton width="38px" />
              <Skeleton width="200px" />
            </div>
            <div className="d-flex justify-content-between">
              <Skeleton width="42px" />
              <Skeleton width="140px" />
            </div>
            <div className="d-flex justify-content-between">
              <Skeleton width="56px" />
              <div className="d-flex flex-column align-items-end">
                <Skeleton width="120px" />
                <Skeleton width="180px" />
                <Skeleton width="80px" />
              </div>
            </div>
          </UiKit.GridRow>
        </Card>
      </UiKit.GridRow>
      <UiKit.GridRow templateColumns="1fr" gap="26">
        <UiKit.GridRow templateColumns="1fr" gap="12">
          <Skeleton width="180px" />
          <UiKit.GridRow
            templateColumns="70px 1fr"
            gap="14"
            className={styles.card}
          >
            <Skeleton width="70px" height="76px" className={styles.img} />
            <div className="d-flex flex-column justify-content-between">
              <Skeleton width="70%" className="m-0" />
              <Skeleton width="50%" className="m-0" />
              <Skeleton width="60%" className="m-0" />
            </div>
          </UiKit.GridRow>
        </UiKit.GridRow>
        <UiKit.GridRow
          templateColumns="1fr"
          gap="18"
          className={styles.leftOffset}
        >
          <div className="d-flex justify-content-between">
            <Skeleton width="88px" />
            <Skeleton width="54px" />
          </div>
          <div className="d-flex justify-content-between">
            <Skeleton width="148px" />
            <Skeleton width="54px" />
          </div>
          <div className="d-flex justify-content-between">
            <Skeleton width="160px" />
            <Skeleton width="54px" />
          </div>
        </UiKit.GridRow>
      </UiKit.GridRow>
    </Layout>
  );
}
