import React, { useCallback, useMemo, useState } from 'react';
import { Collapse } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import _size from 'lodash/size';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { getOpenState } from './util';
import { ReactComponent as CollapseArrow } from 'assets/img/icons/new/collapse-right-arrow.svg';

/**
 * @typedef {Object} Option
 * @property {string} id
 * @property {string} label
 * @property {boolean} checked
 * @property {Option[]} options
 * @property {Object} rawData
 */

/**
 * @param {Object} Props
 * @param {Record<string, boolean>} Props.valuesMap
 * @param {Option[]} Props.options
 * @param {true} Props.defaultOpen
 * @param {Function} Props.onToggle
 * @param {(option: Option) => React.ReactNode} Props.render
 */
export default function NestedCheckboxes({
  isMulti = true,
  valuesMap = {},
  options = [],
  defaultOpen = true,
  onToggle = () => {},
  render = () => null,
  wrapperClassName = '',
  optionClassName = '',
  checkboxClassName = '',
  checkboxTextClassName = '',
  checkboxContentClassName = '',
  checkboxWrapperClassName = '',
  isDisabled = false
}) {
  const [openState, setOpenState] = useState(
    getOpenState(options, defaultOpen)
  );

  const onToggleCollapse = useCallback(id => {
    setOpenState(prevState => {
      return {
        ...prevState,
        [id]: !_get(prevState, id, false)
      };
    });
  }, []);

  const renderOptions = useCallback(
    options => {
      const renderNestedOptions = option => {
        const optionId = _get(option, 'id', '');
        const optionLabel = _get(option, 'label', '');
        const nestedOptions = _get(option, 'options', []);
        const isCheckBoxDisabled =
          isDisabled || _get(option, 'isDisabled', false);
        const indeterminate = _get(option, 'indeterminate', false);
        const isChecked = isMulti
          ? _get(valuesMap, optionId, false)
          : valuesMap === optionId;
        const ifNestedOptionsExist = _size(nestedOptions) > 0;

        const checkbox = (
          <UiKit.Checkbox
            title={optionLabel}
            checked={isChecked}
            disabled={isCheckBoxDisabled}
            indeterminate={indeterminate}
            toggle={() => onToggle(optionId, option)}
            wrapperClassName={cn('w-100', checkboxWrapperClassName)}
            contentClassName={cn('w-100', checkboxContentClassName)}
            textClassName={cn('p-0', checkboxTextClassName)}
            className={cn(styles.checkbox, checkboxClassName)}
            renderTitle={uniqueId => render(option, uniqueId)}
          />
        );

        if (!ifNestedOptionsExist) {
          return (
            <div
              key={optionId}
              className={cn(
                'd-flex align-items-center',
                styles.listContainer,
                optionClassName
              )}
            >
              <span className={styles.nestedCheckboxArrow} />
              {checkbox}
            </div>
          );
        }

        return (
          <div
            key={optionId}
            className={cn(styles.listContainer, optionClassName)}
          >
            <span className={cn('cursor-pointer', styles.listItem)}>
              <div className="d-flex align-items-center">
                <span className={styles.nestedCheckboxArrow}>
                  <CollapseArrow
                    onClick={() => onToggleCollapse(optionId)}
                    className={cn(styles.arrow, {
                      [styles.open]: openState[optionId]
                    })}
                  />
                </span>
                {checkbox}
              </div>
            </span>
            <Collapse isOpen={openState[optionId]}>
              {renderOptions(nestedOptions)}
            </Collapse>
          </div>
        );
      };

      return options.map(option => renderNestedOptions(option));
    },
    [
      valuesMap,
      isMulti,
      isDisabled,
      openState,
      onToggle,
      optionClassName,
      checkboxClassName,
      checkboxTextClassName,
      checkboxWrapperClassName,
      checkboxContentClassName,
      render,
      onToggleCollapse
    ]
  );

  const checkboxes = useMemo(() => renderOptions(options), [
    options,
    renderOptions
  ]);

  return (
    <div className={cn(styles.nestedCheckboxWrapper, wrapperClassName)}>
      {checkboxes}
    </div>
  );
}
