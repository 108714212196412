import localStore from 'utils/localStore';
import findDomain from 'utils/findDomain';

export default function getCustomerPortalLink() {
  try {
    let domains = JSON.parse(localStore.get('customerPortalDomains')) || [];
    return findDomain(domains);
  } catch (e) {
    return '';
  }
}
