import uuid from 'uuid/v4';
import _get from 'lodash/get';

import { getNestedCheckboxValuesMap } from 'components/UiKit/NestedCheckboxes/util';

/**
 * Format Data recevied from server to make it form compatible
 * @param  {object} data API data
 * @return {object} formatted data
 */
export function createFormData(data) {
  if (!data) return data;

  const dataset = { ...data };
  const collections = _get(dataset, 'collections', []);
  const productTypes = _get(dataset, 'product_types', []);
  const excludedTags = _get(dataset, 'excluded_tags', []);
  const productCategories = _get(dataset, 'product_categories', []);

  dataset.collections = collections.map(item => {
    const id = _get(item, 'id');
    const label = _get(item, 'title', '');
    const visible = _get(item, 'visible', false);
    return { id, label, visible, title: label };
  });

  dataset.product_types = productTypes.reduce((acc, item) => {
    const label = _get(item, 'title', '');
    if (!!label) {
      const id = _get(item, 'id', uuid());
      const visible = _get(item, 'visible', false);
      acc.push({ id, label, visible, title: label });
    }
    return acc;
  }, []);

  dataset.excluded_tags = excludedTags.map(tag => ({ value: tag, label: tag }));

  dataset.product_categories = getNestedCheckboxValuesMap(productCategories, {
    checkIfChecked: category => _get(category, 'visible', false)
  });

  return dataset;
}
