import React from 'react';
import { renderToString } from 'react-dom/server';

import { ProgressIndicator, StepText } from 'containers/TourGuides/components';
import {
  beforeShowPromise,
  getShepherdSelector
} from 'containers/TourGuides/methods';
import ADMIN_ROUTES from 'config/nav/admin/routes';
import { getGiftCardCopy } from 'utils/methods';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';
import { ROUTES } from 'views/Configure/ReturnManagement/RefundMethods/stubs';

const RETURN_MANAGEMENT_PATH = ADMIN_ROUTES.CONFIGURE_RR.RETURN_MANAGEMENT;

/**
 * @returns {import("react-shepherd").ShepherdOptionsWithType[]}
 */
export default function getRefundMethodsSteps({
  push = () => {},
  onShow = () => {},
  onHide = () => {}
}) {
  const { giftCardText } = getGiftCardCopy();
  return [
    {
      id: 'refund-methods-intro',
      title: 'Refund Methods',
      text: renderToString(
        <>
          <StepText>
            Configure the refund methods that you want to offer to your shoppers
            here.
          </StepText>
          <ProgressIndicator current={1} total={12} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({ push, path: RETURN_MANAGEMENT_PATH });
      },
      buttons: [{ text: "Let's go", type: 'next' }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-methods-intro',
      attachTo: {
        element: getShepherdSelector('card-refund-methods'),
        on: 'left'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: RETURN_MANAGEMENT_PATH,
          selector: this.attachTo.element
        });
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText className="mb-1">
            Refund Methods can be found in the Configure section, under Return
            Management.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on <code>Manage</code> button to continue.
          </StepText>
          <ProgressIndicator current={2} total={12} />
        </>
      ),
      advanceOn: {
        selector: `${getShepherdSelector('card-refund-methods')} a`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-methods-intro',
      beforeShowPromise,
      text: renderToString(
        <>
          <StepText className="mb-1">
            Here you can view and configure your refund methods. Enabled refund
            methods will be visible while expecting a refund when a shopper
            places a request.
          </StepText>
          <ProgressIndicator current={3} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-methods-types-gift-card',
      attachTo: {
        element: getShepherdSelector('refund-method-GIFT_CARD'),
        on: 'right'
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>
            You can allow shoppers can choose {giftCardText} as their refund
            method.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on the{' '}
            <code>
              <EditIcon width={12} height={12} />
            </code>{' '}
            icon to see the configurations.
          </StepText>
          <ProgressIndicator current={4} total={12} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.HOME,
          selector: this.attachTo.element
        });
      },
      advanceOn: {
        selector: `${getShepherdSelector(
          'refund-method-GIFT_CARD'
        )} .CustomTable__Action`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-method-GIFT_CARD-toggles',
      attachTo: {
        element: getShepherdSelector('refund-method-GIFT_CARD-toggles'),
        on: 'right'
      },
      canClickTarget: false,
      text: renderToString(
        <>
          <StepText>
            Modify what you would like this method to show up as during the
            return process for shoppers to see.
            <br />
            <br />
            You can also turn this off or make this the default method.
          </StepText>
          <ProgressIndicator current={5} total={12} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.REFUND_METHOD_DETAILS_EDIT.replace(':id', 'gift-card'),
          selector: this.attachTo.element
        });
      },
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-method-GIFT_CARD-service-window',
      attachTo: {
        element: getShepherdSelector('refund-method-GIFT_CARD-service-window'),
        on: 'right'
      },
      canClickTarget: false,
      text: renderToString(
        <>
          <StepText>
            You can have separate service windows for the {giftCardText} & the
            Original Payment Method.
            <br />
            <br />
            With this, you can now offer additional service window to your
            shopper where they can now only get {giftCardText} once the Original
            Payment Method window crosses.
          </StepText>
          <ProgressIndicator current={6} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-method-GIFT_CARD-incentive',
      attachTo: {
        element: getShepherdSelector('refund-method-GIFT_CARD-incentive'),
        on: 'right'
      },
      canClickTarget: false,
      text: renderToString(
        <>
          <StepText>
            Here you can control if and how much of an incentive shopper would
            get if they choose {giftCardText} for refund.
            <br />
            <br />
            This could be a highly influential way to incentivise your shoppers
            to choose {giftCardText}.
          </StepText>
          <ProgressIndicator current={7} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-method-breadcrumb',
      attachTo: {
        element: getShepherdSelector('refund-method-edit-back-button'),
        on: 'top'
      },
      canClickTarget: true,
      advanceOn: {
        selector: getShepherdSelector('refund-method-edit-back-button'),
        event: 'click'
      },
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.REFUND_METHOD_DETAILS_EDIT.replace(':id', 'gift-card'),
          selector: this.attachTo.element
        });
      },
      text: renderToString(
        <>
          <StepText>
            Let's revisit the refund method home page to explore more about the
            feature.
          </StepText>
          <ProgressIndicator current={8} total={12} />
        </>
      ),
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-method-ORIGINAL_PAYMENT_METHOD',
      attachTo: {
        element: getShepherdSelector('refund-method-ORIGINAL_PAYMENT_METHOD'),
        on: 'right'
      },
      canClickTarget: true,
      text: renderToString(
        <>
          <StepText>
            Here you can modify your preferences for the Original Payment Method
            as the refund method.
          </StepText>
          <br />
          <StepText callout={false}>
            Click on the{' '}
            <code>
              <EditIcon width={12} height={12} />
            </code>{' '}
            icon to see the configurations.
          </StepText>
          <ProgressIndicator current={9} total={12} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.HOME,
          selector: this.attachTo.element
        });
      },
      advanceOn: {
        selector: `${getShepherdSelector(
          'refund-method-ORIGINAL_PAYMENT_METHOD'
        )} .CustomTable__Action`,
        event: 'click'
      },
      buttons: [{ text: 'Back', type: 'back', secondary: true }],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-method-ORIGINAL_PAYMENT_METHOD-toggles',
      attachTo: {
        element: getShepherdSelector(
          'refund-method-ORIGINAL_PAYMENT_METHOD-toggles'
        ),
        on: 'right'
      },
      canClickTarget: false,
      text: renderToString(
        <>
          <StepText>
            Modify what you would like this method to show up as during the
            return process for shoppers to see.
            <br />
            <br />
            You can also turn this on or off for your shoppers.
          </StepText>
          <ProgressIndicator current={10} total={12} />
        </>
      ),
      beforeShowPromise() {
        return beforeShowPromise({
          push,
          path: ROUTES.REFUND_METHOD_DETAILS_EDIT.replace(
            ':id',
            'original-payment-method'
          ),
          selector: this.attachTo.element
        });
      },
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-method-ORIGINAL_PAYMENT_METHOD-service-window',
      attachTo: {
        element: getShepherdSelector(
          'refund-method-ORIGINAL_PAYMENT_METHOD-service-window'
        ),
        on: 'right'
      },
      canClickTarget: false,
      text: renderToString(
        <>
          <StepText>
            Here you can modify how long will your shoppers have until they can
            choose this method as their choice of Refund Method.
          </StepText>
          <ProgressIndicator current={11} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Got it', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    },
    {
      id: 'refund-method-ORIGINAL_PAYMENT_METHOD-charges',
      attachTo: {
        element: getShepherdSelector(
          'refund-method-ORIGINAL_PAYMENT_METHOD-charges'
        ),
        on: 'right'
      },
      canClickTarget: false,
      text: renderToString(
        <>
          <StepText>
            Here you can charge a processing fee from your shoppers if they
            choose original payment method as the refund method.
          </StepText>
          <ProgressIndicator current={12} total={12} />
        </>
      ),
      buttons: [
        { text: 'Back', type: 'back', secondary: true },
        { text: 'Done', type: 'next' }
      ],
      when: { show: onShow, hide: onHide }
    }
  ];
}
