import React from 'react';

import {
  RecommendationConfigProvider,
  Loader,
  RecommendationTitle,
  RecommendationRule,
  RecommendationCustomRules,
  RecommendationRequestReasons,
  RecommendationActivationToggle,
  RecommendationActions,
  ConfirmationModal
} from './components';

/**
 * @param {Object} props
 * @param {string} props.homeRoute
 * @param {import("./types").RecommendationType} props.type
 */
export default function RecommendationConfig({ type, homeRoute, children }) {
  return (
    <RecommendationConfigProvider type={type} homeRoute={homeRoute}>
      {children}
    </RecommendationConfigProvider>
  );
}

RecommendationConfig.Loader = Loader;
RecommendationConfig.Title = RecommendationTitle;
RecommendationConfig.Rule = RecommendationRule;
RecommendationConfig.CustomRules = RecommendationCustomRules;
RecommendationConfig.RequestReasons = RecommendationRequestReasons;
RecommendationConfig.ActivationToggle = RecommendationActivationToggle;
RecommendationConfig.Actions = RecommendationActions;
RecommendationConfig.ConfirmationModal = ConfirmationModal;
