import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isPlainObject';

import { user, localStore, logError } from 'utils';
import {
  getMasterData,
  getAccessData,
  getConfiguration,
  getSubscription,
  logout as logoutUser
} from 'utils/services';
import {
  transformConfiguration,
  saveConfigurationInStorage,
  createPlanFeaturesMap,
  createFeaturesAccessMap,
  formatAndStoreMasterData
} from 'utils/methods';
import { identifyUser } from 'utils/analytics';
import Loader from 'components/Loading/Loader';

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  state = {
    isLoggedIn: user.isLoggedIn(),
    ready: false,
    masterData: {},
    featureAccess: {},
    userData: user.get(),
    isTermsAccepted: true,
    isSuperuser: false,
    isAddOnStore: false,
    isExternalUser: false,
    externalUserAccess: null,
    isTourShown: false,
    showTour: false,
    configuration: null,
    hasError: false,
    errorObject: null,
    permittedStores: [],
    changePasswordModal: false,
    accessBlocked: null,
    appLevelAlert: null,
    loadedAccessData: false,
    isWarehouseEnabled: false,
    isExchangeAllowed: true,
    storeProperties: {},
    subscriptionDetails: {},
    showSetupChecklist: false,
    isOnboardingComplete: true,
    showSubscriptionModal: false,
    isDefaultWarehouseSetup: false,
    passwordChangeRequired: user.isPasswordChangeRequired(),
    appType: {
      retex: process.env.REACT_APP_TYPE !== 'SHIPMENT_TRACKING',
      shipmentTracking: process.env.REACT_APP_TYPE === 'SHIPMENT_TRACKING'
    }
  };
  planFeatures = [];
  planFeaturesMap = {};
  featuresPlanMap = {};
  uniqueCategoryPlanFeaturesMap = {};
  subscriptionCheckInterval = null;

  componentDidMount() {
    getMasterData(
      masterData => this.setState({ masterData }),
      () => {},
      () => this.setState({ ready: true })
    );

    // Only perform protected API calls withing this block 👇
    if (this.state.passwordChangeRequired) {
      this.setState({ loadedAccessData: true });
    } else {
      this.loadAccessData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError && prevProps.location !== this.props.location) {
      this.setState({ hasError: false, errorObject: null });
    }

    if (
      this.state.isLoggedIn &&
      !user.isLoggedIn() &&
      this.props.location.pathname !== '/logout'
    ) {
      return logoutUser();
    }

    const {
      appType,
      ready,
      loadedAccessData,
      subscriptionDetails
    } = this.state;
    const source = _get(subscriptionDetails, 'source', '');
    const canRefetch = _get(subscriptionDetails, 'show_usage_metrics', false);
    const usedCount = _get(subscriptionDetails, 'requests_processed_count', 0);
    const allowedCount = _get(subscriptionDetails, 'allowed_requests_count', 0);
    const isQuotaExhausted = canRefetch && usedCount >= allowedCount;

    if (
      canRefetch &&
      appType.retex &&
      loadedAccessData &&
      !prevState.loadedAccessData
    ) {
      // setup time interval to refetch subscription data
      this.subscriptionCheckInterval = setInterval(() => {
        this.syncSubscription();
      }, 3_600_000); // every hour

      // setup daily lookup to remind for subscription in case quota exhausted
      if (source === 'SHOPIFY' && isQuotaExhausted) {
        const localStoreKey = 'rr__last-quota-exhaust-reminder-date';
        const dateFormat = 'DD-MM-YYYY';
        const lastShownDate = JSON.parse(localStore.get(localStoreKey));
        const today = moment().format(dateFormat);
        if (lastShownDate !== today) {
          this.setState({ showSubscriptionModal: true });
          localStore.put(localStoreKey, JSON.stringify(today));
        }
      }
    }

    if (ready && loadedAccessData && !prevState.loadedAccessData) {
      /** @type {import("utils/types").EcommPlatformSource} */
      const ecommPlatform = _get(
        this.state.storeProperties,
        'ecommerce_platform.source'
      );

      if (ecommPlatform) {
        this.setState(currState => {
          return {
            masterData: formatAndStoreMasterData(
              currState.masterData,
              ecommPlatform === 'BIGCOMMERCE'
            )
          };
        });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.subscriptionCheckInterval);
  }

  updateState = (updates = {}) => {
    if (!_isObject(updates)) return;
    this.setState(updates);
  };

  toggleChangePasswordModal = () => {
    if (this.state.isExternalUser) return;
    this.setState({ changePasswordModal: !this.state.changePasswordModal });
  };

  setAppLevelAlert = (type, message) => {
    this.setState({
      appLevelAlert: {
        context: type,
        message: message
      }
    });
  };

  clearAppLevelAlert = () => this.setState({ appLevelAlert: null });

  loadAccessData = (callback = () => {}) => {
    const { isLoggedIn, passwordChangeRequired, appType } = this.state;
    if (!isLoggedIn) return;

    if (passwordChangeRequired) {
      return this.setState({ loadedAccessData: true });
    }

    getAccessData((error, data) => {
      if (!error) {
        const planFeatures = _get(data, 'planFeatures', []);
        const configuration = _get(data, 'configuration', {});
        const subscriptionDetails = _get(data, 'subscription', {});
        const features = _get(subscriptionDetails, 'features', []);
        const configData = transformConfiguration(configuration, appType);
        const isSyncComplete = _get(configData.syncStatus, 'completed', false);

        const {
          planFeaturesMap,
          featuresPlanMap,
          uniqueCategoryPlanFeaturesMap
        } = createPlanFeaturesMap(planFeatures);
        saveConfigurationInStorage(configuration);
        this.planFeatures = planFeatures;
        this.planFeaturesMap = planFeaturesMap;
        this.featuresPlanMap = featuresPlanMap;
        this.uniqueCategoryPlanFeaturesMap = uniqueCategoryPlanFeaturesMap;
        this.setState(
          {
            ...configData,
            subscriptionDetails,
            loadedAccessData: true,
            featureAccess: createFeaturesAccessMap(features)
          },
          () => callback(true)
        );
        if (!isSyncComplete) {
          this.syncConfiguration(callback);
        }
      } else {
        this.error(new Error('Could not load configuration.'));
        this.setState({ loadedAccessData: true });
        callback(false);
      }
    });
  };

  syncConfiguration = (callback = () => {}, delay = 1500) => {
    const { isLoggedIn, passwordChangeRequired, appType } = this.state;

    if (!isLoggedIn || passwordChangeRequired) return;

    setTimeout(() => {
      getConfiguration(
        configuration => {
          const configData = transformConfiguration(configuration, appType);
          const hasSynced = _get(configData.syncStatus, 'completed', false);
          this.setState({ ...configData });
          if (!hasSynced) this.syncConfiguration(callback);
          callback(true);
        },
        () => callback(false)
      );
    }, delay);
  };

  syncSubscription = (forceRefresh = false) => {
    const { subscriptionDetails, appType } = this.state;
    const canRefetch = _get(subscriptionDetails, 'show_usage_metrics', false);
    if (appType.retex && (canRefetch || forceRefresh)) {
      getSubscription(data => {
        const isSame = _isEqual(data, subscriptionDetails);
        const features = _get(data, 'features', []);
        if (isSame) return;
        this.setState({
          subscriptionDetails: data,
          featureAccess: createFeaturesAccessMap(features)
        });
      });
    }
  };

  updateSubscriptionDetails = (isSubscribed = false) => {
    this.setState(currState => {
      const currSubscription = currState.subscriptionDetails;
      const subscribed = _get(currSubscription, 'is_subscribed', false);

      if (subscribed === isSubscribed) return currState;

      return {
        subscriptionDetails: {
          ...currState.subscriptionDetails,
          is_subscribed: isSubscribed
        }
      };
    });
  };

  logout = (onLogout = () => {}) => {
    logoutUser(onLogout);
    clearInterval(this.subscriptionCheckInterval);
    this.planFeatures = [];
    this.planFeaturesMap = {};
    this.featuresPlanMap = {};
    this.uniqueCategoryPlanFeaturesMap = {};
    this.setState({
      isLoggedIn: false,
      userData: null,
      storeProperties: {},
      configuration: null,
      subscriptionDetails: {},
      loadedAccessData: false,
      passwordChangeRequired: false
    });
  };

  login = data => {
    try {
      const { appType } = this.state;
      const subscriptionDetails = _get(data, 'subscription', {});
      const features = _get(subscriptionDetails, 'features', []);
      const planFeatures = _get(data, 'billing_plans', {});
      let userData = _get(data, 'userData', {});
      const configuration = _get(userData, 'configuration', {});
      userData = _omit(userData, 'configuration');

      const configData = transformConfiguration(configuration, appType);
      const { storeProperties, syncStatus } = configData;

      const storeUniqueName = _get(storeProperties, 'store_unique_name', '');
      const changePassword = _get(data, 'password_change.is_needed', false);
      const isSyncComplete = _get(syncStatus, 'completed', false);

      localStore.put('rrloggedInStoreName', storeUniqueName);
      localStore.put('userData', JSON.stringify(userData));
      localStore.put('passwordChangeRequired', JSON.stringify(changePassword));
      saveConfigurationInStorage(configuration);
      const {
        planFeaturesMap,
        featuresPlanMap,
        uniqueCategoryPlanFeaturesMap
      } = createPlanFeaturesMap(planFeatures);
      this.planFeatures = planFeatures;
      this.planFeaturesMap = planFeaturesMap;
      this.featuresPlanMap = featuresPlanMap;
      this.uniqueCategoryPlanFeaturesMap = uniqueCategoryPlanFeaturesMap;
      identifyUser();
      this.setState({
        userData,
        isLoggedIn: true,
        loadedAccessData: true,
        subscriptionDetails,
        passwordChangeRequired: changePassword,
        featureAccess: createFeaturesAccessMap(features),
        ...configData
      });
      if (!isSyncComplete) {
        this.syncConfiguration();
      }
    } catch (e) {}
  };

  changeLocalStoreData = (storageKey, storageValue) => {
    if (!storageValue && !storageKey) return;
    localStore.put(storageKey, storageValue);
  };

  error = errorObject => {
    if (this.state.hasError) return;
    if (errorObject) logError(errorObject);
    this.setState({ hasError: true, errorObject });
  };

  isDummyMerchant(state) {
    const name = _get(state, 'storeProperties.store_name') || '';
    return name.toLowerCase().indexOf('zenwear') > -1;
  }

  defaultWareHouseSetupDone = () => {
    this.setState({ isDefaultWarehouseSetup: true });
  };

  onToggleSetupChecklist = () => {
    this.setState(currState => ({
      showSetupChecklist: !currState.showSetupChecklist
    }));
  };

  render() {
    let {
      ready,
      appType,
      hasError,
      masterData,
      isLoggedIn,
      syncStatus,
      errorObject,
      isSuperuser,
      isAddOnStore,
      isExternalUser,
      externalUserAccess,
      isTourShown,
      showTour,
      accessBlocked,
      configuration,
      featureAccess,
      appLevelAlert,
      isTermsAccepted,
      storeProperties,
      permittedStores,
      isExchangeAllowed,
      isWarehouseEnabled,
      showSetupChecklist,
      changePasswordModal,
      loadedAccessData,
      subscriptionDetails,
      isOnboardingComplete,
      showSubscriptionModal,
      passwordChangeRequired,
      isDefaultWarehouseSetup
    } = this.state;

    if (!ready || (isLoggedIn && !loadedAccessData)) {
      return <Loader />;
    }

    const userData = Object.assign({}, this.state.userData);
    delete userData.groups;

    const ROLES = {};
    ROLES.admin = _get(configuration, 'admin.enabled', false);
    ROLES.store = _get(configuration, 'store.enabled', false);
    ROLES.warehouse = _get(configuration, 'warehouse.enabled', false);
    ROLES.default = ROLES.admin
      ? 'admin'
      : ROLES.store
      ? 'store'
      : ROLES.warehouse
      ? 'warehouse'
      : null;

    if (accessBlocked && accessBlocked.message) {
      appLevelAlert = {
        allowClose: false,
        context: 'warn',
        message: accessBlocked.message
      };
    }

    return (
      <AuthContext.Provider
        value={{
          ROLES,
          appType,
          hasError,
          masterData,
          isLoggedIn,
          syncStatus,
          errorObject,
          isSuperuser,
          isAddOnStore,
          isExternalUser,
          externalUserAccess,
          showTour,
          isTourShown,
          configuration,
          featureAccess,
          appLevelAlert,
          accessBlocked,
          isTermsAccepted,
          storeProperties,
          permittedStores,
          isExchangeAllowed,
          isWarehouseEnabled,
          showSetupChecklist,
          changePasswordModal,
          subscriptionDetails,
          isOnboardingComplete,
          showSubscriptionModal,
          passwordChangeRequired,
          isDefaultWarehouseSetup,
          planFeatures: this.planFeatures,
          planFeaturesMap: this.planFeaturesMap,
          featuresPlanMap: this.featuresPlanMap,
          uniqueCategoryPlanFeaturesMap: this.uniqueCategoryPlanFeaturesMap,
          userData: isLoggedIn ? userData : null,
          login: this.login,
          logout: this.logout,
          generateAppError: this.error,
          updateState: this.updateState,
          syncConfiguration: this.syncConfiguration,
          syncSubscription: this.syncSubscription,
          toggleChangePasswordModal: this.toggleChangePasswordModal,
          updateSubscriptionDetails: this.updateSubscriptionDetails,
          dummyMerchant: this.isDummyMerchant(this.state),
          setAppLevelAlert: this.setAppLevelAlert,
          clearAppLevelAlert: this.clearAppLevelAlert,
          onToggleSetupChecklist: this.onToggleSetupChecklist,
          changeLocalStoreData: this.changeLocalStoreData,
          defaultWareHouseSetupDone: this.defaultWareHouseSetupDone
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const Consumer = AuthContext.Consumer;
const Provider = withRouter(AuthProvider);

export { AuthContext, Provider as AuthProvider, Consumer as AuthConsumer };
