import React, { createContext, useContext } from 'react';
import cn from 'classnames';
import _isFunction from 'lodash/isFunction';
import styles from './index.module.scss';

import { InteractConfigWrapper } from 'components';
import PriceLimitHeader from './PriceLimitHeader';
import PriceLimitToggle from './PriceLimitToggle';
import PriceLimitConfig from './PriceLimitConfig';

const PriceLimitterContext = createContext({
  data: {},
  errors: {},
  entity: '',
  isActive: false,
  isDisabled: false,
  onToggle: () => {},
  onChange: () => {},
  allowToggle: false
});

export const usePriceLimitter = () => useContext(PriceLimitterContext);

const DEFAULT_CALLBACK = () => {};

/**
 * @param {Object} props
 * @param {'incentive' | 'fee'} props.entity
 * @param {Object} props.data
 * @param {Object} props.errors
 * @param {string} props.helpText
 * @param {boolean} props.isDisabled
 * @param {string} props.className
 * @param {() => void} props.onClick
 * @param {(fieldName: string, value: any) => void} props.onChange
 * @param {(isActive: boolean, event: React.ChangeEvent<HTMLInputElement> )} props.onToggle
 * @param {React.ReactNode} props.children
 */
export default function PriceLimitter({
  entity = '',
  data = {},
  errors = {},
  helpText = '',
  isDisabled = false,
  className = '',
  onClick = DEFAULT_CALLBACK,
  onToggle = DEFAULT_CALLBACK,
  onChange = DEFAULT_CALLBACK,
  children = null
}) {
  return (
    <InteractConfigWrapper helptext={helpText}>
      <PriceLimitterContext.Provider
        value={{
          entity,
          data,
          errors,
          onToggle,
          onChange,
          isDisabled,
          allowToggle: onToggle !== DEFAULT_CALLBACK && _isFunction(onToggle)
        }}
      >
        <div onClick={onClick} className={cn(styles.container, className)}>
          {children}
        </div>
      </PriceLimitterContext.Provider>
    </InteractConfigWrapper>
  );
}

PriceLimitter.Header = PriceLimitHeader;
PriceLimitter.Toggle = PriceLimitToggle;
PriceLimitter.Config = PriceLimitConfig;
