import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import Modal, { DEFAULT_CONFIRM_FUNCTION } from '../Modal';

export default function SideSheet({
  size = 'xs', // "xs" | "md" | "lg" | "xl" | "2xl"
  isOpen = false,
  title = '',
  footerInfo = '',
  onEnter = () => {},
  onExit = () => {},
  onOpened = () => {},
  onClose = () => {},
  onClosed = () => {},
  onConfirm = DEFAULT_CONFIRM_FUNCTION,
  isProcessing = false,
  isConfirmDisabled = false,
  hideCancelButton = false,
  confirmBtnType = undefined,
  confirmBtnText = undefined,
  confirmBtnProcessingText = undefined,
  cancelBtnText = undefined,
  className = '',
  contentClassName = '',
  headerClassName = '',
  titleClassName = '',
  footerClassName = '',
  footerInfoClassName = '',
  confirmBtnClassName = '',
  cancelBtnClassName = '',
  bodyClassName = '',
  children = null,
  onBackBtnClick = () => {},
  ...reactstrapModalProps
}) {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      title={title}
      onEnter={onEnter}
      onOpened={onOpened}
      onClose={onClose}
      onClosed={onClosed}
      onExit={onExit}
      onConfirm={onConfirm}
      footerInfo={footerInfo}
      isCancelBtnHidden={hideCancelButton}
      confirmBtnType={confirmBtnType}
      confirmBtnText={confirmBtnText}
      confirmBtnProcessingText={confirmBtnProcessingText}
      cancelBtnText={cancelBtnText}
      isProcessing={isProcessing}
      isConfirmDisabled={isConfirmDisabled}
      className={cn(styles.container, className)}
      contentClassName={cn(styles.content, contentClassName)}
      headerClassName={cn(styles.header, headerClassName)}
      titleClassName={cn(styles.title, titleClassName)}
      footerClassName={cn(styles.footer, footerClassName)}
      footerInfoClassName={cn(styles.footerText, footerInfoClassName)}
      confirmBtnClassName={confirmBtnClassName}
      cancelBtnClassName={cancelBtnClassName}
      modalTransition={{
        timeout: 300, // milliseconds
        appear: false,
        baseClass: styles.modal,
        baseClassActive: styles.show
      }}
      backdropTransition={{ timeout: 0 }}
      {...reactstrapModalProps}
    >
      <div className={cn(styles.body, bodyClassName)}>{children}</div>
    </Modal>
  );
}
