/**
 * @typedef {Object} Info
 * @property {string} title
 * @property {string} description
 * @property {string} icon
 */

/**
 * @type {Record<string, Info>}
 */
export default {
  SHOPPER_GROUPS: {
    title: 'Unlock the Shopper Groups feature',
    description:
      'Create and manage shopper groups for targeted incentives and fees to the shoppers.'
  },
  SHOPPER_GROUPS_PRO: {
    title: 'Unlock Shopper Groups PRO',
    description:
      'Provide targeted incentives with shopper groups based on the order history criteria.'
  },
  EXCHANGE_RECOMMENDATIONS: {
    title: 'Unlock Exchange Recommendations feature',
    description:
      'Let our recommendation system suggest the best exchange products to the shoppers.'
  },
  EXCHANGE_RECOMMENDATIONS_PRO: {
    title: 'Unlock Dynamic AI and Custom Rules',
    description:
      'Provide AI-powered or manually controlled exchange recommendations to the shoppers.'
  },
  AUTOMATION_RULES_PRO: {
    title: 'Unlock Automation Rules PRO',
    description:
      'Automatically approve the return requests based on shipment events without the admin approval.'
  },
  REQUEST_REASONS_PRO: {
    title: 'Unlock Request Reasons PRO',
    description:
      'Enable media upload and offer return reason specific to certain products or product categories.'
  },
  TAG_BASED_RULES_PRO: {
    title: 'Unlock Tag-Based Rules PRO',
    description:
      'Create rules based on the order, customer and discount codes to control the return process.'
  },
  SEND_LABEL_INTERNATIONAL: {
    title: 'Unlock International Shipping Label',
    description:
      'Allow your international orders with prepaid shipment labels for easy returns and exchanges.'
  },
  ENTERPRISE_SHIPPING: {
    title: 'Unlock Enterprise Shipping',
    description:
      'Configure your warehouse to route selective products or all products based on request reasons.'
  },
  REPORTS_PRO: {
    title: 'Unlock more with reports',
    description: 'Get the access to the advanced reports.'
  }
};
