import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { ProductCategoriesList, Toggle, UiKit } from 'components';
import { createNestedCheckboxSchema } from 'components/UiKit/NestedCheckboxes/util';
import { useRecommendationConfig } from '../../hooks';
import { createSelectOptions } from '../../methods';
import { ELEMENT_TYPES } from '../../stubs';

export default function RuleConfiguration({
  configData = [],
  configElements = [],
  onChange = () => {}
}) {
  const { errors, isProcessing } = useRecommendationConfig();

  const renderElements = configElements.map(element => {
    const id = _get(element, 'id', '');
    const type = _get(element, 'type', '');
    const label = _get(element, 'label', '');
    const elementData = _get(element, 'data', null);

    let renderItem = null;
    const value = _get(configData, id, null);
    const error = _get(errors, `config.${id}`, null);

    switch (type) {
      case ELEMENT_TYPES.SELECT:
        const options = createSelectOptions(elementData);
        renderItem = (
          <UiKit.Select
            label={label}
            options={options}
            value={value}
            onChange={value => onChange(id, value)}
            isDisabled={isProcessing}
            error={error}
          />
        );
        break;
      case ELEMENT_TYPES.BOOLEAN:
        renderItem = (
          <div className={styles.toggle} data-disabled={isProcessing}>
            <Toggle
              size="sm"
              isActive={value}
              onClick={() => onChange(id, !value)}
              disabled={isProcessing}
            />
            <label
              onClick={() => onChange(id, !value)}
              className={styles.label}
            >
              {label}
            </label>
          </div>
        );
        break;
      case ELEMENT_TYPES.NESTED_SELECT:
        const nestedOptions = createNestedCheckboxSchema(elementData);
        renderItem = (
          <ProductCategoriesList
            isMulti={false}
            label={label}
            productCategoriesOptions={nestedOptions}
            selected={value}
            updateProductCategories={value => onChange(id, value)}
            isDisabled={isProcessing}
            error={error}
          />
        );
        break;
      default:
        renderItem = null;
    }

    return <li key={id}>{renderItem}</li>;
  });

  if (_isEmpty(renderElements)) return null;

  return <ol className={styles.container}>{renderElements}</ol>;
}
