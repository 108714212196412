import axios from 'axios';
import _isArray from 'lodash/isArray';

export default function getPublishedDeploy(
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  const siteId = process.env.REACT_APP_SITE_ID;
  const accessToken = process.env.REACT_APP_NETLIFY_ACCESS_TOKEN;

  if (!siteId || !accessToken) return;

  axios
    .get(
      `https://api.netlify.com/api/v1/sites/${siteId}/deploys?per_page=1&latest-published=true`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then(({ data = [] }) => {
      if (!_isArray(data)) return;
      onSuccess(data[0]);
    })
    .catch(onError)
    .finally(onFinally);
}
