/**
 * Validates a phone number input to ensure it follows a specific format.
 * The phone number can contain digits, optional '+' sign, parentheses, hyphens, and spaces.
 * It must be between 6 and 16 characters long.
 *
 * @param {string} input - The phone number string to validate.
 * @returns {boolean} `true` if the input is a valid phone number, `false` otherwise.
 *
 * @example
 * // Valid phone numbers
 * validatePhoneNumber('123456'); // true
 * validatePhoneNumber('+123 (456) 7890'); // true
 * validatePhoneNumber('123 (456) 7890'); // true
 * validatePhoneNumber('+1 (234) 567-890'); // true
 *
 * // Invalid phone numbers
 * validatePhoneNumber('12345'); // false (too short)
 * validatePhoneNumber('12345678901234567'); // false (too long)
 * validatePhoneNumber('12345+67890'); // false (invalid placement of the `+`)
 */
export default function validatePhoneNumber(input) {
  // Remove leading/trailing spaces and all spaces from the string
  input = input.trim().replace(/\s/g, '');

  // Regular expression to match valid phone numbers
  const regex = /^\+?[\d()\- \s]{6,16}$/;

  // Test the input against the regex and return the result
  return regex.test(input);
}
