import React, { useContext } from 'react';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import styles from './index.module.scss';

import { OrderServiceContext } from 'components/OrderServiceDetails';

export default function CustomerInfo() {
  const { orderService } = useContext(OrderServiceContext);

  const firstName = _get(orderService, 'shipping_address.first_name', '');
  const lastName = _get(orderService, 'shipping_address.last_name', '');
  const email = _get(orderService, 'shipping_address.email', '');
  const phone = _get(orderService, 'shipping_address.phone', '');
  const address1 = _get(orderService, 'shipping_address.address1', '');
  const address2 = _get(orderService, 'shipping_address.address2', '');
  const city = _get(orderService, 'shipping_address.city', '');
  const province = _get(orderService, 'shipping_address.province', '');
  const zip = _get(orderService, 'shipping_address.zip', '');
  const country = _get(orderService, 'shipping_address.country', '');
  // const shopperGroup = _get(orderService, 'shopper_group_name', '') || 'N/A';

  const customerName = `${_upperFirst(firstName)} ${_upperFirst(lastName)}`;

  return (
    <>
      <div className={styles.row}>
        <span className={styles.label}>Name</span>
        <span className={styles.value}>{customerName}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Email</span>
        <span className={styles.value}>
          {email ? <a href={`mailto:${email}`}>{email}</a> : 'N/A'}
        </span>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Phone</span>
        <span className={styles.value}>
          {phone ? <a href={`tel:${phone}`}>{phone}</a> : 'N/A'}
        </span>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Address</span>
        <div className={styles.column}>
          <span className={styles.value}>
            {address1} {address2 && `, ${address2}`}
          </span>
          <span className={styles.value}>
            {city}, {province} - {zip}
          </span>
          {country && <span className={styles.value}>{country}</span>}
        </div>
      </div>
      {/* <div className={styles.row}>
        <span className={styles.label}>Shopper Group</span>
        <OverflowText
          text={shopperGroup}
          id="shopper-group-name"
          trimLength={42}
          className={styles.value}
        />
      </div> */}
    </>
  );
}
