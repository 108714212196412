import React, { useContext, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { formatPrice } from 'utils/formatters';
import { RetexContext } from 'components/RetexDetails';
import ManualDeduction from '../ManualDeduction';
import { ShopperGroupIncentiveIcon } from 'components/OrderServiceDetails/components';
import { ReactComponent as InfoIcon } from 'assets/img/alert-icons/info.svg';
import { AuthContext } from 'context/AuthContext';

export default function AmountBreakdown() {
  const { retex, isRejected } = useContext(RetexContext);

  const { masterData } = useContext(AuthContext);
  const SHOPPER_GROUPS_OVERRIDES = useRef(
    _get(masterData, 'shopper_group_override_entities', [])
  ).current;
  const ENTITY_OVERRIDES = useRef(
    Array.isArray(SHOPPER_GROUPS_OVERRIDES) &&
      SHOPPER_GROUPS_OVERRIDES.length === 5
      ? SHOPPER_GROUPS_OVERRIDES.map(({ value = '' }) => value)
      : ['', '', '', '', '']
  ).current;

  const id = _get(retex, 'id', '');
  const quantity = _get(retex, 'quantity', 0);
  const currency = _get(retex, 'currency', 'USD');
  const entityType = _get(retex, 'entity_type', '');
  const discountCode = _get(retex, 'discount_code', '');
  const orderedVariantPrice = _get(retex, 'ordered_variant.price', 0);
  const purchaseVariantPrice = _get(retex, 'purchase_variant.price', 0);
  const exchangeVariantPrice = _get(retex, 'exchange_variant.price', 0);
  const amountBreakdown = _get(retex, 'amount_breakdown', {});
  const amount = _get(amountBreakdown, 'amount', 0);
  const tax = _get(amountBreakdown, 'tax', 0);
  const discount = _get(amountBreakdown, 'discount', 0);
  const restockFee = _get(amountBreakdown, 'restocking_fee', 0);
  const exchangeIncentive = _get(amountBreakdown, 'exchange_incentive', 0);
  const orderAmount = _get(amountBreakdown, 'original_order_amount', null);
  const shopperOverrides = _get(retex, 'shopper_group_overrides_applied', []);
  const purchaseIncentive = exchangeIncentive; // Same key is being used to send the purchase incentive value

  const isRefund = amount >= 0;
  const isReturn = entityType === 'RET';
  const isExchange = entityType === 'EXC';
  const isPurchase = entityType === 'PCH';
  const tooltipId = `purchase-price-${id}`;

  const orderPrice = formatPrice(orderedVariantPrice * quantity, currency);
  const purchasePrice = formatPrice(purchaseVariantPrice * quantity, currency);
  const orderAmountValue = formatPrice(orderAmount * quantity, currency);
  const exchangePrice = formatPrice(exchangeVariantPrice * quantity, currency);
  const totalTax = formatPrice(tax * quantity, currency);
  const totalDiscount = formatPrice(discount, currency);
  const totalRestockingFee = formatPrice(restockFee, currency);
  const exchangeIncentiveAmount = formatPrice(exchangeIncentive, currency);
  const purchaseIncentiveAmount = formatPrice(purchaseIncentive, currency);
  const totalAmount = formatPrice(amount, currency);

  const showOriginalOrderAmount = !isPurchase && orderAmount !== null;
  const originalAmountSign = orderAmount < 0 ? '-' : '';
  const originalExchangeValue = `${originalAmountSign} ${orderAmountValue}`;

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Line Item Breakdown</h3>
      <div className={styles.content}>
        <div className={styles.row}>
          <span className={styles.label}>
            {isPurchase ? 'Line Item' : 'Original Purchase'} Price &nbsp;
            <If test={!isPurchase}>
              <InfoIcon id={tooltipId} className={styles.infoIcon} />
              <UncontrolledTooltip target={tooltipId}>
                Includes taxes and discounts applied
              </UncontrolledTooltip>
            </If>
          </span>
          <span className={styles.value}>
            {isPurchase ? purchasePrice : orderPrice}
          </span>
        </div>
        <If test={showOriginalOrderAmount}>
          <div className={styles.row}>
            <span className={styles.label}>
              Original Exchange Payment{' '}
              <InfoIcon id={tooltipId} className={styles.infoIcon} />
              <UncontrolledTooltip target={tooltipId}>
                Includes breakup for previous order amount
              </UncontrolledTooltip>
            </span>
            <span className={styles.value}>{originalExchangeValue}</span>
          </div>
        </If>
        <If test={isExchange}>
          <div className={styles.row}>
            <div className={styles.label}>Price of Exchange Item(s)</div>
            <div className={styles.value}>- {exchangePrice}</div>
          </div>
          <If test={tax}>
            <div className={styles.row}>
              <div className={styles.label}>Tax</div>
              <div className={styles.value}>- {totalTax}</div>
            </div>
          </If>
          <If test={discount}>
            <div className={styles.row}>
              <div className={styles.label}>
                Original Discount{' '}
                <If test={discountCode}>
                  <em className={styles.discountTag}>{discountCode}</em>
                </If>
              </div>
              <div className={styles.value}>+ {totalDiscount}</div>
            </div>
          </If>
          <If test={exchangeIncentive}>
            <div className={styles.row}>
              <div className={styles.label}>
                Additional Exchange Credit{' '}
                <ShopperGroupIncentiveIcon
                  type={ENTITY_OVERRIDES[2]}
                  incentives={shopperOverrides}
                  iconClassName={styles.infoIcon}
                  message="Shopper group's exchange bonus cash applied"
                />
              </div>
              <div className={styles.value}>+ {exchangeIncentiveAmount}</div>
            </div>
          </If>
        </If>
        <If test={isPurchase && purchaseIncentive}>
          <div className={styles.row}>
            <div className={styles.label}>
              Additional Purchase Credit{' '}
              <ShopperGroupIncentiveIcon
                type={ENTITY_OVERRIDES[2]}
                incentives={shopperOverrides}
                iconClassName={styles.infoIcon}
                message="Shopper group's purchase bonus cash applied"
              />
            </div>
            <div className={styles.value}>- {purchaseIncentiveAmount}</div>
          </div>
        </If>
        <If test={!isPurchase}>
          <div className={styles.row}>
            <div className={styles.label}>
              Restocking Fee{' '}
              <ShopperGroupIncentiveIcon
                type={ENTITY_OVERRIDES[4]}
                incentives={shopperOverrides}
                iconClassName={styles.infoIcon}
                message="Shopper group's unit restocking fee applied"
              />
            </div>
            <div className={styles.value}>- {totalRestockingFee}</div>
          </div>
        </If>
        <If test={isReturn}>
          <ManualDeduction
            containerClassName={styles.row}
            labelClassName={styles.label}
            valueClassName={styles.value}
          />
        </If>
        <div className={styles.row}>
          <div className={styles.label}>Line Item Total</div>
          <span>{isRefund ? 'Estimated Refund' : 'Shopper Pays'}</span>
          <div
            className={cn(styles.value, styles.largeText, {
              [styles.striked]: isRejected
            })}
          >
            {totalAmount}
          </div>
        </div>
      </div>
    </div>
  );
}
