import ROUTES from 'views/Configure/stubs/routes';
import SHIPPING_ROUTES from 'views/Configure/Shipping/ShippingMethods/stubs/routes';
import { getGiftCardCopy } from 'utils/methods';

const ID = {
  // General Fees 👇
  ORIGINAL_PAYMENT_METHOD: 'original-payment-method',
  // Shipping Fees 👇
  SEND_LABEL_DOMESTIC_ORDERS: 'domestic',
  SEND_LABEL_INTERNATIONAL_ORDERS: 'international',
  SELF_SHIP: 'fees',
  STORE_RETURN: 'fees',
  HOME_PICKUP: 'fees',
  // Incetives 👇
  PAYMENT_WAIVE_OFF: 'payment-waive-off',
  EXCHANGE_BONUS_CASH: 'exchange-bonus-cash',
  DISCOUNT_FORWARDING: 'discount-forwarding',
  GIFT_CARD_INCENTIVE: 'gift-card',
  PURCHASE_BONUS_CASH: 'purchase-bonus-cash'
};

const ACCESS_KEYS = {
  RETURN_MANAGEMENT:
    'admin.submenu.configure.submenu.return_management.enabled',
  SHIPPING: 'admin.submenu.configure.submenu.shipping.enabled'
};

export default {
  PROCESSING_FEE: {
    TITLE: 'Return Processing Fee',
    HELPTEXT: 'Applies once per request',
    ACCESS_KEY: null,
    CONFIG_ROUTE: null
  },
  RESTOCKING_FEE: {
    TITLE: 'Unit Restocking Fee',
    HELPTEXT: 'Applies to every line-item in a request',
    ACCESS_KEY: null,
    CONFIG_ROUTE: null
  },
  REFUND_METHODS: {
    ORIGINAL_PAYMENT_METHOD: {
      TITLE: 'Payment Processing Fee',
      HELPTEXT: 'Applies once per request',
      ACCESS_KEY: ACCESS_KEYS.RETURN_MANAGEMENT,
      CONFIG_ROUTE: `${ROUTES.REFUND_METHODS}/${ID.ORIGINAL_PAYMENT_METHOD}#fee`
    },
    GIFT_CARD: () => {
      const { giftCardText, giftCardIncentiveText } = getGiftCardCopy();
      return {
        TITLE: giftCardIncentiveText,
        HELPTEXT: `Applies on requests with ${giftCardText} selected for the refund`,
        ACCESS_KEY: ACCESS_KEYS.RETURN_MANAGEMENT,
        CONFIG_ROUTE: `${ROUTES.REFUND_METHODS}/${ID.GIFT_CARD_INCENTIVE}#incentive`
      };
    }
  },
  SHIPPING_METHOD: {
    SEND_LABEL: {
      DOMESTIC: {
        TITLE: 'Send a label - Domestic',
        HELPTEXT: 'Applies once per request',
        ACCESS_KEY: ACCESS_KEYS.SHIPPING,
        CONFIG_ROUTE: `${SHIPPING_ROUTES.SHIPPING_METHOD_DETAILS_EDIT}#${ID.SEND_LABEL_DOMESTIC_ORDERS}`
      },
      INTERNATIONAL: {
        TITLE: 'Send a label - International',
        HELPTEXT: 'Applies once per request',
        ACCESS_KEY: ACCESS_KEYS.SHIPPING,
        CONFIG_ROUTE: `${SHIPPING_ROUTES.SHIPPING_METHOD_DETAILS_EDIT}#${ID.SEND_LABEL_INTERNATIONAL_ORDERS}`
      }
    },
    SELF_SHIP: {
      TITLE: 'Self-managed return',
      HELPTEXT: 'Applies once per request',
      ACCESS_KEY: ACCESS_KEYS.SHIPPING,
      CONFIG_ROUTE: `${SHIPPING_ROUTES.SHIPPING_METHOD_DETAILS_EDIT}#${ID.SELF_SHIP}`
    },
    STORE_RETURN: {
      TITLE: 'In-store returns',
      HELPTEXT: 'Applies once per request',
      ACCESS_KEY: ACCESS_KEYS.SHIPPING,
      CONFIG_ROUTE: `${SHIPPING_ROUTES.SHIPPING_METHOD_DETAILS_EDIT}#${ID.STORE_RETURN}`
    },
    HOME_PICKUP: {
      TITLE: 'At-home pickup',
      HELPTEXT: 'Applies once per request',
      ACCESS_KEY: ACCESS_KEYS.SHIPPING,
      CONFIG_ROUTE: `${SHIPPING_ROUTES.SHIPPING_METHOD_DETAILS_EDIT}#${ID.HOME_PICKUP}`
    }
  },
  EXCHANGES: {
    PAYMENT_WAIVE_OFF: {
      TITLE: 'Payment Waive Off',
      HELPTEXT:
        'Applies on the entire order service. Use this to waive off small amounts owed by the customer.',
      ACCESS_KEY: ACCESS_KEYS.RETURN_MANAGEMENT,
      CONFIG_ROUTE: `${ROUTES.EXCHANGES}#${ID.PAYMENT_WAIVE_OFF}`
    },
    BONUS_CASH_INCENTIVE: {
      TITLE: 'Exchange Bonus Cash',
      HELPTEXT: `Applies per item that's being exchanged for a more expensive item`,
      ACCESS_KEY: ACCESS_KEYS.RETURN_MANAGEMENT,
      CONFIG_ROUTE: `${ROUTES.EXCHANGES}#${ID.EXCHANGE_BONUS_CASH}`
    },
    DISCOUNT_FORWARDING: {
      TITLE: 'Discount Forwarding',
      HELPTEXT:
        'Applies on Exchange Requests for a product purchased with a discount',
      ACCESS_KEY: ACCESS_KEYS.RETURN_MANAGEMENT,
      CONFIG_ROUTE: `${ROUTES.EXCHANGES}#${ID.DISCOUNT_FORWARDING}`
    }
  },
  PURCHASES: {
    BONUS_CASH_INCENTIVE: {
      TITLE: 'Purchase Bonus Cash',
      HELPTEXT:
        "Applies per item that's being purchased while creating a service request",
      ACCESS_KEY: ACCESS_KEYS.RETURN_MANAGEMENT,
      CONFIG_ROUTE: `${ROUTES.PURCHASES}#${ID.PURCHASE_BONUS_CASH}`
    }
  }
};
