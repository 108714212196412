import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { entityTypeChoices, Ret_Exc_Status } from 'utils/DICTIONARY';
import { UiKit, DispositionMode } from 'components';
import { CustomTableRow } from 'components/Tables/CustomTable';
import { Product, RetexAction } from 'views/Requests/components';
import { ROUTES } from 'views/Requests/stubs';
import { ReactComponent as NoteIcon } from 'assets/img/icons/note.svg';
import { ReactComponent as GreenLeafIcon } from 'assets/img/icons/green-leaf.svg';

export default function LineItemsTableRow({
  osId = '',
  data = {},
  osName = '',
  isDisabled = false,
  isProcessing = false,
  onUpdate = () => {},
  onDisable = () => {},
  renderRetexTitle = () => null,
  onAfterProcessing = () => {},
  generateRowData = () => null
}) {
  const id = _get(data, 'id', '');
  const name = _get(data, 'name', '');
  const amount = _get(data, 'amount', 0);
  const status = _get(data, 'status', '');
  const type = _get(data, 'entity_type', '');
  const quantity = _get(data, 'quantity', 0);
  const currency = _get(data, 'currency', 'USD');
  const isKeepItem = _get(data, 'is_keep_item', false);
  const customerNote = _get(data, 'customer_note', '');
  const requestReason = _get(data, 'request_reason', '');
  const retailerNote = _get(data, 'last_retailer_note', '');
  const orderedVariant = _get(data, 'ordered_variant', {});
  const exchangeVariant = _get(data, 'exchange_variant', {});
  const purchaseVariant = _get(data, 'purchase_variant', {});
  const isActive = _get(data, 'is_active', false);
  const isApproved = _get(data, 'is_approved', false);
  const isRejected = _get(data, 'is_rejected', false);
  const isActionable = _get(data, 'is_actionable', false);
  const isChecked = _get(data, 'is_checked', false);
  const allowRestock = _get(data, 'allow_restock', false);
  const willRestock = _get(data, 'will_restock', false);
  const comment = _get(data, 'disposition.comment', '');
  const dispositionMode = _get(data, 'disposition.mode', '');
  const customComment = _get(data, 'disposition.custom_comment', '');

  const retexType = entityTypeChoices(type);
  const retexStatus = Ret_Exc_Status(status);
  const retailerNoteId = `retailer-note-${id}`;
  const customerNoteId = `customer-note-${id}`;
  const isExchange = type === 'EXC' && !!exchangeVariant;
  const isPurchase = type === 'PCH' && !!purchaseVariant;
  const retexDetailsUrl = ROUTES.RETEX_DETAILS.replace(':osId', osId).replace(
    ':retexId',
    id
  );

  const onToggleRetex = () => {
    if (!isActionable || isDisabled) return;
    onUpdate(id, { is_checked: !isChecked });
  };

  const onToggleDisposition = () => {
    if (!allowRestock || !isActionable || isDisabled) return;
    onUpdate(id, { will_restock: !willRestock });
  };

  const onBeforeRejecting = () => onDisable(true);

  const onAfterRejecting = (error, data) => {
    onDisable(false);
    onAfterProcessing(true);
    const newStatus = _get(data, 'status', null);
    if (error || !newStatus) return;
    onUpdate(id, { status: newStatus }, true);
  };

  const retexTitle = renderRetexTitle(osId, data) || (
    <Link
      className="link-text"
      to={{
        pathname: retexDetailsUrl,
        state: { retexName: name, osName }
      }}
    >
      {name}
    </Link>
  );

  const values = generateRowData(osId, data) || [
    {
      lg: 3,
      customClass: styles.titleContainer,
      CustomCell: {
        title: (
          <div className={styles.title}>
            {isActionable && (
              <UiKit.Checkbox
                checked={isChecked}
                toggle={onToggleRetex}
                disabled={isDisabled}
                wrapperClassName={styles.checkbox}
              />
            )}
            {retexTitle}
            {retailerNote && (
              <>
                <NoteIcon id={retailerNoteId} className={styles.retailerNote} />
                <UncontrolledTooltip
                  target={retailerNoteId}
                  innerClassName="text-left"
                >
                  {retailerNote}
                </UncontrolledTooltip>
              </>
            )}
            {customerNote && (
              <>
                <NoteIcon id={customerNoteId} className={styles.customerNote} />
                <UncontrolledTooltip
                  target={customerNoteId}
                  innerClassName="text-left"
                >
                  {customerNote}
                </UncontrolledTooltip>
              </>
            )}
            {isKeepItem && <GreenLeafIcon />}
          </div>
        ),
        subTitle: (
          <UiKit.GridRow
            templateColumns="1fr"
            className={cn(styles.col, 'light-text')}
          >
            <p>{retexType}</p>
            <p>{requestReason}</p>
          </UiKit.GridRow>
        )
      }
    },
    {
      lg: 6,
      customClass: styles.rightOffset,
      CustomComponent: (
        <Product
          amount={amount}
          quantity={quantity}
          currency={currency}
          data={isPurchase ? purchaseVariant : orderedVariant}
        >
          <If test={isExchange}>
            <div className={styles.exchangeItem}>
              <p className={styles.exchangeItemTitle}>Exchange with:</p>
              <Product
                data={exchangeVariant}
                containerClassName={styles.exchangeItemContainer}
                contentClassName={styles.exchangeItemContent}
                imageClassName={styles.exchangeItemImage}
              />
            </div>
          </If>
        </Product>
      )
    },
    {
      lg: 3,
      customClass: cn(styles.actions, 'bold-text'),
      CustomComponent: (
        <>
          <div className={styles.infoWrapper}>
            <p className={styles.infoWrapper}>
              {isProcessing && '...'}
              {retexStatus}
            </p>
          </div>
          <div className={styles.infoWrapper}>
            {isKeepItem ? (
              <p className={styles.keepItemMsg}>Shopper keeps product</p>
            ) : (
              <If test={!isPurchase}>
                {allowRestock ? (
                  <UiKit.Checkbox
                    title="Restock"
                    checked={willRestock}
                    toggle={onToggleDisposition}
                    disabled={!isActionable || isDisabled}
                    textClassName={styles.restockCheckboxLabel}
                  />
                ) : isApproved || isRejected ? (
                  <DispositionMode
                    mode={dispositionMode}
                    className={styles.dispositionMode}
                  />
                ) : null}
              </If>
            )}
          </div>
          <div className={styles.infoWrapper}>
            {isActionable && (
              <RetexAction
                data={data}
                isCompact
                isApproving={false}
                isRestocking={willRestock}
                comment={comment}
                customComment={customComment}
                isDisabled={isDisabled}
                onBeforeProcessing={onBeforeRejecting}
                onAfterProcessing={onAfterRejecting}
              />
            )}
          </div>
        </>
      )
    }
  ];

  return (
    <CustomTableRow
      values={values}
      customTableRowWrapClass={styles.container}
      customTableRowClass={cn(styles.row, {
        [styles.disabled]: !isActive,
        [styles.success]: isApproved
      })}
    />
  );
}
