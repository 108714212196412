import React, { useState, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If, logError } from 'utils';
import { AuthContext } from 'context/AuthContext';
import { CustomToast, Logo, PasswordStrength, UiKit } from 'components';
import { Header } from './components';
import { changePassword } from 'views/ChangePassword/services';
import { formValidatorSchema, INITIAL_VALUES } from './stubs';
import { ToastContainer } from 'react-toastify';

const ForceChangePassword = () => {
  const { search = '' } = useLocation();

  const [loading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);
  const { appType, changeLocalStoreData = () => {} } = useContext(AuthContext);
  const formikRef = useRef(null);
  const triggerSubmit = () => formikRef.current.submitForm();
  const appName = appType.shipmentTracking
    ? 'Tracking Rabbit'
    : 'Return Rabbit';

  const onSubmit = values => {
    if (!isPasswordStrong) return;
    setLoading(true);
    let { currentPassword, newPassword, confirmNewPassword } = values;
    const payload = {
      current_password: currentPassword,
      new_password: newPassword,
      re_new_password: confirmNewPassword,
      routeParam: '?auth_token_required=required'
    };
    changePassword(payload, onPasswordChangeSuccess, onPasswordChangeFailure);
  };

  const onPasswordChangeSuccess = () => {
    setLoading(false);
    setSuccess(true);
    CustomToast({ msg: 'Successfully updated the password.', type: 'success' });
    changeLocalStoreData('passwordChangeRequired', JSON.stringify(false));
    const timer = setTimeout(() => {
      const redirectTo = new URLSearchParams(search).get('redirectTo');
      window.location.href = redirectTo ? redirectTo : '/';
      clearTimeout(timer);
    }, 2000);
  };

  const onPasswordChangeFailure = err => {
    try {
      const parsedErr = JSON.parse(JSON.stringify(err));
      const error = _get(parsedErr, 'response.data.displayMessage', {});
      setLoading(false);
      CustomToast({
        type: 'error',
        msg: _get(
          error,
          'message',
          'Unable to change password at the moment. Please try again later.'
        )
      });
    } catch (e) {
      logError(e);
    }
  };

  return (
    <>
      <Header />
      <div className="d-flex align-items-center justify-content-center mt-3 pb-5">
        <div className={styles.container}>
          <Logo className="mt-5" />
          <div className="mt-4">
            <h4>Please create a secure password to continue</h4>
            <p>
              Before you continue, please create a strong and secure password so
              that your {appName} account is protected. Please follow the
              requirements for the password.
            </p>
          </div>

          <div className="mt-4">
            <Formik
              ref={formikRef}
              onSubmit={onSubmit}
              initialValues={INITIAL_VALUES}
              validationSchema={formValidatorSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
                /* and other goodies */
              }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <UiKit.Input
                      label="Current Password"
                      type="password"
                      name="currentPassword"
                      placeholder="Enter current password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentPassword}
                      error={touched.currentPassword && errors.currentPassword}
                    />
                    <UiKit.Input
                      className="mt-4"
                      label="New Password"
                      type="password"
                      name="newPassword"
                      placeholder="Enter new password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                      error={touched.newPassword && errors.newPassword}
                    />
                    <PasswordStrength
                      password={values.newPassword}
                      onChange={setIsPasswordStrong}
                    />
                    <UiKit.Input
                      className="mt-4"
                      label="Confirm Password"
                      type="password"
                      name="confirmNewPassword"
                      placeholder="Re-enter new password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmNewPassword}
                      error={
                        touched.confirmNewPassword && errors.confirmNewPassword
                      }
                    />
                  </Form>

                  <UiKit.Button
                    disabled={isSuccess || loading || !isPasswordStrong}
                    onClick={triggerSubmit}
                    wrapperClassName="w-100 mt-4"
                    style={{ width: '100%' }}
                  >
                    Continue
                  </UiKit.Button>
                </>
              )}
            </Formik>
          </div>

          <If test={isSuccess}>
            <div className="mt-3 text-center">
              <strong className="text-success">
                Password changed successfully! You will be redirected shortly.
              </strong>
            </div>
          </If>
        </div>
      </div>
      <ToastContainer className="app-toast-container" />
    </>
  );
};

export default ForceChangePassword;
