export default function isValidUrl(string) {
  let url = null;

  try {
    url = new URL(string);
  } catch (_) {}

  const isValidProtocol = !!url && ['http:', 'https:'].includes(url.protocol);

  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return isValidProtocol && !!pattern.test(string);
}
