import React, { useState, useContext, useRef, useEffect } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { createRetailerNote } from 'utils/services';
import { UiKit } from 'components';
import { NotesContext } from '../../context';
import { RetexContext } from 'components/RetexDetails';
import { ReactComponent as CrossIcon } from 'assets/img/icons/cross-danger.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/check-success.svg';

export default function Form({ onCancel = () => {} }) {
  const [value, setValue] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const { notes, onCreate } = useContext(NotesContext);
  const { retex } = useContext(RetexContext);
  const inputRef = useRef(null);

  const retexId = _get(retex, 'id', '');
  const showCancelButton = !_isEmpty(notes);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onCreateNote = () => {
    if (!value || !retexId) return;

    setIsProcessing(true);
    createRetailerNote(
      { note: value, routeParam: retexId },
      newNote => {
        setValue('');
        onCreate(newNote);
      },
      () => {},
      () => setIsProcessing(false)
    );
  };

  return (
    <UiKit.GridRow templateColumns="1fr" gap="15" className={styles.form}>
      <UiKit.Textarea
        ref={inputRef}
        rows={3}
        value={value}
        onChange={setValue}
        disabled={isProcessing}
        placeholder="add your note here..."
      />
      <div className={styles.action}>
        {showCancelButton && (
          <button className={styles.cancelBtn} onClick={onCancel}>
            <CrossIcon />
          </button>
        )}
        <button
          onClick={onCreateNote}
          className={styles.createBtn}
          disabled={!value || isProcessing}
        >
          <CheckIcon />
        </button>
      </div>
    </UiKit.GridRow>
  );
}
