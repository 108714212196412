import React, { useRef } from 'react';
import { Formik } from 'formik';
import _get from 'lodash/get';

import { useMutation } from 'utils/hooks';
import { Loading, PageErrorUi } from 'components';
import Form from './Form';
import { useProductsCatalog } from '../../hooks';
import { sanitizePayload, createFormData } from '../../methods';

export default function ProductsCatalogConfig() {
  const {
    entity,
    isError,
    isLoading,
    formattedData,
    onUpdate,
    onReload
  } = useProductsCatalog();

  const formRef = useRef(null);

  const resetForm = _get(formRef.current, 'resetForm', () => {});
  const setErrors = _get(formRef.current, 'setErrors', () => {});
  const setSubmitting = _get(formRef.current, 'setSubmitting', () => {});

  const { mutate: onSave } = useMutation(
    `configure.${entity}.catalog.put`,
    'PUT',
    {
      onSuccess: data => {
        const formData = createFormData(data);
        onUpdate(formData);
        resetForm(formData);
      },
      onError: () => setErrors({ submit: 'Something Went wrong.' }),
      onFinally: () => setSubmitting(false)
    }
  );

  const onSubmit = values => onSave(sanitizePayload(values));

  return (
    <PageErrorUi
      isError={isError}
      onReload={onReload}
      msg="Unable to fetch the Catalog Settings data at the moment."
    >
      <Loading loading={isLoading}>
        <Formik
          ref={formRef}
          onSubmit={onSubmit}
          initialValues={formattedData}
          render={props => <Form {...props} />}
        />
      </Loading>
    </PageErrorUi>
  );
}
