import React, { useCallback } from 'react';
import { DropdownToggle, UncontrolledDropdown, DropdownMenu } from 'reactstrap';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import { UiKit } from 'components';

export default function ProductCategoriesList({
  label = '',
  isMulti = true,
  isDisabled,
  productCategoriesOptions,
  selected,
  updateProductCategories,
  error
}) {
  const isLoading = !productCategoriesOptions;
  const options = productCategoriesOptions || [];
  const hasOptions = !_isEmpty(options);

  const onToggleProductCategory = useCallback(
    categoryId => {
      updateProductCategories(
        isMulti
          ? {
              ...selected,
              [categoryId]: !selected[categoryId]
            }
          : categoryId
      );
    },
    [selected, isMulti, updateProductCategories]
  );

  const flatProductCategoriesOption = flattenArray(productCategoriesOptions);
  const selectedOptions = flatProductCategoriesOption.filter(option =>
    isMulti ? selected[option.id] : selected === option.id
  );

  const selectLabel = label ? label : 'Select product categories';
  const placeholder = hasOptions
    ? 'Select the product categories'
    : 'No product categories found!';

  return (
    <div className="requestReasons-form__productTypes">
      <UncontrolledDropdown disabled={isDisabled}>
        <DropdownToggle tag="div" className="cursor-pointer">
          <UiKit.Select
            isMulti={isMulti}
            label={selectLabel}
            value={selectedOptions}
            onChange={() => {}}
            isLoading={isLoading}
            isDisabled={isDisabled}
            placeholder={placeholder}
            selectAllLabel="All product categories"
            error={error}
          />
        </DropdownToggle>
        <DropdownMenu
          style={{ width: '100%', height: '250px', overflowY: 'auto' }}
        >
          <UiKit.NestedCheckboxes
            isMulti={isMulti}
            options={productCategoriesOptions}
            valuesMap={selected}
            onToggle={onToggleProductCategory}
            isDisabled={isDisabled}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

function flattenArray(arr) {
  let flatArray = [];

  const cloned = _cloneDeep(arr);
  cloned.forEach(item => {
    // Create a shallow copy without the 'children' property
    const { options, ...rest } = item;

    // Add the current object to the flat array
    flatArray.push(rest);

    // Recursively flatten the children and add them to the flat array
    if (options && options.length > 0) {
      flatArray = flatArray.concat(flattenArray(options));
    }
  });

  return flatArray;
}
