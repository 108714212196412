import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Connect } from './components';
import { AuthContext } from 'context/AuthContext';
import UnauthenticatedLayout from 'containers/UnauthenticatedLayout';

export default function ShipStationConnect() {
  const { isLoggedIn, appType, logout } = useContext(AuthContext);
  const isRetexApp = appType.retex;

  useEffect(() => {
    if (isRetexApp && isLoggedIn) {
      logout();
    }
  }, [isLoggedIn, isRetexApp, logout]);

  if (!isRetexApp) {
    return <Redirect to="/" />;
  }

  return (
    <UnauthenticatedLayout>
      <Switch>
        <Route path="/ss-connect/:sellerId" component={Connect} />
        <Redirect to="/" />
      </Switch>
    </UnauthenticatedLayout>
  );
}
