export const PLAN_SLUGS = {
  FREE: 'free',
  FREE_UNLIMITED: 'free_unlimited', // 👈 Added new for Unlimited returns
  ESSENTIAL: 'essential',
  ESSENTIAL_PLUS: 'essential_plus',
  // ESSENTIAL: 'essentials_79',
  // ESSENTIAL_PLUS: 'essentials_125',
  STARTUP: 'startup',
  GROWTH: 'growth',
  MARKET_LEADER: 'market_leader',
  ENTERPRISE: 'enterprise'
};

export const HIGHEST_PLAN_SLUG = PLAN_SLUGS.ENTERPRISE;

export const PLAN_MODE = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
};
