import React from 'react';
import styles from './index.module.scss';

import { If } from 'utils';
import { ExpandableSection } from 'components';
import { Notes, CreateNote } from './components';
import { useNotes } from './hooks';
import { NotesContextProvider } from './context';

export default function InternalNotes() {
  const {
    notes,
    isError,
    isLoading,
    isDisabled,
    canCreate,
    canDelete,
    onCreate,
    onDelete
  } = useNotes();

  const badgeText = isLoading ? null : `${notes.length}`;

  return (
    <NotesContextProvider
      value={{ notes, canCreate, canDelete, onDelete, onCreate }}
    >
      <ExpandableSection
        title="Internal Notes"
        badgeText={badgeText}
        isError={isError}
        isDisabled={isDisabled}
        isLoading={isLoading}
        headerClassName={styles.header}
      >
        <If test={!isLoading}>
          <Notes />
          <CreateNote />
        </If>
      </ExpandableSection>
    </NotesContextProvider>
  );
}
