import React, { useCallback, useRef, useState } from 'react';
import { UncontrolledAlert } from 'reactstrap';
import { Formik } from 'formik';
import qs from 'qs';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';

import { isValidEmail } from 'utils/validators';
import { fetchRetailers } from 'utils/services';
import StepOneForm from './StepOneForm';
import { useLocation } from 'react-router-dom';

export default function FormWrapperOne({ proceed = () => {} }) {
  const { search } = useLocation();
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
  const email = _get(queryParams, 'email', '');

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const initialFormData = useRef({ email: email || '' }).current;

  const handleSubmit = useCallback(
    values => {
      setIsLoading(true);
      const email = _toLower(_get(values, 'email', ''));
      fetchRetailers(
        { email },
        payload => proceed({ ...payload, email }),
        () => {
          setIsError(true);
          setErrorMessage(
            'Unable to fetch the user information at the moment.'
          );
        },
        () => setIsLoading(false)
      );
    },
    [proceed]
  );

  const validate = useCallback(values => {
    let errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  }, []);

  return (
    <div>
      <UncontrolledAlert
        className="text-left"
        color="danger"
        isOpen={isError}
        toggle={() => setIsError(false)}
      >
        <strong>Error!</strong> {errorMessage}
      </UncontrolledAlert>
      <Formik
        validate={validate}
        onSubmit={handleSubmit}
        validateOnChange={false}
        initialValues={initialFormData}
        handleChange={() => setIsError(false)}
        render={props => <StepOneForm {...props} loading={isLoading} />}
      />
    </div>
  );
}
