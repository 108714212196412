import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default function Card({ className = '', children = null, ...divProps }) {
  return (
    <div className={cn(styles.container, className)} {...divProps}>
      {children}
    </div>
  );
}
