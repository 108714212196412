import { MODES } from '../stubs';

export function getConfirmationModalButtonText(
  hasReasons = false,
  mode = MODES.CREATE
) {
  if (hasReasons) {
    return mode === MODES.CREATE
      ? 'Confirm and Create'
      : 'Confirm and Save Changes';
  }
  return mode === MODES.CREATE ? 'Create' : 'Save Changes';
}

export function getConfirmationModalButtonProcessingText(mode = MODES.CREATE) {
  return mode === MODES.CREATE ? 'Creating...' : 'Saving...';
}
