import React from 'react';

export default function Helptext() {
  return (
    <div className="helptext">
      <p>Please select the type of discount you're offering.</p>
      <p>
        For <strong>fixed discount</strong>, the amount set will be the maximum
        waiver amount that can be offered as incentive based on the difference
        between order item and exchange item.
      </p>
      <p>
        For <strong>percentage discount</strong>, please enter the percentage
        and a maximum incentive amount.
      </p>
    </div>
  );
}
