import React from 'react';
import _get from 'lodash/get';

import { UiKit } from 'components';

export default function Form({
  errors = {},
  values = {},
  isProcessing = false,
  handleBlur = () => {},
  handleChange = () => {},
  handleSubmit = () => {}
}) {
  const storeUrl = _get(values, 'storeUrl', '');
  const error = _get(errors, 'storeUrl', '');

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <UiKit.Label text="Bigcommerce Store URL" />
        <UiKit.GridRow
          templateColumns="1fr auto"
          gap="4"
          className="align-items-center"
        >
          <UiKit.Input
            autoFocus
            name="storeUrl"
            value={storeUrl}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Enter your store URL"
          />
        </UiKit.GridRow>
      </div>
      {error && <div id="feedback">{error}</div>}
      <UiKit.Button
        type="submit"
        disabled={!storeUrl}
        processing={isProcessing}
        processingText="Connecting..."
        wrapperClassName="mt-4"
        className="w-100"
      >
        Install ReturnRabbit App
      </UiKit.Button>
    </form>
  );
}
