import _get from 'lodash/get';
import _set from 'lodash/set';
import _trim from 'lodash/trim';

import { INCENTIVE_TYPE } from 'dictionary';

export function validate(data) {
  const errors = {};

  const type = _trim(_get(data, 'discount_type', ''));
  const value = _trim(_get(data, 'incentive_amount', ''));
  const threshold = _trim(_get(data, 'ceiling_incentive_amount', ''));

  if (!value) {
    _set(errors, 'value', 'Value is required');
  } else if (+value < 0) {
    _set(errors, 'value', 'Value must be greater than or equal to 0');
  } else if (type === INCENTIVE_TYPE.PERCENT && +value === 0) {
    _set(errors, 'value', 'Percentage must be greater than 0');
  } else if (type === INCENTIVE_TYPE.PERCENT && +value > 100) {
    _set(errors, 'value', 'Percentage should be less than 100');
  }
  if (!threshold) {
    _set(errors, 'threshold', 'Please enter a cap amount');
  } else if (+threshold < 0) {
    _set(errors, 'threshold', 'Please enter a valid cap amount');
  } else if (type === INCENTIVE_TYPE.PERCENT && +threshold === 0) {
    _set(errors, 'threshold', 'Cap amount must be greater than 0');
  }

  return errors;
}
