import { useRef } from 'react';

import { useAccess } from 'utils/hooks';

export default function useRulesAccess() {
  const { hasSubFeatureAccess } = useAccess();

  const hasProAccess = hasSubFeatureAccess('EXCHANGE_RECOMMENDATIONS_PRO');
  const hasBasicAccess = hasSubFeatureAccess('EXCHANGE_RECOMMENDATIONS_BASIC');

  const rulesAccessMap = useRef({
    // Exchange Rules
    BS_LR_RECOMMENDATION: hasBasicAccess,
    BS_RA_RECOMMENDATION: hasBasicAccess,
    BS_AS_RECOMMENDATION: hasBasicAccess,
    BS_SLC_RECOMMENDATION: hasBasicAccess,
    BS_SMPT_RECOMMENDATION: hasBasicAccess,
    BS_SLPT_RECOMMENDATION: hasBasicAccess,
    AI_RECOMMENDATION: hasProAccess,
    MAN_RECOMMENDATION: hasProAccess,
    MAN_SP_RECOMMENDATION: hasProAccess,
    BS_SLPC_RECOMMENDATION: hasBasicAccess,
    BS_SMPC_RECOMMENDATION: hasBasicAccess,

    // Purchase Rules
    PURCHASE_BS_LR_RECOMMENDATION: hasBasicAccess,
    PURCHASE_BS_RA_RECOMMENDATION: hasBasicAccess,
    PURCHASE_BS_AS_RECOMMENDATION: hasBasicAccess,
    PURCHASE_BS_SLC_RECOMMENDATION: hasBasicAccess,
    PURCHASE_BS_SLPT_RECOMMENDATION: hasBasicAccess,
    PURCHASE_BS_SLPC_RECOMMENDATION: hasBasicAccess
  }).current;

  return rulesAccessMap;
}
