import React, { useState, useMemo, useEffect } from 'react';
import _get from 'lodash/get';

import { If } from 'utils';
import { UiKit } from 'components';
import RuleCard from './RuleCard';
import AddRuleCard from './AddRuleCard';
import RuleConfig from './RuleConfig';
import { useRecommendationConfig } from '../../hooks';
import { createCustomRuleTitle, isValidRuleConfig } from '../../methods';

export default function RecommendationCustomRules() {
  const {
    data,
    errors,
    customRuleData,
    isCustomRuleModalOpen,
    addRule,
    updateRule,
    onCloseCustomRuleModal,
    onPostCloseCustomRuleModal
  } = useRecommendationConfig();

  const [product, setProduct] = useState(null);
  const [isProductConfirmed, setProductConfirmed] = useState(false);

  const rule = _get(data, 'rule', []);
  const rules = _get(data, 'rules', []);
  const errorMsg = _get(errors, 'rules.msg', '');
  const isCustomRule = _get(rule, 'is_custom', false);

  const index = _get(customRuleData, 'index', null);
  const configData = _get(customRuleData, 'config', {});
  const customRule = _get(customRuleData, 'ruleOption', null);
  const selectedProduct = _get(customRuleData, 'product', null);
  const configElements = _get(customRule, 'config', []);
  const isProductRequired = _get(customRule, 'is_product_required', false);
  const modalTitle = createCustomRuleTitle(index + 1);
  const isProductSelected = product && isProductConfirmed;

  useEffect(() => {
    if (isCustomRuleModalOpen) {
      setProduct(selectedProduct);
      setProductConfirmed(!!selectedProduct);
    }
  }, [isCustomRuleModalOpen, selectedProduct]);

  const cards = useMemo(() => {
    return rules.map(({ key }, index) => <RuleCard key={key} index={index} />);
  }, [rules]);

  const onConfirmRule = () => {
    const ruleIndex = _get(customRuleData, 'index', null);
    const isEditing = ruleIndex < rules.length;
    const func = isEditing ? updateRule : addRule;
    func({ product: isProductRequired ? product : null });
  };

  const onModalClosed = () => {
    setProduct(null);
    setProductConfirmed(false);
    onPostCloseCustomRuleModal();
  };

  const isValidConfig = isValidRuleConfig(
    configData,
    configElements,
    isProductSelected,
    isProductRequired
  );

  return (
    <If test={isCustomRule}>
      <div className="mb-4 pb-2">
        <UiKit.GridRow templateColumns="1fr 1fr 1fr" gap="24" className="mb-2">
          {cards}
          <AddRuleCard error={!!errorMsg} />
        </UiKit.GridRow>
        <UiKit.ErrorMessage msg={errorMsg} />
      </div>
      <UiKit.SideSheet
        title={modalTitle}
        isOpen={isCustomRuleModalOpen}
        onConfirm={isValidConfig ? onConfirmRule : undefined}
        onClose={onCloseCustomRuleModal}
        onClosed={onModalClosed}
        isConfirmDisabled={!isValidConfig}
      >
        <RuleConfig
          product={product}
          isProductConfirmed={isProductConfirmed}
          setProduct={setProduct}
          setProductConfirmed={setProductConfirmed}
        />
      </UiKit.SideSheet>
    </If>
  );
}
