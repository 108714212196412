export default function findDomain(domains = []) {
  const filteredDomains = domains.filter(domain => {
    return domain.indexOf('aspen') < 0; //exclude domains which has aspen
  });

  let domain = filteredDomains[0] || domains[0] || '';
  if (domain.indexOf('https:') < 0) {
    domain = 'https://' + domain;
  }
  return domain;
}
