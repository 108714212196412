//Add here more currencies
const currency = {
  USD: '$',
  EUR: '€',
  CAD: 'C$'
};

const currencySymbol = val => currency[val] || val;

export default currencySymbol;
