import ADMIN_ROUTES from 'config/nav/admin/routes';

const {
  ROOT: HOME,
  MANAGE_SUBSCRIPTION,
  USER_MANAGEMENT,
  ACCOUNT,
  MANAGE_APIS
} = ADMIN_ROUTES.SETTINGS;

export default {
  HOME,

  MANAGE_SUBSCRIPTION,
  BILLING_PLAN: `${MANAGE_SUBSCRIPTION}/billing-plan`,
  BILLING_HISTORY: `${MANAGE_SUBSCRIPTION}/billing-history`,

  USER_MANAGEMENT,

  MANAGE_APIS,
  ACCOUNT
};
