import React, { useContext, useRef } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { findAllValues } from 'utils/DICTIONARY';
import { OS_STATUS } from 'dictionary';
import { getPaymentType } from 'utils/methods';
import { formatPrice } from 'utils/formatters';
import ResendOsEmail, { RESEND_EMAIL_TYPES } from 'components/ResendOsEmail';
import ExternalLink from '../ExternalLink';
import { OrderServiceContext } from 'components/OrderServiceDetails';

export default function PaymentInfo() {
  const { orderService } = useContext(OrderServiceContext);
  const paymentMap = useRef(findAllValues('refundPaymentChoices')).current;

  const osId = _get(orderService, 'id', '');
  const status = _get(orderService, 'status', '');
  const paymentInfo = _get(orderService, 'payment_info', {});
  const shippingAddress = _get(orderService, 'shipping_address', '');

  const amount = _get(paymentInfo, 'amount', 0);
  const currency = _get(paymentInfo, 'currency', '');
  const paymentMethod = _get(paymentInfo, 'method', '');
  const paymentLink = _get(paymentInfo, 'order_payment_link', '');
  const draftOrderLink = _get(paymentInfo, 'draft_order_link', '');
  const customerEmail = _get(shippingAddress, 'email', '');

  const paymentType = getPaymentType(amount);
  const formattedPrice = formatPrice(amount, currency);
  const fomattedPaymentType = _get(paymentMap, paymentMethod);
  const isExpectingPayment = status === OS_STATUS.PENDING_PAYMENT_COMPLETION;

  const ifPaymentTypeUpsell = paymentType === 'Upsell';

  return (
    <>
      <div className={styles.row}>
        <span className={styles.label}>Final Amount</span>
        <span className={styles.value}>{formattedPrice}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Payment Type</span>
        <span className={styles.value}>{paymentType}</span>
      </div>
      <If test={!ifPaymentTypeUpsell}>
        <div className={styles.row}>
          <span className={styles.label}>Payment Method</span>
          <span className={styles.value}>{fomattedPaymentType}</span>
        </div>
      </If>
      <div className={styles.row}>
        <span className={styles.label}>Payment Link</span>
        <ExternalLink
          allowCopy
          href={isExpectingPayment && paymentLink}
          text={paymentLink}
          fallback={<span className={styles.value}>N/A</span>}
        />
      </div>
      <div className={styles.actions}>
        {draftOrderLink && (
          <a
            href={draftOrderLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.action}
          >
            Draft Order
          </a>
        )}
        {isExpectingPayment && (
          <ResendOsEmail
            osId={osId}
            customerEmail={customerEmail}
            type={RESEND_EMAIL_TYPES.PAYMENT_REQUIRED}
            buttonClassName={styles.action}
          />
        )}
      </div>
    </>
  );
}
