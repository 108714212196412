import { useCallback, useContext, useMemo } from 'react';
import _get from 'lodash/get';

import { AuthContext } from 'context/AuthContext';
import { findEcommPlatform } from 'utils/DICTIONARY';

/**
 * @typedef {Object} EcommPlatformInfo E-commerce platfrom information of the loggein user's store
 * @property {import('utils/types').EcommPlatformSource} ecommPlatformSource E-commerce platform source
 * @property {string} ecommPlatformName E-commerce platform name in human readable format
 * @property {string} ecommPlatformPlanName E-commerce platform plan name
 * @property {(platform: import('utils/types').EcommPlatformSource) => boolean} checkIfPlatformIs Check if the e-commerce platform is the provided platform
 */

/**
 * @returns {EcommPlatformInfo} ecommPlatform information
 */
export default function useEcomPlatform() {
  const { storeProperties } = useContext(AuthContext);

  const ecommPlatform = _get(storeProperties, 'ecommerce_platform', null);
  const ecommPlatformSource = _get(ecommPlatform, 'source', '');
  const ecommPlatformPlanName = _get(ecommPlatform, 'plan_name', '');

  const ecommPlatformName = useMemo(() => {
    if (!ecommPlatformSource) return '';
    return findEcommPlatform(ecommPlatformSource);
  }, [ecommPlatformSource]);

  const checkIfPlatformIs = useCallback(
    platform => ecommPlatformSource === platform,
    [ecommPlatformSource]
  );

  return {
    ecommPlatformName,
    ecommPlatformSource,
    ecommPlatformPlanName,
    checkIfPlatformIs
  };
}
