import React, { useState } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _size from 'lodash/size';
import styles from './index.module.scss';

import { If } from 'utils';
import Card from '../Card';
import { useRecommendationConfig } from '../../../hooks';
import { ReactComponent as AddIcon } from 'assets/img/icons/add.svg';

export default function AddRuleCard() {
  const { data, errors, isProcessing, createRule } = useRecommendationConfig();

  const errorMsg = _get(errors, 'rules.msg', '');
  const [isPressedDown, setIsPressedDown] = useState(false);

  const rules = _get(data, 'rules', []);

  const mouseHandler = (val = false) => {
    if (isProcessing) return;
    setIsPressedDown(val);
  };

  return (
    <If test={_size(rules) < 5}>
      <Card
        onClick={createRule}
        onMouseDown={() => mouseHandler(true)}
        onMouseUp={() => mouseHandler(false)}
        onMouseLeave={() => mouseHandler(false)}
        className={cn(styles.container, {
          [styles.invalid]: !!errorMsg,
          [styles.pressed]: isPressedDown,
          [styles.disabled]: isProcessing
        })}
      >
        <AddIcon />
      </Card>
    </If>
  );
}
