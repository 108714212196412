import React, { useEffect } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { useAccess, useFeatures } from 'utils/hooks';
import { Toggle, UiKit } from 'components';
import Loader from './Loader';

/**
 * @param {Object} props
 * @param {string} props.featureType - Feature type
 * @param {import("dictionary").FEATURE_SLUGS} props.featureSlug - Feature slug
 * @param {boolean} props.defaultIsEnabled - Default enabled state
 * @param {Function} props.isActiveCallback - Callback function to get the active status
 */
export default function FeatureFlagToggle({
  featureType = '',
  featureSlug = '',
  defaultIsEnabled = false,
  isActiveCallback = () => {}
}) {
  const {
    features,
    isLoading,
    isError,
    isModalOpen,
    isProcessing,
    modalData,
    onModalOpen,
    onModalClose,
    onModalClosed,
    toggleFeature
  } = useFeatures({ includeFeatures: [featureType], defaultIsEnabled });
  const { syncSubscription, hasSubFeatureAccess } = useAccess();

  const hasAccess = hasSubFeatureAccess(featureSlug);
  const feature = _get(features, '[0]', null);
  const id = _get(feature, 'id', null);
  const title = _get(feature, 'title', '');

  const isEnabled = hasAccess && _get(feature, 'is_enabled', false);
  const isEnabling = !_get(modalData, 'is_enabled', false);

  useEffect(() => {
    syncSubscription();
  }, [syncSubscription]);

  useEffect(() => {
    isActiveCallback(isEnabled);
  }, [isEnabled, isActiveCallback]);

  if (!featureType || isError) return null;

  return (
    <Loader isLoading={isLoading}>
      <div
        className={cn(styles.container, { [styles.active]: isEnabled })}
        onClick={() => onModalOpen(id)}
      >
        <Toggle isActive={isEnabled} />
        <span className={styles.label}>Activate</span>
      </div>
      <UiKit.Modal
        title={title}
        isOpen={isModalOpen}
        isProcessing={isProcessing}
        onConfirm={toggleFeature}
        onClose={onModalClose}
        onModalClosed={onModalClosed}
        confirmBtnType={isEnabling ? 'success' : 'danger'}
        confirmBtnText={isEnabling ? 'Enable' : 'Disable'}
        confirmBtnProcessingText={isEnabling ? 'Enabling...' : 'Disabling...'}
      >
        <p>
          Are you sure you want to {isEnabling ? 'enable' : 'disable'} this
          feature?
        </p>
      </UiKit.Modal>
    </Loader>
  );
}
