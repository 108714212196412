import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { getOrderServiceTimeline } from 'utils/services';
import { PageErrorUi, Skeleton } from 'components';
import Card from '../Card';
import { OrderServiceContext } from 'components/OrderServiceDetails';
import {
  checkRetexes,
  formatDateTime
} from 'components/OrderServiceDetails/methods';

export default function Timeline() {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    orderService = {},
    retexes = [],
    onRetexClick = () => {}
  } = useContext(OrderServiceContext);

  const osId = _get(orderService, 'id', '');

  const loadData = useCallback(() => {
    if (!osId) return;
    setIsError(false);
    setIsLoading(true);
    getOrderServiceTimeline(
      { routeParams: { id: osId } },
      data => setData(data),
      () => setIsError(true),
      () => setIsLoading(false)
    );
  }, [osId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const retexNameIdMap = useMemo(() => {
    return retexes.reduce((acc, retex) => {
      const retexId = _get(retex, 'id', '');
      const name = _get(retex, 'name', '');
      acc[name] = retexId;
      return acc;
    }, {});
  }, [retexes]);

  const containerClassName = useMemo(() => {
    const { areAllApproved, isAnyRejected } = checkRetexes(retexes);
    return cn(styles.container, {
      [styles.success]: areAllApproved,
      [styles.danger]: isAnyRejected
    });
  }, [retexes]);

  const timelineRecords = useMemo(() => {
    return data.map((info, index) => {
      const title = _get(info, 'title', '');
      const type = _get(info, 'type', '');
      const recorderOn = _get(info, 'recorded_on', '');
      const recorderBy = _get(info, 'recorded_by', '');
      const entity = _get(info, 'entity', '');
      const retexId = _get(retexNameIdMap, entity, '');
      // const role = _get(info, 'role', '');

      const isInternalNote = type === 'INTERNAL_NOTE';
      const isCustomerNote = type === 'CUSTOMER_NOTE';
      const isNote = isInternalNote || isCustomerNote;

      const dated = formatDateTime(recorderOn);
      const message = !isNote ? (
        title
      ) : (
        <span>
          "{title}" - on{' '}
          <em onClick={() => onRetexClick(retexId, entity)}>{entity}</em>
        </span>
      );
      const helptext = isInternalNote
        ? `Internal note by ${recorderBy} at ${dated}`
        : isCustomerNote
        ? `Customer note by ${recorderBy} at ${dated}`
        : dated;
      return (
        <li key={index} className={styles.record}>
          <p>{message}</p>
          <span>{helptext}</span>
        </li>
      );
    });
  }, [data, retexNameIdMap, onRetexClick]);

  return (
    <Card className={containerClassName}>
      <h4 className={styles.header}>Request Timeline</h4>
      <PageErrorUi hideImage isError={isError} contentClassName={styles.error}>
        <ul className={styles.records}>
          {!isLoading ? (
            timelineRecords
          ) : (
            <li className={styles.record}>
              <div className="d-flex align-items-center">
                <Skeleton width="10px" height="10px" circle />
                <Skeleton width="180px" className="ml-2" />
              </div>
              <div className="d-flex align-items-center">
                <Skeleton width="10px" height="10px" opacity="0" />
                <Skeleton width="100px" className="ml-2" />
              </div>
            </li>
          )}
        </ul>
      </PageErrorUi>
    </Card>
  );
}
