import React from 'react';
import styles from './index.module.scss';

export default function Wave() {
  return (
    <svg
      className={styles.container}
      width="336"
      height="133"
      viewBox="0 0 336 133"
      fill="none"
    >
      <path
        d="M336.553 26.7561C262.131 53.444 248.917 58.5496 209.57 63.5667C133.052 73.3232 96.1174 9.82594 -0.274864 37.9758C-96.6671 66.1257 198.054 211.836 198.054 211.836L698.258 172.565L687.776 19.5433C611.197 29.3076 596.114 3.31798 536.748 1.82327C438.506 -0.650261 411.34 -0.0632133 336.553 26.7561Z"
        fill="url(#paint0_linear_75_11267)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_75_11267"
          x1="392.795"
          y1="-429.137"
          x2="454.136"
          y2="203.692"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.463542" stopColor="white" />
          <stop offset="0.739583" stopColor="white" stopOpacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  );
}
