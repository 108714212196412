import React from 'react';
import styles from './index.module.scss';
import { Skeleton, SkeletonTheme } from 'components';

export default function Loader({ isLoading = false, children = null }) {
  if (!isLoading) return children;

  const skeletonArray = Array.from({ length: 3 }, (_, i) => (
    <SkeletonTheme key={i} className="mb-0">
      <div className={styles.content}>
        <div className={styles.iconWrapper}>
          <Skeleton width="20px" height="20px" rounded />
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <Skeleton width="150px" height="16px" className="mb-2" />
          </div>
          <div className={styles.row}>
            <Skeleton width="60px" height="13px" />
            <Skeleton width="5px" height="5px" rounded />
            <Skeleton width="150px" height="13px" />
          </div>
        </div>
        <Skeleton width="90px" height="32px" />
      </div>
    </SkeletonTheme>
  ));

  return <div className={styles.container}>{skeletonArray}</div>;
}
