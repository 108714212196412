import React, { useEffect, useState } from 'react';

import { useMutation } from 'utils/hooks';
import { UiKit } from 'components';
import { useProductsCatalog } from '../../hooks';
import { isSyncInProcess, saveLastSyncTime } from '../../methods';

export default function ProductsCatalogSyncButton({
  text = 'Sync Catalog',
  processingText = 'Syncing your catalog...'
}) {
  const { entity, isLoading, isError } = useProductsCatalog();
  const [isSyncing, setIsSyncing] = useState(isSyncInProcess(entity));

  useEffect(() => {
    if (isSyncing) {
      const timer = setInterval(() => {
        setIsSyncing(isSyncInProcess(entity));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isSyncing, entity]);

  const { mutate: onSyncCatalog } = useMutation(
    `configure.${entity}.catalog.sync`,
    'POST',
    {
      errorMessage:
        'Unable to sync catalog at the moment. Please try again later.',
      successMessage:
        'Sync started successfully. Please wait while we update your catalog.',
      onValidate: () => {
        setIsSyncing(true);
        return true;
      },
      onSuccess: () => saveLastSyncTime(entity),
      onError: () => setIsSyncing(false)
    }
  );

  return (
    <UiKit.Button
      onClick={() => onSyncCatalog()}
      processing={isSyncing}
      disabled={isLoading || isError}
      processingText={processingText}
      customType={isSyncing ? 'outline' : 'primary'}
    >
      {text}
    </UiKit.Button>
  );
}
