import _get from 'lodash/get';

export default function getConflictingReasons(data = {}, refData = {}) {
  const reasons = _get(data, 'attachedReasons', []);
  const exisitingReasons = _get(refData, 'attachedReasons', []);
  const exisitingReasonsMap = exisitingReasons.reduce((acc, { id }) => {
    acc[id] = true;
    return acc;
  }, {});
  return reasons.filter(({ id, product_recommendation }) => {
    const hasRecommendation = !!product_recommendation;
    const hasReasonAttached = _get(exisitingReasonsMap, id, false);
    return hasRecommendation && !hasReasonAttached;
  });
}
