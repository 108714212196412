import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import routes from '../../config/routes/connect';
import UnauthenticatedLayout from 'containers/UnauthenticatedLayout';
import { AuthContext } from 'context/AuthContext';

export default function ConnectLayout(props) {
  const { appType } = useContext(AuthContext);

  if (!appType.retex) {
    return <Redirect to="/" />;
  }

  return (
    <UnauthenticatedLayout>
      <Switch>
        {routes.map((route, idx) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={routerProps => (
                <route.component {...routerProps} {...props} />
              )}
            />
          ) : null;
        })}
        <Redirect to="/" />
      </Switch>
    </UnauthenticatedLayout>
  );
}
