import {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import useFetch from './useFetch';
import useMutation from './useMutation';
import { AuthContext } from 'context/AuthContext';

export default function useFeatures({
  loadOnMount = true,
  includeFeatures = [],
  excludeFeatures = [],
  defaultIsEnabled = false
} = {}) {
  const { updateState } = useContext(AuthContext);

  const [features, setFeatures] = useState(() => {
    if (!_isArray(includeFeatures)) return [];
    return includeFeatures.map(feature => ({
      feature_type: feature,
      is_enabled: defaultIsEnabled
    }));
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalDataRef = useRef(null);

  const featureMap = useMemo(() => {
    if (!_isArray(features) && !_isEmpty(features)) return {};
    return features.reduce((acc, feature) => {
      acc[feature.id] = feature;
      return acc;
    }, {});
  }, [features]);

  const featureId = _get(modalDataRef.current, 'id');
  const featureTitle = _get(modalDataRef.current, 'title');
  const isEnabled = _get(featureMap, `${featureId}.is_enabled`);
  const newEnabledState = isEnabled ? 'disable' : 'enable';

  const { isError, isLoading, fetchData } = useFetch(
    'configure.featureFlags.get',
    {
      loadOnMount: loadOnMount,
      payload: () => {
        const payload = {};
        const include = _isArray(includeFeatures) && includeFeatures.toString();
        const exclude = _isArray(excludeFeatures) && excludeFeatures.toString();
        if (include) payload.filter = include;
        if (exclude) payload.exclude = exclude;
        return payload;
      },
      onSuccess: setFeatures
    }
  );

  useEffect(() => {
    if (_isArray(features) && !_isEmpty(features)) {
      const exchangeFeature = features.find(({ feature_type }) => {
        return feature_type === 'is_exchange_allowed';
      });
      if (exchangeFeature) {
        const isExchangeAllowed = _get(exchangeFeature, 'is_enabled', true);
        updateState({ isExchangeAllowed });
      }
    }
  }, [features, updateState]);

  const { mutate, isProcessing } = useMutation(
    'configure.featureFlags.patch',
    'PATCH',
    {
      routeParam: featureId,
      payload: { is_enabled: !isEnabled },
      successToastType: isEnabled ? 'warn' : 'success',
      successMessage: `Successfully ${newEnabledState}d the ${featureTitle} feature.`,
      errorMessage: `Unable to ${newEnabledState} the ${featureTitle} feature at the moment. Please try later!`,
      onValidate: () => !!featureId,
      onSuccess: () => {
        onModalClose();
        setFeatures(currFeatures => {
          const index = currFeatures.findIndex(({ id }) => id === featureId);
          if (index === -1) return currFeatures;
          const features = _cloneDeep(currFeatures);
          _set(features, [index, 'is_enabled'], !isEnabled);
          return features;
        });
        setTimeout(() => onModalClosed(), 300);
      },
      onError: err => console.log(err)
    }
  );

  const onModalOpen = useCallback(
    featureId => {
      const feature = _get(featureMap, featureId, null);
      modalDataRef.current = feature;
      setIsModalOpen(!!feature);
    },
    [featureMap]
  );

  const onModalClose = useCallback(() => setIsModalOpen(false), []);

  const onModalClosed = useCallback(() => (modalDataRef.current = null), []);

  return {
    features,
    isError,
    isLoading,
    onReload: () => fetchData(),

    // Confirm Modal
    isModalOpen,
    modalData: modalDataRef.current,
    isProcessing,
    onModalOpen,
    onModalClose,
    onModalClosed,
    toggleFeature: () => mutate()
  };
}
