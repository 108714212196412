import React from 'react';
import cn from 'classnames';
import { Modal as ReactstrapModal } from 'reactstrap';
import styles from './index.module.scss';

import { If } from 'utils';
import Button from '../Button';
// import { ReactComponent as CrossIcon } from 'assets/img/icons/close.svg';
import { ReactComponent as CrossIcon } from 'assets/img/icons/cross.svg';

export const DEFAULT_CONFIRM_FUNCTION = () => {};

export default function Modal({
  isOpen = false,
  title = '',
  subTitle = '',
  footerInfo = '',
  size = undefined, // "xs" | "md" | "lg" | "xl" | "2xl"
  centered = false,
  isProcessing = false,
  isCrossIconHidden = false,
  isCancelBtnHidden = false,
  isConfirmDisabled = false,
  isCustomBtnVisible = false,
  onEnter = () => {},
  onExit = () => {},
  onOpened = () => {},
  onClose = () => {},
  onClosed = () => {},
  onConfirm = DEFAULT_CONFIRM_FUNCTION,
  onCustomBtnClick = () => {},
  cancelBtnText = 'Cancel',
  confirmBtnText = 'Confirm',
  confirmBtnProcessingText = 'Saving',
  customBtnText = '',
  confirmBtnType = 'success', // Possible set of customTypes of Button component
  className = '',
  wrapClassName = '',
  modalClassName = '',
  backdropClassName = '',
  titleClassName = '',
  contentClassName = '',
  headerClassName = '',
  footerClassName = '',
  footerInfoClassName = '',
  cancelBtnClassName = '',
  confirmBtnClassName = '',
  confirmBtnWrapperClassName = '',
  customBtnClassName = '',
  zIndex = 10000,
  children = null,
  ...reactstrapModalProps
}) {
  const showFooter =
    onConfirm &&
    onConfirm !== DEFAULT_CONFIRM_FUNCTION &&
    onConfirm instanceof Function;

  const closeModalHandler = () => !isProcessing && onClose();
  return (
    <ReactstrapModal
      size={size}
      centered={centered}
      isOpen={isOpen}
      toggle={closeModalHandler}
      onEnter={onEnter}
      onOpened={onOpened}
      onClosed={onClosed}
      onExit={onExit}
      zIndex={zIndex}
      className={cn('AtlasGrotesk-Regular-Web', styles.modal, styles[size], {
        [className]: className
      })}
      wrapClassName={cn(styles.modalWrap, { [wrapClassName]: wrapClassName })}
      modalClassName={cn(styles.modalClass, {
        [modalClassName]: modalClassName
      })}
      backdropClassName={cn(styles.backdrop, {
        [backdropClassName]: backdropClassName
      })}
      contentClassName={cn(styles.content, {
        [contentClassName]: contentClassName
      })}
      {...reactstrapModalProps}
    >
      <div
        className={cn(styles.header, { [headerClassName]: headerClassName })}
      >
        <div className={styles.title}>
          <h1 className={titleClassName}>{title}</h1>
          <If test={!isCrossIconHidden}>
            <div className={styles.crossIcon}>
              <CrossIcon
                onClick={closeModalHandler}
                className={cn({ [styles.disabled]: isProcessing })}
              />
            </div>
          </If>
        </div>
        <If test={subTitle}>
          <div className={styles.subtitle}>{subTitle}</div>
        </If>
      </div>
      {children}
      <If test={showFooter}>
        <div className={cn(styles.footer, footerClassName)}>
          <div className={cn(styles.footerInfo, footerInfoClassName)}>
            {footerInfo}
          </div>
          <div className={styles.footerActions}>
            <If test={isCustomBtnVisible}>
              <Button
                customType="inline"
                onClick={onCustomBtnClick}
                processing={isProcessing}
                className={customBtnClassName}
              >
                {customBtnText}
              </Button>
            </If>
            <If test={!isCancelBtnHidden}>
              <Button
                customType="inline"
                onClick={closeModalHandler}
                processing={isProcessing}
                className={cancelBtnClassName}
              >
                {cancelBtnText}
              </Button>
            </If>
            <Button
              onClick={onConfirm}
              processing={isProcessing}
              customType={confirmBtnType}
              disabled={isConfirmDisabled}
              className={confirmBtnClassName}
              wrapperClassName={confirmBtnWrapperClassName}
              processingText={confirmBtnProcessingText}
            >
              {confirmBtnText}
            </Button>
          </div>
        </div>
      </If>
    </ReactstrapModal>
  );
}
