import _has from 'lodash/has';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { INVALID_VALUES } from '../stubs';
import getConflictingReasons from './getConflictingReasons';

export function validateField({ value = '', type = 'TEXT' }) {
  let error = '';
  switch (type) {
    case 'TEXT': {
      if (typeof value === 'string' && value.trim().length === 0) {
        error = 'Required';
      }
      break;
    }
    default:
      return null;
  }
  return error;
}

export function validateCustomRules(rules = []) {
  const error = {};
  if (rules.length === 0) {
    error.msg = '* Please add atleast one custom rule';
  } else {
    rules.forEach((rule, index) => {
      const productId = _get(
        rule,
        'product.objectID',
        _get(rule, 'product.ObjectId', null)
      );
      const ruleId = _get(rule, 'ruleOption.value', null);
      const isProductRequired = _get(
        rule,
        'ruleOption.is_product_required',
        false
      );

      let msg = '';
      if (!ruleId) {
        msg = 'Please select a rule';
      } else if (isProductRequired && !productId) {
        msg = 'Please select a product';
      }

      if (msg) {
        error[index] = { msg };
      }
    });
  }
  return error;
}

export function validate(data = {}) {
  const errors = {};
  const isCustomRule = _get(data, 'rule.is_custom', false);
  const ruleConfig = _get(data, 'rule.config', []);
  const checkConfig = _size(ruleConfig) > 0;

  console.log('data', data);

  const titleError = validateField({ value: _get(data, 'title', '') });
  if (titleError) {
    errors.title = titleError;
  }

  if (checkConfig) {
    const configData = _get(data, 'config', {});
    const configErrors = ruleConfig.reduce((acc, element) => {
      const id = _get(element, 'id', '');
      const type = _get(element, 'type', '');
      const value = _get(configData, id);
      const invalidValue = INVALID_VALUES[type];
      if (!_has(configData, id) || value === invalidValue) {
        acc[id] = 'Required';
      }
      return acc;
    }, {});
    if (!_isEmpty(configErrors)) {
      errors.config = configErrors;
    }
  }

  if (isCustomRule) {
    const rules = _get(data, 'rules', []);
    const rulesError = validateCustomRules(rules);
    if (!_isEmpty(rulesError)) {
      errors.rules = rulesError;
    }
  }

  return errors;
}

export function validateReasons(data = {}, refData = {}) {
  const invalidReasons = getConflictingReasons(data, refData);
  return !_isEmpty(invalidReasons);
}
