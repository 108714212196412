import React, { useCallback, useRef } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { InteractConfigWrapper, UiKit } from 'components';
import Helptext from './Helptext';
import { useBonusCashConfig } from '../../hooks';
import { getDiscountTypeOptions, getDiscountTypesMap } from '../../methods';

export default function IncentiveType() {
  const {
    data,
    errors,
    isProcessing,
    onChangeData,
    onUpdateErrors
  } = useBonusCashConfig();
  const discountTypeMap = useRef(getDiscountTypesMap()).current;
  const discountTypeOptions = useRef(getDiscountTypeOptions()).current;

  const currency = _get(data, 'currency', '$');
  const amount = _get(data, 'incentive_amount', '');
  const discountType = _get(data, 'discount_type', null);
  const threshold = _get(data, 'ceiling_incentive_amount', '');

  const valueError = _get(errors, 'value', '');
  const thresholdError = _get(errors, 'threshold', '');

  const isFixedType = discountType === 'fixed';
  const isPercentageType = discountType === 'percentage';
  const selectedDiscountTypeOption = _get(discountTypeMap, discountType, null);

  const onDiscountTypeChange = useCallback(
    option => {
      onChangeData({
        incentive_amount: 0,
        ceiling_incentive_amount: 0,
        discount_type: _get(option, 'value', null)
      });
      onUpdateErrors({ value: '', threshold: '' });
    },
    [onChangeData, onUpdateErrors]
  );

  const onAmountChange = useCallback(
    event => {
      const amount = _get(event, 'target.value', '');
      let regex = isFixedType ? /^\d*(\.)?(\d{0,2})?$/ : /^[0-9]*$/;
      const isValid = regex.test(amount);
      if (!isValid) return;
      onChangeData({ incentive_amount: amount });
      onUpdateErrors({ value: '' });
    },
    [isFixedType, onChangeData, onUpdateErrors]
  );

  const onThresholdChange = useCallback(
    event => {
      const threshold = _get(event, 'target.value', '');
      const isValid = /^\d*(\.)?(\d{0,2})?$/.test(threshold);
      if (!isValid) return;
      onChangeData({ ceiling_incentive_amount: threshold });
      onUpdateErrors({ threshold: '' });
    },
    [onChangeData, onUpdateErrors]
  );

  return (
    <InteractConfigWrapper
      helpComponent={Helptext}
      contentClassName="exchange-credits-edit-modal"
    >
      <UiKit.GridRow gap="10" templateColumns="1fr">
        <UiKit.GridRow gap="16" templateColumns="2fr 1fr">
          <UiKit.Select
            label={<b>Incentive Type</b>}
            value={selectedDiscountTypeOption}
            options={discountTypeOptions}
            isDisabled={isProcessing}
            onChange={onDiscountTypeChange}
          />
          <UiKit.Input
            min="0"
            type="number"
            label={<b>Value</b>}
            value={amount}
            onChange={onAmountChange}
            error={!!valueError}
            disabled={isProcessing}
            inputClassName="amount"
            inputWrapperClassName={cn(styles.valueInputWrapper, {
              [styles.fixed]: isFixedType,
              [styles.percent]: isPercentageType
            })}
          />
        </UiKit.GridRow>
        {isPercentageType && (
          <UiKit.GridRow
            gap="16"
            templateColumns="2fr 1fr"
            className="align-items-center"
          >
            <span className={styles.capLabel}>Enter the cap for incentive</span>
            <UiKit.Input
              step={1}
              value={threshold}
              onChange={onThresholdChange}
              currency={currency}
              error={!!thresholdError}
              disabled={isProcessing}
              placeholder="say, $4"
              inputWrapperClassName={cn(styles.valueInputWrapper, styles.fixed)}
            />
          </UiKit.GridRow>
        )}
        <If test={!!valueError || !!thresholdError}>
          <UiKit.GridRow templateColumns="1fr">
            <If test={!!valueError}>
              <div className={styles.error}>{valueError}</div>
            </If>
            <If test={!!thresholdError}>
              <div className={styles.error}>{thresholdError}</div>
            </If>
          </UiKit.GridRow>
        </If>
      </UiKit.GridRow>
    </InteractConfigWrapper>
  );
}
