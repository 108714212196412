import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { INVALID_VALUES } from '../stubs';

export default function isValidRuleConfig(
  configData = {},
  configElements = [],
  isProductSelected = false,
  isProductRequired = false
) {
  if (!_isArray(configElements)) return false;

  const isConfigDataValid = configElements.every(data => {
    const id = _get(data, 'id', '');
    const type = _get(data, 'type', '');
    const value = _get(configData, id);
    const invalidValue = _get(INVALID_VALUES, type);
    return value !== invalidValue;
  });

  const isValidProduct =
    !isProductRequired || (isProductRequired && isProductSelected);

  return isConfigDataValid && isValidProduct;
}
