import _get from 'lodash/get';

import { PLAN_MODE, PLAN_SLUGS } from 'dictionary';

export function configureRetexPlans({
  plans = [],
  isTrackingApp = false,
  subscribedPlanSlug = null,
  subscribedBillCycle = null,
  billCycle = PLAN_MODE.YEARLY
}) {
  if (isTrackingApp) return plans;

  const plansList = [...plans];
  const isSameBillCycle = subscribedBillCycle === billCycle;
  const isSubscribedToEnterprise = subscribedPlanSlug === PLAN_SLUGS.ENTERPRISE;
  const isSubscribedToFreePlan = subscribedPlanSlug === PLAN_SLUGS.FREE;
  const isSubscribedToEssential = isEssentialPlan(subscribedPlanSlug);

  const freeUnlimitedPlanIndex = plansList.findIndex(plan => {
    const slug = _get(plan, 'slug', '');
    return slug === PLAN_SLUGS.FREE_UNLIMITED;
  });
  if (freeUnlimitedPlanIndex > -1) {
    const unlimitedFreePlan = plansList.splice(freeUnlimitedPlanIndex, 1);
    plansList.unshift(unlimitedFreePlan[0]);
  }

  const skipPlans = [
    PLAN_SLUGS.FREE,
    PLAN_SLUGS.ESSENTIAL,
    PLAN_SLUGS.ESSENTIAL_PLUS,
    PLAN_SLUGS.ENTERPRISE
  ].filter(slug => {
    return isSameBillCycle ? slug !== subscribedPlanSlug : true;
  });

  if (isSameBillCycle) {
    if (isSubscribedToEnterprise) {
      skipPlans.push(PLAN_SLUGS.STARTUP);
    } else if (isSubscribedToEssential || isSubscribedToFreePlan) {
      skipPlans.push(PLAN_SLUGS.MARKET_LEADER);
    }
  }

  return plansList.reduce((acc, plan) => {
    const slug = _get(plan, 'slug', '');
    if (!skipPlans.includes(slug)) {
      acc.push(plan);
    }
    return acc;
  }, []);
}

export function getAllPlans(paidPlans = [], plans = [], isTrackingApp = false) {
  if (isTrackingApp) return plans;

  const enterprisePlan = plans.find(
    ({ slug }) => slug === PLAN_SLUGS.ENTERPRISE
  );
  return [...paidPlans, enterprisePlan];
}

export function getPlansOrderMap(plans = [], isTrackingApp = false) {
  const orderKey = isTrackingApp ? 'name' : 'slug';
  return plans.reduce((acc, plan, index) => {
    const key = _get(plan, orderKey, '');
    acc[key] = index;
    return acc;
  }, {});
}

export function isFreePlan(slug = '') {
  if (!slug) return false;
  return slug.includes(PLAN_SLUGS.FREE);
}

export function isEssentialPlan(slug = '') {
  if (!slug) return false;
  return slug.includes(PLAN_SLUGS.ESSENTIAL);
}
