import React, { createContext, useState } from 'react';
import _get from 'lodash/get';

import { useFetch } from 'utils/hooks';
import { createFormData } from '../../methods';
import { createNestedCheckboxSchema } from 'components/UiKit/NestedCheckboxes/util';

/**
 * @type {React.Context<{
 * entity: "exchanges" | "purchases",
 * isError: boolean,
 * isLoading: boolean,
 * formattedData: any,
 * categoriesOptions: any[],
 * onReload: () => void,
 * onUpdate: (data: any) => void,
 * }>}
 */
export const ProductsCatalogContext = createContext({
  entity: undefined
});

/**
 * @param {Object} props
 * @param {"exchanges" | "purchases"} props.entity
 * @param {React.ReactNode} props.children
 */
export function ProductsCatalogProvider({ entity, children }) {
  if (!entity) {
    throw new Error('Props "entity" is required for ProductsCatalogProvider');
  }
  if (entity !== 'exchanges' && entity !== 'purchases') {
    throw new Error(
      'Props "entity" must be either "exchanges" or "purchases" in ProductsCatalogProvider'
    );
  }

  const [data, setData] = useState(null);
  const [categoriesOptions, setCategoriesSchema] = useState([]);

  const { isLoading, isError, fetchData: onReload } = useFetch(
    `configure.${entity}.catalog.get`,
    {
      onSuccess: data => {
        const categories = _get(data, 'product_categories', []);
        setData(createFormData(data));
        setCategoriesSchema(createNestedCheckboxSchema(categories));
      }
    }
  );

  return (
    <ProductsCatalogContext.Provider
      value={{
        entity,
        isError,
        isLoading,
        formattedData: data,
        categoriesOptions,
        onReload,
        onUpdate: setData
      }}
    >
      {children}
    </ProductsCatalogContext.Provider>
  );
}
