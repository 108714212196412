import React, { useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _isString from 'lodash/isString';
import styles from './index.module.scss';

import { formatPrice } from 'utils/formatters';
import {
  OverflowText,
  PaintObject,
  ProductVariantAttributes
} from 'components';
import { If } from 'utils';

export default function Product({
  data = {},
  amount = null,
  currency = null,
  quantity = null,
  attributeShowCount = 2,
  containerClassName = '',
  imageClassName = '',
  contentClassName = '',
  children = null
}) {
  const imageUrl = _get(data, 'image_url', '');
  const title = _get(data, 'title', '').toLowerCase();
  const optionValues = _get(data, 'option_values', {});

  const metadata = useMemo(() => {
    const dataset = {};
    if (_isNumber(quantity)) {
      dataset['Quantity'] = quantity;
    }
    if (_isNumber(amount) && _isString(currency)) {
      dataset['Sub-total'] = formatPrice(amount, currency);
    }
    return dataset;
  }, [amount, currency, quantity]);

  const hasOptionValues = !_isEmpty(optionValues);
  const hasMetadata = !_isEmpty(metadata);

  return (
    <div className={cn(styles.container, 'light-text', containerClassName)}>
      <img
        src={imageUrl}
        alt="line-item"
        className={cn(styles.image, imageClassName)}
      />
      <div className={cn(styles.info, contentClassName)}>
        <div className={styles.titleContainer}>
          <OverflowText
            text={title}
            preventLineBreak
            className={styles.title}
            tooltipClassName={styles.tooltip}
          />
        </div>
        <If test={hasOptionValues || hasMetadata}>
          <div className={styles.row}>
            <If test={hasOptionValues}>
              <ProductVariantAttributes
                gap="0"
                optionValues={optionValues}
                showCount={attributeShowCount}
                render={(_, value) => value}
                separator={<>,&nbsp;</>}
              />
            </If>
            <If test={hasOptionValues && hasMetadata}>
              {PaintObject.SEPERATOR}
            </If>
            <If test={hasMetadata}>
              <PaintObject pattern="horizontal" data={metadata} />
            </If>
          </div>
        </If>
        {children}
      </div>
    </div>
  );
}
