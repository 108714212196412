import React from 'react';
import _get from 'lodash/get';

import { InteractConfigWrapper, UiKit } from 'components';
import { useRecommendationConfig } from '../hooks';

export default function RecommendationTitle() {
  const {
    data,
    errors,
    isProcessing,
    updateData,
    clearErrors
  } = useRecommendationConfig();

  const title = _get(data, 'title', '');
  const error = _get(errors, 'title', '');

  const onTitleChange = (_, title) => {
    updateData({ title });
    clearErrors('title');
  };

  return (
    <InteractConfigWrapper
      title="Recommendation Set Name"
      helptext="The Recommendation Set name is used throughout the merchant portal for identification purposes. This will not appear on the customer portal."
    >
      <UiKit.Input
        value={title}
        onChange={onTitleChange}
        label="Recommendation set name"
        placeholder="eg. Most Selling Products"
        disabled={isProcessing}
        error={error}
      />
    </InteractConfigWrapper>
  );
}
