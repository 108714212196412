import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _toLower from 'lodash/toLower';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

export function getOpenState(options = [], defaultOpen = true) {
  function createOpenStateMap(map = {}, options = []) {
    if (_isEmpty(options)) {
      return map;
    }

    return options.reduce((acc, option) => {
      const id = _get(option, 'id');
      const options = _get(option, 'options', []);

      if (!_isEmpty(options)) {
        acc[id] = defaultOpen;
      }

      return createOpenStateMap(acc, options);
    }, map);
  }

  return createOpenStateMap({}, options);
}

/**
 * Generates the NestedCheckbox component's "options" schema.
 */
export function createNestedCheckboxSchema(
  options = [],
  {
    idKey = 'id',
    labelKey = 'label',
    optionsKey = 'options',
    skipOption = () => false,
    checkIsDisabled = () => false,
    checkIsInterminate = () => false,
    optionDataGenerator = () => null
  } = {}
) {
  if (!_isArray(options)) return [];

  return options.reduce((acc, option) => {
    const skip = skipOption(option);
    if (skip) return acc;

    const id = _get(option, idKey);
    const label = _get(option, labelKey);
    const options = _get(option, optionsKey, []);

    acc.push({
      id,
      label,
      isDisabled: checkIsDisabled(option),
      indeterminate: checkIsInterminate(option),
      rawData: _omit(option, 'optionsKey'),
      query: createNestedCheckboxSearchQuery(option, { labelKey, optionsKey }),
      ...optionDataGenerator(option),
      options: _isEmpty(options)
        ? []
        : createNestedCheckboxSchema(options, {
            idKey,
            labelKey,
            optionsKey,
            skipOption,
            checkIsDisabled,
            checkIsInterminate,
            optionDataGenerator
          })
    });
    return acc;
  }, []);
}

/**
 * Generates the NestedCheckbox component's "valueMap" data.
 */
export function getNestedCheckboxValuesMap(
  options = [],
  { idKey = 'id', optionsKey = 'options', checkIfChecked = () => false } = {}
) {
  function createMap(
    map = {},
    options = [],
    { idKey = 'id', optionsKey = 'options', checkIfChecked = () => false } = {}
  ) {
    if (_isEmpty(options)) {
      return map;
    }

    return options.reduce((acc, option) => {
      const id = _get(option, idKey);
      const options = _get(option, optionsKey, []);
      acc[id] = checkIfChecked(option);
      return createMap(acc, options, { idKey, optionsKey, checkIfChecked });
    }, map);
  }

  return createMap({}, options, { idKey, optionsKey, checkIfChecked });
}

/**
 * Generates the search query for the nested tree
 */
export function createNestedCheckboxSearchQuery(
  option = {},
  { labelKey = 'label', optionsKey = 'options' } = {}
) {
  const generateQuery = (prefix = '', option) => {
    const label = _toLower(_get(option, labelKey, ''));
    const subOptions = _get(option, optionsKey, []);

    return subOptions.reduce(
      (acc, subOption) => generateQuery(acc, subOption),
      prefix ? `${prefix}+${label}` : label
    );
  };

  return generateQuery('', option);
}
