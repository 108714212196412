import React, { createContext, useContext } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { PageErrorUi, UiKit } from 'components';
import {
  LoaderSkeleton,
  Layout,
  Header,
  InfoCard,
  Timeline,
  Retexes,
  AmountBreakdown,
  Actions,
  LockedReason,
  ShopperGroupIncentives
} from './components';
import { useOsDetails } from './hooks';
import { AuthContext } from 'context/AuthContext';

export const OrderServiceContext = createContext({
  isLoading: true,
  orderService: {},
  retexes: [],
  osStatuses: {},
  retexStatuses: {},
  approvalTypes: {},
  terminalOsStatuses: [],
  rejectedRetexStatuses: [],
  approvedRetexStatuses: [],
  areAllRetexesSelected: true,
  isDisabled: false,
  processingId: null,
  showAction: false,
  onReSync: () => {},
  onLoadData: () => {},
  onRetexClick: () => {},
  onUpdateRetex: () => {},
  setIsDisabled: () => {},
  setProcessingId: () => {},
  onToggleAllRetexes: () => {}
});

const DEFAULT_ON_LOADED_CALLBACK = () => {};
const DEFAULT_ON_RETEX_CLICK_CALLBACK = () => {};

export default function OrderServiceDetails({
  osId = '',
  onLoaded = DEFAULT_ON_LOADED_CALLBACK,
  onRetexClick = DEFAULT_ON_RETEX_CLICK_CALLBACK,
  containerClassName = '',
  footerClassName = ''
}) {
  const { configuration } = useContext(AuthContext);
  const { isError = false, isLoading = false, ...contextData } = useOsDetails(
    osId,
    onLoaded
  );

  const isLocked = _get(contextData.orderService, 'on_hold', false);
  const lockReason = _get(contextData.orderService, 'on_hold_reason', '');
  const templateRows = isLocked ? 'auto auto 1fr' : 'auto 1fr';
  const hasShopperGroupsAccess = _get(
    configuration,
    'admin.submenu.configure.submenu.return_management.submenu.shopper_groups.enabled',
    false
  );

  return (
    <OrderServiceContext.Provider value={{ onRetexClick, ...contextData }}>
      <PageErrorUi isError={isError} onReload={contextData.onLoadData}>
        <LoaderSkeleton isLoading={isLoading}>
          <Layout className={containerClassName}>
            <UiKit.GridRow gap="20" templateColumns="1fr" className="pb-5 mb-4">
              <Header />
              <InfoCard />
              <If test={hasShopperGroupsAccess}>
                <ShopperGroupIncentives />
              </If>
              <Timeline />
            </UiKit.GridRow>
            <UiKit.GridRow
              gap="20"
              templateColumns="1fr"
              templateRows={templateRows}
              className={styles.actionsColumn}
            >
              <If test={contextData.showAction}>
                <LockedReason reason={isLocked ? lockReason : null} />
              </If>
              <Retexes />
              <AmountBreakdown />
            </UiKit.GridRow>
          </Layout>
          <Actions className={footerClassName} />
        </LoaderSkeleton>
      </PageErrorUi>
    </OrderServiceContext.Provider>
  );
}
