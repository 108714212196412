import _get from 'lodash/get';

import { ELEMENT_TYPES, DEFAULT_VALUES } from '../stubs';

export default function createRuleData(ruleConfigData = [], ruleConfig = []) {
  return ruleConfigData.reduce((acc, configData) => {
    const id = _get(configData, 'id', '');
    const type = _get(configData, 'type', '');
    const defaultValue = _get(DEFAULT_VALUES, type, null);
    let configValue;

    switch (type) {
      case ELEMENT_TYPES.SELECT:
        const optionId = _get(configData, 'value', null);
        const config = ruleConfig.find(item => id === item.id);
        const optionsList = _get(config, 'data', []);
        const option = optionsList.find(opt => optionId === opt.id);
        const value = _get(option, 'id', '');
        const label = _get(option, 'name', '');
        configValue = !!option ? { label, value } : defaultValue;
        break;
      case ELEMENT_TYPES.NESTED_SELECT:
      case ELEMENT_TYPES.BOOLEAN:
        configValue = _get(configData, 'value', defaultValue);
        break;
      default:
        configValue = undefined;
    }
    if (configValue !== undefined) {
      acc[id] = configValue;
    }

    return acc;
  }, {});
}
