import LogRocket from 'logrocket';
import localStore from 'utils/localStore';
import { preventErrorLog } from 'utils/methods';

export default function registerLogRocketEvent(
  evenName = '',
  eventProperties = {}
) {
  const user = JSON.parse(localStore.get('userData'));
  const logRocketApp = process.env.REACT_APP_LOG_ROCKET_APP_ID;

  if (!logRocketApp || !user || !evenName || preventErrorLog()) {
    return;
  }

  LogRocket.track(evenName, eventProperties);
}
