import React from 'react';
import { ModalFooter } from 'reactstrap';
import cn from 'classnames';

import { If } from 'utils';
import { UiKit } from 'components';

const EditFooter = ({
  text = '',
  className = '',
  processing = false,
  cancelText = 'Cancel',
  actionText = 'Save Changes',
  disabledCancel = false,
  disabledSubmit = false,
  toggle = () => {},
  confirm = () => {},
  children = null,
  removeParent = false
}) => {
  const customClassName = cn('border-0', { [className]: !!className });

  const elements = (
    <>
      <If test={children}>{children}</If>
      <If test={text}>
        <div>{text}</div>
      </If>
      <If test={!children && !text}>
        <If test={!disabledSubmit}>
          <UiKit.Button
            customType="inline"
            onClick={toggle}
            disabled={processing || disabledCancel}
            wrapperClassName="m-0"
          >
            {cancelText}
          </UiKit.Button>
        </If>
        <UiKit.Button
          customType="success"
          disabled={disabledSubmit}
          onClick={confirm}
          processing={processing}
          processingText="Processing"
          wrapperClassName={removeParent ? '' : 'm-0 ml-3'}
        >
          {actionText}
        </UiKit.Button>
      </If>
    </>
  );

  return (
    <>
      <If test={!removeParent}>
        <ModalFooter className={customClassName}>{elements}</ModalFooter>
      </If>

      <If test={removeParent}>{elements}</If>
    </>
  );
};

export default EditFooter;
