import _get from 'lodash/get';

import { isEcommPlatformSupported } from 'utils/methods';

/**
 * @typedef {Object} Store
 * @property {string} store_name
 * @property {string} store_url
 * @property {import("utils/types").EcommPlatformSource} ecommerce_platform
 * @property {boolean} is_installed
 */

/**
 * @param {Store[]} stores - Array of stores
 * @returns {{supportedStores: Store[], unSupportedStores: Store[]}}
 */
export default function sortStores(stores = []) {
  const { supported, unSupported } = stores.reduce(
    (acc, store) => {
      const isSupported = isEcommPlatformSupported(store.ecommerce_platform);
      const list = isSupported ? acc.supported : acc.unSupported;
      list.push(store);
      return acc;
    },
    { supported: [], unSupported: [] }
  );

  const supportedStores = supported.sort(a => (_get(a, 'store_url') ? -1 : 1));

  return {
    supportedStores: supportedStores,
    unSupportedStores: unSupported
  };
}
