import { MODES } from '../stubs';

export default function getConfirmationModalMessage(
  hasReasons = false,
  mode = MODES.CREATE
) {
  if (hasReasons) {
    return 'The Request Reason(s) listed below are part of an existing Recommendation Set. This action will override the existing set. Are you sure you want to continue?';
  }
  const helptext = mode === MODES.CREATE ? 'created' : 'updated';
  return `Looks good! The recommendation set can be ${helptext}.`;
}
