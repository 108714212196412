import _get from 'lodash/get';

const createOption = (rule = {}, accessMap = {}) => {
  const id = _get(rule, 'id', '');
  const slug = _get(rule, 'slug', '');
  const title = _get(rule, 'title', '');
  const hasAccess = _get(accessMap, slug, false);

  if (!id || !title) return null;

  return { value: id, label: title, isDisabled: !hasAccess, ...rule };
};

export default function createRulesOptions(rules = [], accessMap = {}) {
  if (!Array.isArray(rules)) return {};

  const { parentRules, childRules } = rules.reduce(
    (acc, rule) => {
      const ruleOption = createOption(rule, accessMap);
      const list = ruleOption.isDisabled ? 'disabled' : 'enabled';
      if (ruleOption) {
        const { is_child, is_parent } = rule;
        if (is_child) {
          acc.childRules[list].push(ruleOption);
        }
        if (is_parent) {
          acc.parentRules[list].push(ruleOption);
        }
      }
      return acc;
    },
    {
      parentRules: { enabled: [], disabled: [] },
      childRules: { enabled: [], disabled: [] }
    }
  );

  return {
    parentRules: [...parentRules.enabled, ...parentRules.disabled],
    childRules: [...childRules.enabled, ...childRules.disabled]
  };
}
