import React, { useState, useRef } from 'react';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import styles from './index.module.scss';

import { If, post } from 'utils';
import { UiKit } from 'components';
import { ReactTable, Pagination, Search } from 'components/ReactTable';
import Products from './Products';

export default function SelectProduct({
  show = false,
  product = null,
  setProduct = () => {},
  isProductConfirmed = false,
  setProductConfirmed = () => {}
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const filters = useRef({ page_size: 12 }).current;

  const isProductSelected = product && isProductConfirmed;
  const title = _capitalize(_get(product, 'title', ''));
  const imageUrl = _get(product, 'image_url', '');

  const onOpenModal = () => setIsModalOpen(true);
  const onCloseModal = () => setIsModalOpen(false);

  const onConfirmProduct = () => {
    setProductConfirmed(true);
    onCloseModal();
  };

  return (
    <If test={show}>
      <If test={!isProductSelected}>
        <UiKit.Button customType="adder" onClick={onOpenModal}>
          Select a product
        </UiKit.Button>
      </If>
      <If test={isProductSelected}>
        <div className={styles.product}>
          <img src={imageUrl} alt={title} />
          <div className={styles.info}>
            <span>{title}</span>
            <UiKit.Button customType="adder" onClick={onOpenModal}>
              Choose different product
            </UiKit.Button>
          </div>
        </div>
      </If>
      <UiKit.SideSheet
        title="Select a product"
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onConfirm={onConfirmProduct}
        isConfirmDisabled={!product}
        footerInfo={
          imageUrl && (
            <img src={imageUrl} alt={title} className={styles.thumbnail} />
          )
        }
      >
        <p className={styles.description}>
          Please select a product here that you want to show as a
          recommendation.
        </p>
        <ReactTable
          apiEndpoint="products.getAll"
          method={post}
          filters={filters}
        >
          <Search
            customClass={styles.search}
            placeholder="Type the product name"
          />
          <Products selected={product} onSelect={setProduct} />
          <Pagination />
        </ReactTable>
      </UiKit.SideSheet>
    </If>
  );
}
