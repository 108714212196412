import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { useFetch } from 'utils/hooks';

export default function useRetexes(osId = '') {
  const { data, isLoading } = useFetch('retexes.getList', {
    loadOnMount: !!osId,
    routeParams: { id: osId },
    payload: { fields: 'id,name,status' },
    onTransform: data => {
      const retexes = _isArray(data) ? data : [];
      const { retexIds, retexesDataMap } = retexes.reduce(
        (acc, retex) => {
          const id = _get(retex, 'id', '');
          const name = _get(retex, 'name', '');
          const status = _get(retex, 'status', '');
          if (id) {
            acc.retexIds.push(id);
            acc.retexesDataMap[id] = { id, name, status };
          }
          return acc;
        },
        { retexIds: [], retexesDataMap: {} }
      );
      return { retexIds, retexesDataMap };
    }
  });

  return {
    isFetchingRetexes: isLoading,
    retexIds: _get(data, 'retexIds', []),
    retexesDataMap: _get(data, 'retexesDataMap', {})
  };
}
