import { useEffect, useRef, useState } from 'react';

const DEFAULT_EVENTS = [
  'keypress',
  'mousemove',
  'touchmove',
  'click',
  'scroll'
];
const DEFAULT_OPTIONS = {
  events: DEFAULT_EVENTS,
  initialState: true
};

const DEFAULT_TIMEOUT = 300000;

export default function useIdle(timeout = DEFAULT_TIMEOUT, options) {
  const { events, initialState } = { ...DEFAULT_OPTIONS, ...options };
  const [idle, setIdle] = useState(initialState);
  const timer = useRef();

  useEffect(() => {
    const handleEvents = () => {
      setIdle(false);

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setIdle(true);
      }, timeout);
    };

    events.forEach(event => document.addEventListener(event, handleEvents));

    return () => {
      events.forEach(event =>
        document.removeEventListener(event, handleEvents)
      );
    };
  }, [timeout, events]);

  return idle;
}
