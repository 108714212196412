import React from 'react';
import _get from 'lodash/get';

import { InteractConfigWrapper, Toggle } from 'components';
import { useRecommendationConfig } from '../hooks';

export default function RecommendationActivationToggle() {
  const { data, isProcessing, updateData } = useRecommendationConfig();

  const isEnabled = _get(data, 'isEnabled', false);

  const onToggle = () => updateData({ isEnabled: !isEnabled });

  return (
    <InteractConfigWrapper
      configCard
      title="Activate Recommendation"
      helptext="Set the status for the recommendation. If enabled, the shoppers will start seeing the product recommendations."
    >
      <div className="d-inline-flex align-items-center cursor-pointer">
        <Toggle
          size="lg"
          isActive={isEnabled}
          disabled={isProcessing}
          onClick={onToggle}
        />
        <span onClick={onToggle} className="pl-3">
          Activate
        </span>
      </div>
    </InteractConfigWrapper>
  );
}
