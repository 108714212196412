import React from 'react';
import { Modal } from 'reactstrap';
class EditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: this.props.isEditModalOpen
    };

    this.toggle = this.props.toggle;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isEditModalOpen !== this.props.isEditModalOpen) {
      this.setState({
        isEditModalOpen: this.props.isEditModalOpen
      });
    }
  }

  render() {
    let { isEditModalOpen } = this.state;

    return (
      <Modal
        isOpen={isEditModalOpen}
        toggle={this.toggle}
        className="editModal"
        contentClassName={'editModal__content'}
        id={this.props.id}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default EditModal;
