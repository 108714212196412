import React, { useState, useContext, useEffect } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { getShopperComments } from 'utils/services';
import { UiKit, Skeleton } from 'components';
import { RetexContext } from 'components/RetexDetails';

export default function ShopperComment() {
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { retex } = useContext(RetexContext);

  const retexId = _get(retex, 'id', '');
  const entityType = _get(retex, 'entity_type', '');
  const isPurchase = entityType === 'PCH';

  useEffect(() => {
    if (!isPurchase) {
      setIsError(false);
      setIsLoading(true);
      getShopperComments(
        retexId,
        data => setComment(_get(data, '[0].note', 'N/A')),
        () => setIsError(true),
        () => setIsLoading(false)
      );
    }
  }, [retexId, isPurchase]);

  return (
    <If test={!isPurchase}>
      <UiKit.GridRow templateColumns="1fr" gap="2">
        <span className={styles.label}>Customer Comment</span>
        {!isError ? (
          isLoading ? (
            <Skeleton width="60%" height="16px" />
          ) : (
            <p className={styles.value}>{comment}</p>
          )
        ) : (
          <p className={styles.error}>
            <span role="img" aria-label="warn">
              ⚠️
            </span>{' '}
            Unable to load the customer comment information at the moment!
          </p>
        )}
      </UiKit.GridRow>
    </If>
  );
}
