import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import _get from 'lodash/get';

import { PageErrorUi, ShopperGroupSelector, FeatureUpsell } from 'components';
import Loader from './Loader';
import ToggleSwitch from './ToggleSwitch';
import IncentiveType from './IncentiveType';
import { useBonusCashConfig } from '../hooks';

export default function ConfigForm({ homeRoute = '', configRoute = '' }) {
  const { push } = useHistory();
  const {
    data,
    initialData,
    featureSlug,
    isError,
    errorMsg,
    isProcessing,
    onChangeData,
    onLoadData,
    onReset,
    onSave,
    onDelete
  } = useBonusCashConfig();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const shopperGroupId = _get(data, 'shopper_group_id', '');
  const isConfigurable = _get(data, 'shopper_groups_allowed', false);

  return (
    <PageErrorUi
      isError={isError}
      msg={errorMsg}
      contentClassName="p-0"
      onReload={errorMsg ? () => push(configRoute) : onLoadData}
      reloadButtonText={errorMsg ? 'Load Global Settings' : undefined}
    >
      <FeatureUpsell.Banner
        featureId="SHOPPER_GROUPS"
        buttonType="outline"
        className="mb-5"
      />
      <Loader>
        <ShopperGroupSelector.Provider
          entity={featureSlug}
          selectedId={shopperGroupId}
          isConfigurable={isConfigurable}
          onSubmit={onSave}
          onChange={onChangeData}
        >
          <ShopperGroupSelector />
          <ToggleSwitch />
          <IncentiveType />
          <ShopperGroupSelector.Actions
            homeRoute={homeRoute}
            formData={data}
            initialFormData={initialData}
            onResetForm={onReset}
            isProcessing={isProcessing}
            showDeleteModal={showDeleteModal}
            onDelete={onDelete}
            onOpenDeleteModal={() => setShowDeleteModal(true)}
            onCloseDeleteModal={() => setShowDeleteModal(false)}
          />
        </ShopperGroupSelector.Provider>
      </Loader>
    </PageErrorUi>
  );
}
