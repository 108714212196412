import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';
import ssXRrIcon from 'assets/img/brand/ss-x-rr.svg';
import trackingRabbitLogo from 'assets/img/brand/tracking-rabbit-logo.png';

export default function Logo({ className = '' }) {
  const { appType } = useContext(AuthContext);

  const logo = appType.shipmentTracking ? (
    <img src={trackingRabbitLogo} alt="logo" width="215" />
  ) : (
    <img src={ssXRrIcon} alt="logo" height="32" />
  );

  return <div className={cn(styles.container, className)}>{logo}</div>;
}
