import React, { useContext } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { ReactTablesContext } from 'components/ReactTable';
import { Skeleton, SkeletonTheme, UiKit } from 'components';

export default function Loader({ children = null }) {
  const { searchState } = useContext(ReactTablesContext);
  const isLoading = _get(searchState, 'isFetchingData', true);

  if (!isLoading) return children;

  const loadingFrames = Array.from({ length: 5 }, (_, index) => (
    <UiKit.GridRow key={index} templateColumns="1fr" gap="8">
      <Skeleton width="100%" height="130px" />
      <Skeleton width="100%" height="15px" />
    </UiKit.GridRow>
  ));

  return (
    <SkeletonTheme className="m-0">
      <UiKit.GridRow
        gap="28px 24px"
        templateColumns="1fr 1fr 1fr"
        className={styles.container}
      >
        {loadingFrames}
      </UiKit.GridRow>
    </SkeletonTheme>
  );
}
