import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import _upperFirst from 'lodash/upperFirst';
import styles from './index.module.scss';

import { If } from 'utils';
import { getEcommPlatformLabel, isEcommPlatformSupported } from 'utils/methods';
import { EcommPlatfrom, UiKit } from 'components';
import { ReactComponent as WarningIcon } from 'assets/img/icons/warning.svg';

export default function Store({
  data = {},
  isConnecting = false,
  connectingStore = null,
  onConnect = () => {}
}) {
  const { push } = useHistory();
  const uid = useRef(`store-${uuidv4()}`).current;

  const storeUrl = _get(data, 'store_url', '');
  const storeName = _get(data, 'store_name', '');
  const isInstalled = _get(data, 'is_installed', false);
  const ecommPlatform = _get(data, 'ecommerce_platform', '');

  const isProcessing = connectingStore && connectingStore === storeUrl;
  const isSupported = isEcommPlatformSupported(ecommPlatform);
  const isDisabled = !isSupported || !storeUrl || isConnecting;
  const isUnserviceable = isSupported && !storeUrl;
  const storeLabel = getEcommPlatformLabel(
    ecommPlatform,
    _upperFirst(_toLower(ecommPlatform))
  );

  const onConnectStore = () => {
    if (isDisabled) return;
    if (isInstalled) return push('/login');
    onConnect(data);
  };

  return (
    <div className={cn(styles.container, { [styles.disabled]: !isSupported })}>
      <If test={isSupported}>
        <div className={styles.iconWrapper}>
          <EcommPlatfrom.Icon source={ecommPlatform} className={styles.icon} />
        </div>
      </If>
      <div className={styles.info}>
        <div className={styles.row}>
          <p className={styles.storeName}>{storeName}</p>
          <If test={isInstalled}>
            <UiKit.Badge className={styles.badge}>Activated</UiKit.Badge>
          </If>
        </div>
        <div className={styles.row}>
          <p className={styles.ecommPlatform}>{storeLabel}</p>
          <If test={storeUrl}>
            <div className={styles.separator} />
            <p className={styles.storeUrl}>{storeUrl}</p>
          </If>
        </div>
      </div>
      <UiKit.Button
        id={uid}
        disabled={isDisabled}
        onClick={onConnectStore}
        processing={isProcessing}
        processingText={<i className="fas fa-spin fa-spinner" />}
        className={cn(styles.button, {
          [styles.unserviceable]: isUnserviceable
        })}
        wrapperClassName={styles.buttonWrapper}
      >
        <If test={isUnserviceable}>
          <WarningIcon className={styles.warningIcon} />
        </If>
        {isInstalled ? 'Login' : 'Activate'}
      </UiKit.Button>
      <If test={isUnserviceable}>
        <UncontrolledTooltip target={uid} placement="top">
          Store URL is missing. Please add your store URL to activate.
        </UncontrolledTooltip>
      </If>
    </div>
  );
}
