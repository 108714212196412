import React, { useContext, useRef } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { DICTIONARY, If } from 'utils';
import { UiKit } from 'components';
import Card from '../Card';
import { OrderServiceContext } from 'components/OrderServiceDetails';
import { Link } from 'react-router-dom';
import CONFIGURE_ROUTES from 'views/Configure/stubs/routes';

export default function ShopperGroupIncentives() {
  const { orderService } = useContext(OrderServiceContext);

  const shopperGroupIncentiveEntitiesMap = useRef(
    DICTIONARY('shopper_group_override_entities') || {}
  ).current;

  const shopperGroup = _get(orderService, 'shopper_group_name', '');
  const overrides = _get(orderService, 'shopper_group_overrides_applied', []);
  const hasIncentives = Array.isArray(overrides) && overrides.length;
  const tagClassName = cn('shopper-incentive-tags', styles.tag);

  const renderAppliedIncentives = !hasIncentives
    ? null
    : overrides.map((incentive, index) => (
        <div key={`${incentive}-${index}`} className={tagClassName}>
          {shopperGroupIncentiveEntitiesMap[incentive]}
        </div>
      ));

  return (
    <Card className={styles.container}>
      <UiKit.GridRow templateColumns="auto 1fr" gap={14}>
        <div className={styles.icon}>
          <span role="img" aria-label="info">
            💡
          </span>
        </div>
        <div className={styles.content}>
          <If test={!shopperGroup}>
            <div className={styles.row}>
              <div className={styles.label}>
                Shopper does not belongs to any group
              </div>
              <div className={styles.value}>
                Configure shopper groups easily and offer tailored incentives to
                enhance shoppers' experience and loyalty.{' '}
                <Link
                  to={CONFIGURE_ROUTES.SHOPPER_GROUPS}
                  className="text-underline"
                >
                  Start here
                </Link>
              </div>
            </div>
          </If>
          <If test={shopperGroup}>
            <div className={styles.row}>
              <div className={styles.label}>Shopper belongs to this group</div>
              <div className={styles.value}>{shopperGroup}</div>
            </div>
            <If test={hasIncentives}>
              <div className={styles.row}>
                <div className={styles.label}>Applied overrides</div>
                <div className={styles.value}>{renderAppliedIncentives}</div>
              </div>
            </If>
            <If test={!hasIncentives}>
              <div className={styles.row}>
                <div className={styles.label}>
                  No shopper group overrides applied
                </div>
              </div>
            </If>
          </If>
        </div>
      </UiKit.GridRow>
    </Card>
  );
}
