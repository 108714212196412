export const MODES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT'
};

/**
 * @type {Record<import("./types").RecommendationType, {
 * get: string,
 * put: string,
 * post?: string,
 * rules: { get: string },
 * reasons?: { get: string }
 * }}
 */
export const ENDPOINTS = {
  exchange: {
    get: 'configure.exchanges.recommendations.get',
    put: 'configure.exchanges.recommendations.put',
    post: 'configure.exchanges.recommendations.post',
    rules: {
      get: 'configure.exchanges.recommendations.rules.get'
    },
    reasons: {
      get: 'configure.exchanges.recommendations.reasons.get'
    }
  },
  purchase: {
    get: 'configure.purchases.recommendations.get',
    put: 'configure.purchases.recommendations.put',
    rules: {
      get: 'configure.purchases.recommendations.rules.get'
    }
  }
};

export const ELEMENT_TYPES = {
  SELECT: 'SELECT',
  BOOLEAN: 'BOOLEAN',
  NESTED_SELECT: 'NESTED_SELECT'
};

export const DEFAULT_VALUES = {
  [ELEMENT_TYPES.SELECT]: null,
  [ELEMENT_TYPES.BOOLEAN]: false,
  [ELEMENT_TYPES.NESTED_SELECT]: null
};

export const INVALID_VALUES = {
  [ELEMENT_TYPES.SELECT]: null,
  [ELEMENT_TYPES.NESTED_SELECT]: null
};
