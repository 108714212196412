import React, { useRef } from 'react';
import styles from './index.module.scss';

import { getCustomerPortalLink } from 'utils/methods';
import { ReactComponent as LinkIcon } from 'assets/img/sidebar/customer-portal.svg';

export default function CustomerPortalLink() {
  const customerPortalLink = useRef(getCustomerPortalLink()).current;

  if (!customerPortalLink) return null;

  return (
    <a
      href={customerPortalLink}
      target="_blank"
      rel="noopener noreferrer"
      data-shepherd="sidebar-customer-portal"
      className={styles.container}
    >
      Customer Portal
      <LinkIcon />
    </a>
  );
}
