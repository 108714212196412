import React, { useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { If } from 'utils';
import { UiKit } from 'components';
import {
  getConflictingReasons,
  getConfirmationModalMessage,
  getConfirmationModalButtonText,
  getConfirmationModalButtonProcessingText
} from '../../methods';
import { ReactComponent as WarningIcon } from 'assets/img/icons/warning.svg';
import { useRecommendationConfig } from '../../hooks';

export default function ConfirmationModal() {
  const {
    mode,
    data,
    initialData,
    isProcessing,
    showConfirmationModal,
    onConfirm,
    updateData,
    onCloseConfirmationModal
  } = useRecommendationConfig();

  const reasons = _get(data, 'attachedReasons', []);

  const conflictingReasons = useMemo(() => {
    return getConflictingReasons(data, initialData);
    // eslint-disable-next-line
  }, [reasons]);

  const onRemoveReason = reasonId => {
    if (!reasonId) return;
    const newReasons = [...reasons];
    const reasonsIndex = newReasons.findIndex(({ id }) => {
      return id === reasonId;
    });
    newReasons.splice(reasonsIndex, 1);
    updateData({ attachedReasons: newReasons });
  };

  const renderItems = conflictingReasons.map(
    ({ id, identifier, product_recommendation }) => {
      const title = _get(product_recommendation, 'recommendation_title', '');
      return (
        <UiKit.GridRow
          key={id}
          gap="14"
          className="my-1 py-1"
          templateColumns="2fr 3fr"
        >
          <div>{identifier}</div>
          <UiKit.GridRow gap="14" templateColumns="1fr auto">
            <div>{title}</div>
            <div
              onClick={() => onRemoveReason(id)}
              className={styles.removeBtn}
            >
              Remove
            </div>
          </UiKit.GridRow>
        </UiKit.GridRow>
      );
    }
  );

  const hasReasons = !_isEmpty(conflictingReasons);
  const message = getConfirmationModalMessage(hasReasons, mode);
  const buttonType = hasReasons ? 'danger' : 'success';
  const buttonText = getConfirmationModalButtonText(hasReasons, mode);
  const buttonProcessingText = getConfirmationModalButtonProcessingText(mode);

  return (
    <UiKit.Modal
      isOpen={showConfirmationModal}
      title="Notice!"
      isProcessing={isProcessing}
      confirmBtnType={buttonType}
      confirmBtnText={buttonText}
      confirmBtnProcessingText={buttonProcessingText}
      onConfirm={() => onConfirm({ skipReasonCheck: true })}
      onClose={onCloseConfirmationModal}
      className={styles.container}
    >
      <UiKit.GridRow gap="20" templateColumns="auto 1fr" className={styles.row}>
        <WarningIcon
          className={cn({
            [styles.negative]: hasReasons,
            [styles.positive]: !hasReasons
          })}
        />
        <p>{message}</p>
      </UiKit.GridRow>
      <If test={hasReasons}>
        <p className="mb-2">List of conflicting request reasons</p>
        <UiKit.GridRow gap="14" templateColumns="2fr 3fr" className="pb-1">
          <strong>Request Reason</strong>
          <strong>Recommendation Set</strong>
        </UiKit.GridRow>
        {renderItems}
      </If>
    </UiKit.Modal>
  );
}
