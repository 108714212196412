import React from 'react';
import styles from './index.module.scss';

import Wave from '../Wave';
import { ReactComponent as CollapseIcon } from 'assets/img/icons/collapse-icon.svg';

export default function Menu({ onToggle = () => {}, children = null }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.backsplash}>
          <Wave />
        </div>
        <div className={styles.header}>
          <h4>Setup Checklist</h4>
          <button className={styles.collapseBtn} onClick={onToggle}>
            <CollapseIcon />
          </button>
        </div>
        <div className={styles.menu}>{children}</div>
      </div>
    </div>
  );
}
