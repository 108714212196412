import _get from 'lodash/get';

import { findAllValues } from 'utils/DICTIONARY';

export default function getGiftCardCopy() {
  const methodsMap = findAllValues('refundPaymentChoices');
  const incentivesMap = findAllValues('shopper_group_override_entities');

  return {
    giftCardText: _get(methodsMap, 'GIFT_CARD', 'Gift Card'),
    giftCardIncentiveText: _get(
      incentivesMap,
      'GIFT_CARD',
      'Gift Card Incentive'
    )
  };
}
