import React, { useState, useContext, useEffect, useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { getRetexAppliedRules } from 'utils/services';
import { ExpandableSection, UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';
import {
  getRuleAppliesOn,
  getFormattedRange
} from 'views/Configure/ReturnManagement/AutomationRules/views/Home/methods';

export default function AppliedRules() {
  const [rules, setRules] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { retex, isProcessing, automatedRuleTypes } = useContext(RetexContext);

  const retexId = _get(retex, 'id', '');

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);
    getRetexAppliedRules(
      retexId,
      rules => setRules(rules),
      () => setIsError(true),
      () => setIsLoading(false)
    );
  }, [retexId]);

  const rulesList = useMemo(() => {
    return rules.map((rule, index) => {
      const name = _get(rule, 'name', '');
      const action = _get(rule, 'action', '');
      const applicableOnReturns = _get(rule, 'applicableOnReturns', false);
      const applicableOnExchanges = _get(rule, 'applicableOnExchanges', false);
      const valueRange = _get(rule, 'valueRange', []);

      const formattedAction = _get(automatedRuleTypes, action, '');
      const formattedEntities = getRuleAppliesOn(
        applicableOnReturns,
        applicableOnExchanges
      );
      const { label: rangeLabel, value: rangeValue } = getFormattedRange(
        valueRange
      );

      return (
        <li key={index} className={styles.rule}>
          <UiKit.GridRow templateColumns="1fr" className={styles.mutedText}>
            <strong>{name}</strong>
            <span>Action: {formattedAction}</span>
          </UiKit.GridRow>
          <UiKit.GridRow
            templateColumns="1fr"
            className={cn('text-right', styles.mutedText)}
          >
            <span>{formattedEntities}</span>
            <span>
              {rangeLabel} <strong>{rangeValue}</strong>
            </span>
          </UiKit.GridRow>
        </li>
      );
    });
  }, [rules, automatedRuleTypes]);

  const hasRules = !_isEmpty(rulesList);
  const badgeText = isLoading ? null : `${rules.length}`;

  return (
    <ExpandableSection
      title="Rules Applicable"
      badgeText={badgeText}
      isError={isError}
      isLoading={isLoading}
      isDisabled={isProcessing}
      headerClassName={styles.header}
    >
      {hasRules ? (
        <ul className={styles.list}>{rulesList}</ul>
      ) : (
        <div className={styles.emptyBox}>
          <span className={styles.mutedText}>
            No Automation Rules triggered!
          </span>
        </div>
      )}
    </ExpandableSection>
  );
}
