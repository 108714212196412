import React from 'react';
import _isArray from 'lodash/isArray';
import ReactMultiSelect from '@kenshooui/react-multi-select';
import '@kenshooui/react-multi-select/dist/style.css';
import styles from './index.module.scss';

export default function MultiSelect({ options = [], onChange = () => {} }) {
  if (!_isArray(options)) return null;

  const handleChange = selectedItems => {
    const selectedItemsIds = selectedItems.map(selectedItem => selectedItem.id);
    const updatedData = options.map(item => {
      const visible = selectedItemsIds.indexOf(item.id) !== -1;
      return { ...item, visible };
    });
    onChange(updatedData);
  };

  const selectedItems = options.filter(({ visible }) => visible);

  return (
    <ReactMultiSelect
      items={options}
      selectedItems={selectedItems}
      onChange={handleChange}
      showSelectedItems={false}
      wrapperClassName={styles.container}
    />
  );
}
