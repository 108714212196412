import { INCENTIVE_TYPE } from 'dictionary';

/**
 * @type {Record<import("./types").BonusCashType, {
 *    get: string,
 *    put: string,
 *    post: string,
 *    delete: string
 * }}
 */
export const ENDPOINTS = {
  exchange: {
    get: 'configure.exchanges.bonusCash.get',
    put: 'configure.exchanges.bonusCash.put',
    post: 'configure.exchanges.bonusCash.post',
    delete: 'configure.exchanges.bonusCash.delete'
  },
  purchase: {
    get: 'configure.purchases.bonusCash.get',
    put: 'configure.purchases.bonusCash.put',
    post: 'configure.purchases.bonusCash.post',
    delete: 'configure.purchases.bonusCash.delete'
  }
};

/**
 * @type {Record<import("./types").BonusCashType, import("./types").BonusCashFeatureSlug>}
 */
export const FEATURE_SLUG = {
  exchange: 'EXCHANGE_BONUS_CASH',
  purchase: 'PURCHASE_BONUS_CASH'
};

export const DEFAULT_DATA = {
  incentive_amount: 0,
  ceiling_incentive_amount: 0,
  discount_type: INCENTIVE_TYPE.FIXED
};
