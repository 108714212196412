import React, { useContext, useMemo } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { UiKit } from 'components';
import RetexInfo from './RetexInfo';
import { OrderServiceContext } from 'components/OrderServiceDetails';

export default function Retexes() {
  const {
    retexes = [],
    orderService = {},
    isDisabled = false,
    areAllRetexesSelected = false,
    onToggleAllRetexes = () => {}
  } = useContext(OrderServiceContext);
  const isOnHold = _get(orderService, 'on_hold', false);

  const showSelectAllCheckbox = useMemo(() => {
    return retexes.some(retex => {
      return _get(retex, 'is_actionable', false);
    });
  }, [retexes]);

  const retexCards = useMemo(() => {
    return retexes.map(retex => {
      const id = _get(retex, 'id', '');
      return (
        <RetexInfo key={id} data={retex} canSelect={showSelectAllCheckbox} />
      );
    });
  }, [retexes, showSelectAllCheckbox]);

  return (
    <UiKit.GridRow templateColumns="1fr" gap="16">
      <div className={styles.header}>
        <h4 className={styles.title}>Request Breakdown</h4>
        {showSelectAllCheckbox && (
          <UiKit.Checkbox
            title="Select All"
            checked={areAllRetexesSelected}
            toggle={onToggleAllRetexes}
            disabled={isOnHold || isDisabled}
            textClassName={styles.checkboxLabel}
          />
        )}
      </div>
      {retexCards}
    </UiKit.GridRow>
  );
}
