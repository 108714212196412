import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { Skeleton, SkeletonTheme, UiKit } from 'components';
import { CustomTableRow } from 'components/Tables/CustomTable';

export default function LoaderSkeleton({ isLoading = false, children = null }) {
  const loaderFrames = [
    {
      lg: 3,
      customClass: cn(styles.leftOffset),
      CustomCell: {
        title: <Skeleton width="100px" className="mb-2" />,
        subTitle: (
          <div className="d-flex flex-column">
            <Skeleton width="60px" className="mb-2" />
            <Skeleton width="140px" className="mb-0" />
          </div>
        )
      }
    },
    {
      lg: 6,
      CustomComponent: (
        <UiKit.GridRow
          gap="24"
          templateColumns="66.5px 1fr"
          className="align-items-stretch"
        >
          <Skeleton width="66.5px" height="66.5px" className={styles.img} />
          <UiKit.GridRow
            gap="8px"
            templateColumns="1fr"
            templateRows="auto 1fr"
          >
            <Skeleton width="75%" className="mb-0" />
            <Skeleton width="50%" className="mb-0" />
          </UiKit.GridRow>
        </UiKit.GridRow>
      )
    }
  ];

  return isLoading ? (
    <SkeletonTheme height="16px">
      <CustomTableRow
        values={loaderFrames}
        customTableRowClass={styles.row}
        customTableRowWrapClass={styles.container}
      />
    </SkeletonTheme>
  ) : (
    children
  );
}
