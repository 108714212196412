import moment from 'moment';

import { localStore } from 'utils';

const getStoreKey = key => `rr-${key}-catalog-last-sync-time`;

/**
 * @param {"purchases" | "exchanges"} key // Required
 */
export const saveLastSyncTime = key => {
  if (!key) throw new Error('Provide the key to save the last sync time');

  localStore.put(getStoreKey(key), moment().toDate());
};

/**
 * @param {"purchases" | "exchanges"} key // Required
 * @param {number} minutes // Default 15 minutes
 */
export const isSyncInProcess = (key, minutes = 15) => {
  if (!key) throw new Error('Provide the key to save the last sync time');

  const lastSyncTime = localStore.get(getStoreKey(key));

  if (!lastSyncTime) return false;

  const time = moment(lastSyncTime);
  const timePassed = moment().diff(time, 'seconds');
  return timePassed < minutes * 60;
};
