import _get from 'lodash/get';

export default function createReasonOptions(reasons = []) {
  if (!Array.isArray(reasons)) return [];

  return reasons.map(reason => {
    return {
      value: _get(reason, 'id', ''),
      label: _get(reason, 'identifier', ''),
      ...reason
    };
  });
}
