import useEcomPlatform from './useEcomPlatform';

export default function useSupportedProductAttributes() {
  const { checkIfPlatformIs } = useEcomPlatform();

  const isShopify = checkIfPlatformIs('SHOPIFY');
  const isWooCommerce = checkIfPlatformIs('WOOCOMMERCE');
  const isBigCommerce = checkIfPlatformIs('BIGCOMMERCE');

  return {
    supportsType: isShopify,
    supportsCategory: isWooCommerce || isBigCommerce,
    supportsCollection: isShopify
  };
}
