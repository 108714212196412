import React, { useContext } from 'react';
import _get from 'lodash/get';

import { UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';
import Variant from './Variant';
import { If } from 'utils';

const ENTITY_TYPE = {
  RET: 'RETURN',
  EXC: 'EXCHANGE',
  PCH: 'PURCHASE'
};

export default function ProductVariants() {
  const { retex } = useContext(RetexContext);

  const quantity = _get(retex, 'quantity', 0);
  const entityType = _get(retex, 'entity_type', '');
  const orderedVariant = _get(retex, 'ordered_variant', {});
  const exchangeVariant = _get(retex, 'exchange_variant', {});
  const purchaseVariant = _get(retex, 'purchase_variant', {});

  const isExchange = entityType === 'EXC';
  const isPurchase = entityType === 'PCH';
  const formattedEntityType = ENTITY_TYPE[entityType];

  return (
    <UiKit.GridRow templateColumns="1fr" gap="15">
      <Variant
        data={isPurchase ? purchaseVariant : orderedVariant}
        subTitle={formattedEntityType}
        quantity={quantity}
      />
      <If test={isExchange && exchangeVariant}>
        <Variant showIcon data={exchangeVariant} quantity={quantity} />
      </If>
    </UiKit.GridRow>
  );
}
