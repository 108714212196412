import React from 'react';
import styles from './index.module.scss';

import { If } from 'utils';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/icons/delete-muted.svg';

export default function Actions({
  showActions = true,
  isDisabled = false,
  editRule = () => {},
  discardRule = () => {},
  children = null
}) {
  return (
    <div className={styles.footer}>
      <div>{children}</div>
      <If test={showActions}>
        <div className={styles.actions}>
          <button onClick={discardRule} disabled={isDisabled}>
            <DeleteIcon />
          </button>
          <button onClick={editRule} disabled={isDisabled}>
            <EditIcon />
          </button>
        </div>
      </If>
    </div>
  );
}
