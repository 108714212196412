import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import styles from './index.module.scss';

import { EcommPlatfrom, UiKit } from 'components';
import { ROUTES } from '../../stubs';
import { getEcomPlatformMap } from 'utils/DICTIONARY';

/**
 * @type {import("utils/types").EcommPlatformSource[]}
 */
const PLATFORMS = ['SHOPIFY', 'WOOCOMMERCE', 'BIGCOMMERCE'];

export default function StoreTiles() {
  const { search } = useLocation();
  const labelMap = useRef(getEcomPlatformMap()).current;

  const tiles = PLATFORMS.map(platform => {
    const id = _toLower(platform);
    const label = _get(labelMap, platform, '');
    const route = `${ROUTES.ROOT}/${id}${search}`;

    return (
      <Link key={id} to={route} className={cn(styles.card, styles[id])}>
        <EcommPlatfrom.Icon source={platform} className={styles.icon} />
        <p className={styles.title}>{label}</p>
      </Link>
    );
  });

  return <UiKit.GridRow gap="16">{tiles}</UiKit.GridRow>;
}
