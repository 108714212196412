import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

import { preventErrorLog } from './methods';

/**
 * @param {Error} error
 */
export default function logError(error) {
  const appMode = process.env.REACT_APP_MODE;

  if (!appMode || preventErrorLog()) return;

  if (appMode === 'DEVELOPMENT') {
    return console.log('Log Error: ', error);
  }

  LogRocket.captureException(error);
  Sentry.captureException(error);
}
