import React from 'react';

import { BonusCashConfigProvider, ConfigForm } from './components';

/**
 * @param {object} props
 * @param {import("./types").BonusCashType} props.type
 * @param {string} props.homeRoute
 * @param {string} props.configRoute
 */
export default function BonusCashConfig({
  type = null,
  homeRoute = '',
  configRoute = ''
}) {
  return (
    <BonusCashConfigProvider type={type}>
      <ConfigForm homeRoute={homeRoute} configRoute={configRoute} />
    </BonusCashConfigProvider>
  );
}
