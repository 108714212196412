import { useCallback, useEffect, useRef, useState } from 'react';
import { waterfall } from 'async';
import axios from 'axios';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _isFunction from 'lodash/isFunction';

import { get, logError, post } from 'utils';
import { CustomToast } from 'components';

const API_METHODS = {
  GET: () => get,
  POST: () => post
};

const DEFAULT = {
  method: 'GET',
  loadOnMount: true,
  initialData: null,
  isPublicAPI: false,
  payload: {},
  routeParam: '',
  routeParams: {},
  axiosConfig: {},
  onSkipRequest: () => false,
  onTransform: data => data,
  onSuccess: () => {},
  onError: () => {},
  ignoreReadModeCheck: false,
  errorMessage: 'Unable to fetch data at the moment. Please try again later.'
};

/**
 * Arguments of useSeriesFetch hook
 * @param {import('utils/types').SeriesApi[]} apis List of the objects defining the apiKey and the request configuration
 * @param {import('utils/types').SeriesFetchConfig} config Optional parameters to control the behaviour of the hook
 */
export default function useSeriesFetch(apis = [], config = {}) {
  if (!_isArray(apis)) {
    throw new Error('"apis" must be an Array in the useSeriesFetch hook');
  }

  const initialData = _get(config, 'initialData', DEFAULT.initialData);
  const loadOnMount = _get(config, 'loadOnMount', DEFAULT.loadOnMount);
  const errorMessage = _get(config, 'errorMessage', DEFAULT.errorMessage);
  const onTransform = _get(config, 'onTransform', DEFAULT.onTransform);
  const onSuccess = _get(config, 'onSuccess', DEFAULT.onSuccess);
  const onError = _get(config, 'onError', DEFAULT.onError);

  const [data, setData] = useState(initialData);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(loadOnMount && !_isEmpty(apis));

  const isMountedRef = useRef(false);
  const apisRef = useRef(apis);
  const onSuccessRef = useRef(onSuccess);
  const onErrorRef = useRef(onError);
  const onTransformRef = useRef(onTransform);

  const cancelTokenSourceRef = useRef(null);

  useEffect(() => {
    apisRef.current = apis;
    onSuccessRef.current = onSuccess;
    onErrorRef.current = onError;
    onTransformRef.current = onTransform;
  });

  /**
   * @param {Object} payloadOverrides
   */
  const fetchData = useCallback(
    (payloadOverrides = {}) => {
      const requests = createRequests(
        apisRef.current,
        payloadOverrides,
        cancelTokenSourceRef
      );

      if (_isEmpty(requests)) return;

      setIsError(false);
      setIsLoading(true);
      waterfall(requests, (error, data) => {
        if (axios.isCancel(error)) return;

        setIsLoading(false);
        if (error) {
          setIsError(true);
          onErrorRef.current(error, _get(error, 'response.data.data', null));
          logError(
            errorMessage === DEFAULT.errorMessage ? error : errorMessage
          );
          if (errorMessage) {
            CustomToast({
              isNotified: _get(error, 'notified', false),
              msg: errorMessage,
              type: 'error'
            });
          }
        } else {
          const transformedData = onTransformRef.current(_cloneDeep(data));
          setData(transformedData);
          onSuccessRef.current(transformedData, data);
        }
      });
    },
    [errorMessage]
  );

  useEffect(() => {
    if (!isMountedRef.current && loadOnMount) {
      isMountedRef.current = true;
      fetchData();
    }
  }, [fetchData, loadOnMount]);

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
    };
  }, []);

  return {
    data,
    isError,
    isLoading,
    fetchData
  };
}

// --------------------------------------------------------------------------

/**
 * Arguments of useSeriesFetch hook
 * @param {import('utils/types').SeriesApi[]} apis
 * @param {Object} payloadOverrides
 * @param {{ current: import('axios').CancelTokenSource }} cancelTokenSourceRef
 */
function createRequests(
  apis = [],
  payloadOverrides = {},
  cancelTokenSourceRef = {}
) {
  let isFirst = true;

  if (cancelTokenSourceRef.current) {
    cancelTokenSourceRef.current.cancel();
  }

  cancelTokenSourceRef.current = axios.CancelToken.source();

  return apis.reduce((acc, { apiKey, dataKey, ...meta } = {}) => {
    if (!apiKey || !dataKey) {
      throw new Error(
        'apiKey and the dataKey are required in the useSeriesFetch hook'
      );
    }

    const apiVerb = _get(meta, 'method', DEFAULT.method);
    const payload = _get(meta, 'payload', DEFAULT.payload);
    const axiosConfig = _get(meta, 'axiosConfig', DEFAULT.axiosConfig);
    const routeParam = _get(meta, 'routeParam', DEFAULT.routeParam);
    const routeParams = _get(meta, 'routeParams', DEFAULT.routeParams);
    const isPublicAPI = _get(meta, 'isPublicAPI', DEFAULT.isPublicAPI);
    const onTransform = _get(meta, 'onTransform', DEFAULT.onTransform);
    const onSkipRequest = _get(meta, 'onSkipRequest', DEFAULT.onSkipRequest);
    const ignoreReadModeCheck = _get(
      meta,
      'ignoreReadModeCheck',
      DEFAULT.ignoreReadModeCheck
    );

    const methodGen = _get(API_METHODS, apiVerb, API_METHODS.GET);
    const apiMethod = methodGen();
    const overridePayload = _get(payloadOverrides, dataKey, {});

    const apiCall = (
      payload,
      successCallback = () => {},
      errorCallback = () => {}
    ) => {
      apiMethod(
        { apiKey, noTokenRequired: isPublicAPI, ignoreReadModeCheck },
        {
          params: {
            routeParam,
            routeParams,
            ...payload,
            ...overridePayload
          },
          config: {
            ...axiosConfig,
            cancelToken: cancelTokenSourceRef.current.token
          }
        }
      )
        .then(({ data }) => {
          successCallback({ [dataKey]: onTransform(data) });
        })
        .catch(err => errorCallback(err));
    };

    if (isFirst) {
      isFirst = false;
      acc.push(function(callback) {
        apiCall(
          payload,
          data => callback(null, data),
          err => callback(err)
        );
      });
    } else {
      acc.push(function(aggregateData, callback) {
        const skip = onSkipRequest(aggregateData);
        if (skip) {
          return callback(null, aggregateData);
        }
        const payloadData = _isFunction(payload)
          ? payload(aggregateData)
          : payload;
        apiCall(
          payloadData,
          data => callback(null, { ...aggregateData, ...data }),
          err => callback(err)
        );
      });
    }

    return acc;
  }, []);
}
