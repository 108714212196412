import React from 'react';

import Skeleton from 'components/Skeleton';
import { useBonusCashConfig } from '../../hooks';

export default function Loader({ children = null }) {
  const { isLoading } = useBonusCashConfig();

  if (!isLoading) return children;

  return (
    <>
      <Skeleton width="50%" />
      <Skeleton width="40%" />
      <Skeleton width="30%" />
    </>
  );
}
