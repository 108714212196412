import _get from 'lodash/get';
import _pick from 'lodash/pick';

/**
 * Sanitize Payload before sending info to server
 * @param  {object} data API payload to be sent
 * @return {formattedData}      Modified API payload in correct form
 */
export function sanitizePayload(data) {
  const payload = { ...data };
  const collections = _get(payload, 'collections', []);
  const productTypes = _get(payload, 'product_types', []);
  const productCategories = _get(payload, 'product_categories', {});
  const excludedTags = _get(payload, 'excluded_tags', []);
  const minPrice = _get(payload, 'min_price', '');

  payload.product_types = productTypes.map(productType => {
    return _pick(productType, ['title', 'visible']);
  });

  payload.collections = collections.map(collection => {
    return _pick(collection, ['id', 'title', 'visible']);
  });

  payload.product_categories = Object.entries(
    productCategories
  ).map(([id, visible]) => ({ id, visible }));

  payload.excluded_tags = excludedTags.map(tag => _get(tag, 'value', ''));

  payload.min_price = +minPrice;

  return payload;
}
