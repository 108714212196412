import React, { useContext, useEffect, useState } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { getRetexTimeline } from 'utils/services';
import { ExpandableSection } from 'components';
import TimelineRows from './TimelineRows';
import { RetexContext } from 'components/RetexDetails';
import { mapTimelineData } from '../../methods';

export default function Timeline() {
  const [timelineData, setTimelineData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { retex, isProcessing } = useContext(RetexContext);

  const retexId = _get(retex, 'id', '');

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);
    getRetexTimeline(
      retexId,
      data => {
        const modifiedData = mapTimelineData(data);
        setTimelineData(modifiedData);
      },
      () => setIsError(true),
      () => setIsLoading(false)
    );
  }, [retexId]);

  return (
    <ExpandableSection
      title="Line Item Timeline"
      isError={isError}
      isLoading={isLoading}
      isDisabled={isProcessing}
      headerClassName={styles.header}
    >
      <TimelineRows dataList={timelineData} />
    </ExpandableSection>
  );
}
