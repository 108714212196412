import _get from 'lodash/get';

import { DEFAULT_VALUES } from '../stubs';

export default function createEmptyFormData(defaultRule = null) {
  const ruleConfig = _get(defaultRule, 'config', []);
  const config = ruleConfig.reduce((acc, { id, type }) => {
    acc[id] = _get(DEFAULT_VALUES, type, null);
    return acc;
  }, {});

  return {
    title: '',
    rule: defaultRule,
    rules: [],
    attachedReasons: [],
    isEnabled: false,
    config
  };
}
