import React, { createContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import uuid from 'uuid/v4';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _size from 'lodash/size';
import _assign from 'lodash/assign';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _cloneDeep from 'lodash/cloneDeep';
import _isPlainObject from 'lodash/isPlainObject';

import { useMutation, useParallelFetch, useSeriesFetch } from 'utils/hooks';
import { scrollToTop } from 'utils/methods';
import { useRulesAccess } from '../hooks';
import {
  getSeriesAPIs,
  getParallelAPIs,
  getMutationApi,
  createConfig,
  createDefaultRuleConfig,
  validate,
  validateReasons,
  createRequestBody
} from '../methods';
import { MODES } from '../stubs';

/**
 * @type {React.Context<{
 * data: Object | null,
 * isError: boolean,
 * isLoading: boolean,
 * initialData: Object | null,
 * ruleOptions: Object[],
 * reasonOptions: Object[],
 * customRuleOptions: Object[],
 * mode: keyof typeof MODES,
 * isProcessing: boolean,
 * errors: Record<string, string>,
 * isCustomRuleModalOpen: boolean,
 * customRuleData: Object | null,
 * updateData: (data: Object) => void,
 * clearErrors: (...keys: string[]) => void
 * onReset: () => void,
 * createRule: () => void,
 * editRule: (index: number) => void,
 * discardRule: (index: number) => void,
 * updateCustomRuleData: (data: Object) => void
 * addRule: (ruleInfo: Object) => void,
 * updateRule: (ruleInfo: Object) => void,
 * onCloseCustomRuleModal: () => void,
 * onPostCloseCustomRuleModal: () => void,
 * onConfirm: (info: Object) => void,
 * showConfirmationModal: boolean,
 * onCloseConfirmationModal: () => void
 * }>}
 */
export const RecommendationConfigContext = createContext();

/**
 * @param {Object} props
 * @param {import("../types").RecommendationType} props.type
 * @param {string} props.homeRoute
 * @param {React.ReactNode} props.children
 */
export function RecommendationConfigProvider({
  type = null,
  homeRoute = '',
  children = null
}) {
  if (!type) {
    throw new Error(
      'prop "type" is required in RecommendationConfigProvider component'
    );
  }

  const { id } = useParams();
  const { push } = useHistory();
  const mode =
    type === 'purchase' ? MODES.EDIT : id ? MODES.EDIT : MODES.CREATE;

  const rulesAccessMap = useRulesAccess();

  const [data, setData] = useState(null);
  const [errors, setErrors] = useState({});
  const [isCustomRuleModalOpen, setIsCustomRuleModalOpen] = useState(false);
  const [customRuleData, setCustomRuleData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const initialDataRef = useRef(null);
  const ruleOptionsRef = useRef([]);
  const customRuleOptionsRef = useRef([]);
  const reasonOptionsRef = useRef([]);

  const {
    data: seriesApisData,
    isError: isSeriesApisError,
    isLoading: isSeriesApisLoading
  } = useSeriesFetch(getSeriesAPIs(type, id));

  const {
    data: parallelApisData,
    isError: isParallelApisError,
    isLoading: isParallelApisLoading
  } = useParallelFetch(getParallelAPIs(type, rulesAccessMap));

  const { mutationApiKey, mutationMethod } = getMutationApi(id, type);
  const { mutate, isProcessing } = useMutation(mutationApiKey, mutationMethod, {
    errorMessage: `Unable to ${
      mode === MODES.CREATE ? 'create' : 'update'
    } the recommendation set. Please try again!`,
    successMessage: `Successfully ${
      mode === MODES.CREATE ? 'created' : 'updated'
    } the recommendation set`,
    onSuccess: () => {
      initialDataRef.current = data;
      if (!homeRoute) return;
      setTimeout(() => push(homeRoute), 100);
    }
  });

  const isError = isSeriesApisError || isParallelApisError;
  const isLoading = isSeriesApisLoading || isParallelApisLoading;

  useEffect(() => {
    const recommendation = _get(seriesApisData, 'recommendation', null);
    const products = _get(seriesApisData, 'products', []);
    const rules = _get(parallelApisData, 'rules', []);
    const reasons = _get(parallelApisData, 'reasons', []);
    const hasData = [recommendation, products, rules, reasons].some(dataset => {
      return !!dataset && !_isEmpty(dataset);
    });

    if (!isError && !isLoading && hasData) {
      const config = createConfig({ recommendation, products, rules, reasons });
      const formData = _get(config, 'formData', {});
      ruleOptionsRef.current = _get(config, 'parentRules', []);
      customRuleOptionsRef.current = _get(config, 'childRules', []);
      reasonOptionsRef.current = _get(config, 'reasons', []);
      initialDataRef.current = formData;
      setData(formData);
    }
  }, [seriesApisData, parallelApisData, isError, isLoading]);

  const onReset = () => setData(initialDataRef.current);

  const clearErrors = (...keys) => setErrors(errors => _omit(errors, keys));

  const updateData = (updates = {}) => {
    if (!_isPlainObject(updates)) return null;
    setData(prevData => ({ ...prevData, ...updates }));
  };

  const updateCustomRuleData = (updates = {}) => {
    if (!_isPlainObject(updates)) return null;
    setCustomRuleData(prevData => ({ ...prevData, ...updates }));
  };

  const onCloseCustomRuleModal = () => setIsCustomRuleModalOpen(false);

  const onPostCloseCustomRuleModal = () => setCustomRuleData(null);

  const createRule = () => {
    if (isProcessing) return;
    const index = _get(data, 'rules.length', null);
    const ruleOption = customRuleOptionsRef.current[0];
    const ruleConfig = _get(ruleOption, 'config', []);
    const config = createDefaultRuleConfig(ruleConfig);
    setCustomRuleData({ index, ruleOption, config });
    setIsCustomRuleModalOpen(true);
  };

  const editRule = index => {
    const rule = _get(data, `rules.[${index}]`, null);
    if (isProcessing || !_isNumber(index) || !rule) return;
    const modalData = _cloneDeep(rule);
    modalData.index = index;
    setCustomRuleData(modalData);
    setIsCustomRuleModalOpen(true);
  };

  const discardRule = index => {
    if (isProcessing || !_isNumber(index)) return;
    const rules = _get(data, 'rules', []);
    updateData({ rules: rules.filter((_, i) => i !== index) });
  };

  const addRule = (ruleInfo = {}) => {
    const rules = _get(data, 'rules', []);
    const size = _size(rules);
    const config = _get(customRuleData, 'config', null);
    const ruleOption = _get(customRuleData, 'ruleOption', null);

    if (size < 5) {
      const newRule = _assign(ruleInfo, { key: uuid(), ruleOption, config });
      onCloseCustomRuleModal();
      updateData({ rules: [...rules, newRule] });
      setErrors(currentErrors => {
        let errors = _omit(currentErrors, 'rules.msg');
        if (_isEmpty(_get(errors, 'rules', {}))) {
          errors = _omit(errors, 'rules');
        }
        return errors;
      });
    }
  };

  const updateRule = (ruleInfo = {}) => {
    const rules = _get(data, 'rules', []);
    const index = _get(customRuleData, 'index', null);
    const config = _get(customRuleData, 'config', null);
    const ruleOption = _get(customRuleData, 'ruleOption', null);

    if (!_isNumber(index) || index >= 5) return;

    const newRules = _cloneDeep(rules);
    const prevRule = _cloneDeep(rules[index]);
    const newRule = _assign(ruleInfo, { key: uuid(), ruleOption, config });
    const updatedRule = _assign(prevRule, newRule);
    newRules.splice(index, 1, updatedRule);
    updateData({ rules: newRules });
    onCloseCustomRuleModal();
  };

  const runValidation = skipReasonCheck => {
    const errors = validate(data);
    if (!_isEmpty(errors)) {
      scrollToTop();
      setErrors(errors);
      return false;
    }
    if (!skipReasonCheck) {
      const initialData = initialDataRef.current;
      const hasInvalidReasons = validateReasons(data, initialData);
      if (hasInvalidReasons) {
        setShowConfirmationModal(true);
        return false;
      }
    }
    return true;
  };

  const onConfirm = info => {
    const skipReasonCheck = _get(info, 'skipReasonCheck', false);
    const isValid = runValidation(skipReasonCheck);
    if (!isValid) return;

    let payload = createRequestBody(data);
    if (mode === MODES.EDIT) {
      const recommendation = _get(seriesApisData, 'recommendation', null);
      const id = _get(recommendation, 'id', null);
      payload.routeParam = id;
    }
    mutate(payload);
  };

  return (
    <RecommendationConfigContext.Provider
      value={{
        mode,
        data,
        isError,
        isLoading,
        initialData: initialDataRef.current,
        errors,
        isProcessing,
        ruleOptions: ruleOptionsRef.current,
        reasonOptions: reasonOptionsRef.current,
        customRuleOptions: customRuleOptionsRef.current,
        isCustomRuleModalOpen,
        customRuleData,
        updateData,
        clearErrors,
        onReset,
        createRule,
        editRule,
        discardRule,
        updateCustomRuleData,
        addRule,
        updateRule,
        onCloseCustomRuleModal,
        onPostCloseCustomRuleModal,
        onConfirm,
        showConfirmationModal,
        onCloseConfirmationModal: () => setShowConfirmationModal(false)
      }}
    >
      {children}
    </RecommendationConfigContext.Provider>
  );
}
