import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { Toggle } from 'components';
import { usePriceLimitter } from '..';

export default function PriceLimitToggle({
  name = '',
  label = '',
  enabledKey = 'enabled'
}) {
  const { data, isDisabled, allowToggle, onToggle } = usePriceLimitter();

  const id = useRef(`toggle-${uuidv4()}`);

  const isActive = _get(data, enabledKey, false);

  if (!allowToggle) return null;

  return (
    <div className={styles.container}>
      <div className={styles.toggle}>
        <Toggle
          id={id}
          name={name}
          isActive={isActive}
          onClick={({ event }) => onToggle(!isActive, event)}
          disabled={isDisabled}
        />
      </div>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
    </div>
  );
}
