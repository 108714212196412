import React, {
  useState,
  useRef,
  createContext,
  useCallback,
  useEffect
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';

import { useFetch, useMutation } from 'utils/hooks';
import { validate } from 'components/BonusCashConfig/methods';
import { ENDPOINTS, DEFAULT_DATA, FEATURE_SLUG } from '../stubs';

/**
 * @type {React.Context<{
 * data: any,
 * initialData: any,
 * isError: boolean,
 * isLoading: boolean,
 * errorMsg: string,
 * errors: Record<string, string>,
 * isProcessing: boolean,
 * featureSlug: import("../types").BonusCashFeatureSlug,
 * onUpdateErrors: (errors: Record<string, string>) => void,
 * onChangeData: (dataset: Record<string, any>) => void,
 * onLoadData: () => void,
 * onReset: () => void,
 * onSave: (isShopperGroupConfigured: boolean) => void,
 * onDelete: (callback: () => void) => void
 * }>}
 */
export const BonusCashConfigContext = createContext();

/**
 * @param {object} props
 * @param {import("../types").BonusCashType} props.type
 */
export function BonusCashConfigProvider({ type = null, children = null }) {
  if (!type) {
    throw new Error(
      'prop "type" is required in BonusCashConfigProvider component'
    );
  }

  const { search } = useLocation();
  const { replace } = useHistory();

  const [data, setData] = useState(null);
  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const initialDataRef = useRef(null);

  const searchParams = new URLSearchParams(search);
  const isNew = searchParams.get('new') === 'true';
  const shopperGroupId = searchParams.get('shopper_group');

  const initFormData = useCallback(data => {
    if (!_isObject(data)) return;
    initialDataRef.current = data;
    setData(data);
    setErrors({});
    setErrorMsg('');
  }, []);

  const { isError, isLoading, fetchData } = useFetch(ENDPOINTS[type].get, {
    loadOnMount: false,
    errorMessage:
      'Unable to fetch the bonus cash data. Please try again later.',
    onTransform: data => {
      if (!isNew) return data;
      return { ...data, ...DEFAULT_DATA, shopper_group_id: shopperGroupId };
    },
    onSuccess: initFormData,
    onError: error => {
      const statusCode = _get(error, 'statusCode', '');
      let errorMessage = _get(error, 'displayMessage.message');
      if (statusCode === 404 && shopperGroupId) {
        errorMessage = 'Shopper group configuration not found';
      }
      setErrorMsg(errorMessage);
    }
  });

  const onLoadData = useCallback(() => {
    fetchData({
      shopper_group: shopperGroupId && !isNew ? shopperGroupId : null
    });
  }, [shopperGroupId, isNew, fetchData]);

  useEffect(() => {
    onLoadData();
  }, [onLoadData]);

  const { isProcessing: isCreating, mutate: onCreate } = useMutation(
    ENDPOINTS[type].post,
    'POST',
    {
      successMessage: `Created new purchase bonus cash incentive configuration.`,
      errorMessage: `Unable to create new purchase bonus cash incentive at the moment. Please try later!`,
      onSuccess: data => {
        const shopperGroupId = _get(data, 'shopper_group_id');
        if (shopperGroupId) {
          replace({ search: `shopper_group=${shopperGroupId}` });
        }
      }
    }
  );

  const { isProcessing: isUpdating, mutate: onUpdate } = useMutation(
    ENDPOINTS[type].put,
    'PUT',
    {
      successMessage: `Successfully updated purchase bonus cash incentive.`,
      errorMessage: `Unable to update the purchase bonus cash incentive at the moment. Please try later!`,
      onSuccess: initFormData
    }
  );

  const { isProcessing: isDeleting, mutate: onDelete } = useMutation(
    ENDPOINTS[type].delete,
    'DELETE',
    {
      routeParams: { id: _get(data, 'id') },
      successMessage: 'Deleted purchase bonus cash incentive configuration.',
      errorMessage: `Unable to delete the purchase bonus cash incentive at the moment. Please try later!`,
      onSuccess: () => replace({ search: '' })
    }
  );

  const onChangeData = useCallback((dataset = {}) => {
    if (!_isObject(dataset)) return;
    setData(currData => ({ ...currData, ...dataset }));
  }, []);

  const onUpdateErrors = useCallback((errors = {}) => {
    if (!_isObject(errors)) return;
    setErrors(currErrors => {
      return Object.keys(errors).reduce(
        (acc, key) => {
          const value = errors[key];
          if (!value) delete acc[key];
          else acc[key] = value;
          return acc;
        },
        { ...currErrors }
      );
    });
  }, []);

  const onSave = useCallback(
    (isShopperGroupConfigured = null) => {
      const errors = validate(data);
      onUpdateErrors(errors);
      if (!_isEmpty(errors)) return;

      const isGlobalSetting = isShopperGroupConfigured === null;
      const params = {
        is_active: _get(data, 'is_active'),
        discount_type: _get(data, 'discount_type'),
        incentive_amount: +_get(data, 'incentive_amount'),
        ceiling_incentive_amount: +_get(data, 'ceiling_incentive_amount'),
        shopper_group_id: _get(data, 'shopper_group_id'),
        shopper_groups_allowed: _get(data, 'shopper_groups_allowed')
      };

      if (isGlobalSetting || isShopperGroupConfigured) {
        onUpdate({ ...params, routeParams: { id: _get(data, 'id') } });
      } else {
        onCreate(params);
      }
    },
    [data, onUpdateErrors, onCreate, onUpdate]
  );

  const onReset = useCallback(() => setData(initialDataRef.current), []);

  return (
    <BonusCashConfigContext.Provider
      value={{
        data,
        initialData: initialDataRef.current,
        isError,
        errorMsg,
        isLoading,
        errors,
        isProcessing: isUpdating || isCreating || isDeleting,
        featureSlug: FEATURE_SLUG[type],
        onUpdateErrors,
        onChangeData,
        onLoadData,
        onReset,
        onSave,
        onDelete: (callback = () => {}) => onDelete({}, callback)
      }}
    >
      {children}
    </BonusCashConfigContext.Provider>
  );
}
