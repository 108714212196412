import _get from 'lodash/get';
import _has from 'lodash/has';
import _toLower from 'lodash/toLower';
import _isArray from 'lodash/isArray';
import _cloneDeep from 'lodash/cloneDeep';
import _isObject from 'lodash/isPlainObject';

import localStore from 'utils/localStore';

export function formatAndStoreMasterData(data, switchToStoreCredits = false) {
  const masterData = updateMasterData(data, switchToStoreCredits);
  const formattedData = {};
  try {
    Object.keys(masterData).forEach(key => {
      var itemObj = {};
      var itemArr = [];
      if (Array.isArray(masterData[key])) {
        masterData[key].forEach(item => {
          if (typeof item === 'string') {
            itemArr.push(item);
          } else {
            itemObj[item.value] = item.text;
          }
        });
      } else {
        itemObj = masterData[key];
      }
      if (itemArr.length) {
        formattedData[key] = Object.assign([], itemArr);
      } else {
        formattedData[key] = Object.assign({}, itemObj);
      }
    });
    localStore.put('dictionary', JSON.stringify(formattedData));
    return formattedData;
  } catch (e) {
    return null;
  }
}

function updateMasterData(masterData, switchToStoreCredits = false) {
  const data = _cloneDeep(masterData);
  const factory = createMasterDataUpdateFactory(data);

  if (switchToStoreCredits) {
    factory.replaceText('retailerPaymentMethodChoices', 'Store Credits');
    factory.replaceText('refundPaymentChoices', 'Store Credits');
    factory.replaceText(
      'shopper_group_override_entities',
      'Store Credits Incentive'
    );
  }

  factory.inject('paymentWaiveOff', {
    infoText: 'We have waived off all the charges for this exchange order'
  });
  return data;
}

function createMasterDataUpdateFactory(data) {
  return {
    inject: (key, value) => {
      data[key] = value;
    },
    replaceText: (key, replaceWith = '') => {
      const dataset = _get(data, key, []);
      if (_isArray(dataset)) {
        data[key] = dataset.map(item => {
          const isItemObject = _isObject(item);
          const hasTextKey = _has(item, 'text');
          const hasValueKey = _has(item, 'value');
          const value = _get(item, 'value', '');
          const isGiftCartValue = _toLower(value) === 'gift_card';
          if (isItemObject && hasTextKey && hasValueKey && isGiftCartValue) {
            return { ...item, text: replaceWith };
          }
          return item;
        });
      }
    }
  };
}
