import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import { DEFAULT_VALUES } from '../stubs';

export default function createDefaultRuleConfig(config = []) {
  if (!_isArray(config)) return {};

  return config.reduce((acc, data) => {
    const id = _get(data, 'id', '');
    const type = _get(data, 'type', '');
    acc[id] = _get(DEFAULT_VALUES, type, null);
    return acc;
  }, {});
}
