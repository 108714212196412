import React, { useContext, useRef } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import _get from 'lodash/get';

import { TourGuides } from 'containers';
import Nav from '../Nav';
import QuotaUsage from './QuotaUsage';
import StickyFooter from '../StickyFooter';
import CustomerPortalLink from './CustomerPortalLink';
import { AuthContext } from 'context/AuthContext';
import { admin, store, warehouse } from 'config/nav';

export default function RetexNav() {
  const { appType, ROLES, configuration } = useContext(AuthContext);
  const footerRef = useRef();

  const isWarehouse = _get(ROLES, 'warehouse', false);
  const isStore = _get(ROLES, 'store', false);
  const isAdmin = _get(ROLES, 'admin', false);

  if (!appType.retex) return null;

  return (
    <Switch>
      <Route
        path="/w"
        render={() => {
          if (!isWarehouse) return <Redirect to="/" />;
          return <Nav homePath="/w" config={warehouse} />;
        }}
      />
      <Route
        path="/s"
        render={() => {
          if (!isStore) return <Redirect to="/" />;
          return <Nav homePath="/s" config={store} />;
        }}
      />
      <Route
        path="/"
        render={() => {
          if (!isAdmin) return <Redirect to="/" />;

          const config = admin.getConfig(configuration);
          return (
            <Nav homePath="/" config={config}>
              <StickyFooter ref={footerRef}>
                <QuotaUsage />
                <CustomerPortalLink />
                <TourGuides.FeaturesChecklist />
              </StickyFooter>
            </Nav>
          );
        }}
      />
    </Switch>
  );
}
