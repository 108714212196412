import _get from 'lodash/get';

import ROUTES from './routes';

const FEATURE_ROUTES = [
  {
    name: 'Overview',
    id: 'admin.submenu.overview.enabled',
    shepherdId: 'sidebar-overview',
    url: ROUTES.OVERVIEW.ROOT,
    icon: 'icon-overview',
    hideIfNotEnabled: false
  },
  {
    name: 'Orders',
    id: 'admin.submenu.orders.enabled',
    shepherdId: 'sidebar-orders',
    url: ROUTES.ORDERS.ROOT,
    icon: 'icon-orders',
    hideIfNotEnabled: false
  },
  {
    name: 'Requests',
    id: 'admin.submenu.requests.enabled',
    shepherdId: 'sidebar-requests',
    url: ROUTES.REQUESTS.ROOT,
    icon: 'icon-service-requests',
    hideIfNotEnabled: false
  },
  {
    name: 'Analytics',
    id: 'admin.submenu.analytics.enabled',
    shepherdId: 'sidebar-analytics',
    url: ROUTES.ANALYTICS.ROOT,
    icon: 'icon-reporting',
    hideIfNotEnabled: false,
    children: [
      {
        name: 'Dashboard',
        id: 'admin.submenu.analytics.submenu.dashboard.enabled',
        shepherdId: 'sidebar-analytics-dashboard',
        url: ROUTES.ANALYTICS.DASHBOARD
      },
      {
        name: 'Insights',
        id: 'admin.submenu.analytics.submenu.insights.enabled',
        shepherdId: 'sidebar-analytics-insights',
        url: ROUTES.ANALYTICS.INSIGHTS
      },
      {
        name: 'User Reports',
        id: 'admin.submenu.analytics.submenu.user_reports.enabled',
        shepherdId: 'sidebar-analytics-user-reports',
        url: ROUTES.ANALYTICS.USER_REPORTS
      }
    ]
  },
  {
    name: 'Configure',
    id: 'admin.submenu.configure.enabled',
    shepherdId: 'sidebar-configure',
    url: ROUTES.CONFIGURE_RR.ROOT,
    icon: 'icon-configure',
    hideIfNotEnabled: false,
    children: [
      {
        name: 'Return Management',
        id: 'admin.submenu.configure.submenu.return_management.enabled',
        shepherdId: 'sidebar-configure-return-management',
        url: ROUTES.CONFIGURE_RR.RETURN_MANAGEMENT
      },
      {
        name: 'Shipping',
        id: 'admin.submenu.configure.submenu.shipping.enabled',
        shepherdId: 'sidebar-configure-shipping',
        url: ROUTES.CONFIGURE_RR.SHIPPING
      },
      {
        name: 'Shopper Portal',
        id: 'admin.submenu.configure.submenu.shopper_portal.enabled',
        shepherdId: 'sidebar-configure-shopper-portal',
        url: ROUTES.CONFIGURE_RR.SHOPPER_PORTAL
      }
    ]
  },
  {
    name: 'Settings',
    id: 'admin.submenu.settings.enabled',
    shepherdId: 'sidebar-settings',
    url: ROUTES.SETTINGS.ROOT,
    icon: 'icon-setting',
    hideIfNotEnabled: false,
    children: [
      {
        name: 'Manage Subscription',
        id: 'admin.submenu.settings.submenu.manage_subscription.enabled',
        shepherdId: 'sidebar-settings-manage-subscription',
        url: ROUTES.SETTINGS.MANAGE_SUBSCRIPTION
      },
      {
        name: 'Manage Users',
        id: 'admin.submenu.settings.submenu.manage_users.enabled',
        shepherdId: 'sidebar-settings-manage-users',
        url: ROUTES.SETTINGS.USER_MANAGEMENT
      },
      {
        name: 'Manage API Tokens',
        id: 'admin.submenu.settings.submenu.manage_apis.enabled',
        shepherdId: 'sidebar-settings-manage-apis',
        url: ROUTES.SETTINGS.MANAGE_APIS
      }
    ]
  },
  {
    name: 'Help',
    id: 'admin.submenu.help_and_support.enabled',
    shepherdId: 'sidebar-help-and-support',
    url: ROUTES.HELP_AND_SUPPORT.ROOT,
    icon: 'icon-help-and-support',
    hideIfNotEnabled: false
  }
];

export const getConfig = displayConfig => {
  const items = FEATURE_ROUTES.filter(feature => {
    const isEnabled = _get(displayConfig, feature.id, false);
    return isEnabled || !feature.hideIfNotEnabled;
  });
  return { items };
};
