import React from 'react';

import { UiKit } from 'components';
import { Skeleton, SkeletonTheme, InteractConfigWrapper } from 'components';
import { useRecommendationConfig } from '../hooks';

export default function Loader({ children }) {
  const { isLoading } = useRecommendationConfig();

  if (!isLoading) return children;

  return (
    <SkeletonTheme className="m-0">
      <InteractConfigWrapper
        title={<Skeleton width="200px" height="19.2px" />}
        helptext={
          <UiKit.GridRow templateColumns="1fr" gap="4px">
            <Skeleton width="100%" height="18px" />
            <Skeleton width="250px" height="18px" />
          </UiKit.GridRow>
        }
      >
        <UiKit.GridRow templateColumns="1fr" gap="10px">
          <Skeleton width="180px" height="12px" />
          <Skeleton width="100%" height="44px" />
        </UiKit.GridRow>
      </InteractConfigWrapper>
    </SkeletonTheme>
  );
}
